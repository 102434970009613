import * as TYPES from './constants';
//al OPPORTUNITIES
export const requestAllOpportunities = () => ({
  type: TYPES.ALL_OPPORTUNITIES_REQUEST,
});
export const recieveAllOpportunities = (json, loadMore) => ({
  type: TYPES.ALL_OPPORTUNITIES_SUCCESS,
  payload: json,
  loadMore: loadMore,
});
export const requestAllOpportunitiesError = err => ({
  type: TYPES.ALL_OPPORTUNITIES_ERROR,
  err,
});
export const clearAllOpportunities = err => ({
  type: TYPES.CLEAR_ALL_OPPORTUNITIES,
  err,
});
