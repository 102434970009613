export const REQUEST_USER = 'REQUEST_USER';
export const SUCCESS_USER = 'SUCCESS_USER';
export const ERROR_USER = 'ERROR_USER';

export const PROFILE_UPDATE_LOAD = 'PROFILE_UPDATE_LOAD';
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS';
export const PROFILE_UPDATE_ERROR = 'PROFILE_UPDATE_ERROR';

export const PROFILE_DATA_LOAD = 'PROFILE_DATA_LOAD';
export const PROFILE_DATA_SUCCESS = 'PROFILE_DATA_SUCCESS';
export const PROFILE_DATA_ERROR = 'PROFILE_DATA_ERROR';

export const SEEKING_DATA_LOAD = 'SEEKING_DATA_LOAD';
export const SEEKING_DATA_SUCCESS = 'SEEKING_DATA_SUCCESS';
export const SEEKING_DATA_ERROR = 'SEEKING_DATA_ERROR';

export const STUDENT_AWARD_TYPES_LOAD = 'STUDENT_AWARD_TYPES_LOAD';
export const STUDENT_AWARD_TYPES_SUCCESS = 'STUDENT_AWARD_TYPES_SUCCESS';
export const STUDENT_AWARD_TYPE_ERROR = 'STUDENT_AWARD_TYPE_ERROR';

export const STUDENT_SKILLS_LOAD = 'STUDENT_SKILLS_LOAD';
export const STUDENT_SKILLS_SUCCESS = 'STUDENT_SKILLS_SUCCESS';
export const STUDENT_SKILLS_CLEAR = 'STUDENT_SKILLS_CLEAR';
export const STUDENT_SKILLS_ERROR = 'STUDENT_SKILLS_ERROR';
export const STUDENT_SKILLS_SAVE_LOAD = 'STUDENT_SKILLS_SAVE_LOAD';
export const STUDENT_SKILLS_SAVE_SUCCESS = 'STUDENT_SKILLS_SAVE_SUCCESS';
export const STUDENT_SKILLS_SAVE_ERROR = 'STUDENT_SKILLS_SAVE_ERROR';
export const STUDENT_SKILLS_UPDATE_LOAD = 'STUDENT_SKILLS_UPDATE_LOAD';
export const STUDENT_SKILLS_UPDATE_SUCCESS = 'STUDENT_SKILLS_UPDATE_SUCCESS';
export const STUDENT_SKILLS_UPDATE_ERROR = 'STUDENT_SKILLS_UPDATE_ERROR';
export const STUDENT_SKILLS_DELETE_LOAD = 'STUDENT_SKILLS_DELETE_LOAD';
export const STUDENT_SKILLS_DELETE_SUCCESS = 'STUDENT_SKILLS_DELETE_SUCCESS';
export const STUDENT_SKILLS_DELETE_ERROR = 'STUDENT_SKILLS_DELETE_ERROR';

export const SKILLS_LOAD = 'SKILLS_LOAD';
export const SKILLS_SUCCESS = 'SKILLS_SUCCESS';
export const SKILLS_ERROR = 'SKILLS_ERROR';

export const OCCUPATION_INTEREST_LOAD = 'OCCUPATION_INTEREST_LOAD';
export const OCCUPATION_INTEREST_SUCCESS = 'OCCUPATION_INTEREST_SUCCESS';
export const OCCUPATION_INTEREST_ERROR = 'OCCUPATION_INTEREST_ERROR';

export const OCCUPATION_INTEREST_DELETE_LOAD =
  'OCCUPATION_INTEREST_DELETE_LOAD';
export const OCCUPATION_INTEREST_DELETE_SUCCESS =
  'OCCUPATION_INTEREST_DELETE_SUCCESS';
export const OCCUPATION_INTEREST_DELETE_ERROR =
  'OCCUPATION_INTEREST_DELETE_ERROR';

export const ETHNICITY_LOAD = 'ETHNICITY_LOAD';
export const ETHNICITY_SUCCESS = 'ETHNICITY_SUCCESS';
export const ETHNICITY_ERROR = 'ETHNICITY_ERROR';

export const NATIVE_LANGUAGE_LOAD = 'NATIVE_LANGUAGE_LOAD';
export const NATIVE_LANGUAGE_SUCCESS = 'NATIVE_LANGUAGE_SUCCESS';
export const NATIVE_LANGUAGE_ERROR = 'NATIVE_LANGUAGE_ERROR';

export const CITIZENSHIP_LOAD = 'ETHNICITY_LOAD';
export const CITIZENSHIP_SUCCESS = 'CITIZENSHIP_SUCCESS';
export const CITIZENSHIP_ERROR = 'CITIZENSHIP_ERROR';

export const STUDENT_CREDENTIALS_LOAD = 'STUDENT_CREDENTIALS_LOAD';
export const STUDENT_CREDENTIALS_SUCCESS = 'STUDENT_CREDENTIALS_SUCCESS';
export const STUDENT_CREDENTIALS_ERROR = 'STUDENT_CREDENTIALS_ERROR';
export const STUDENT_CREDENTIALS_SAVE_LOAD = 'STUDENT_CREDENTIALS_SAVE_LOAD';
export const STUDENT_CREDENTIALS_SAVE_SUCCESS =
  'STUDENT_CREDENTIALS_SAVE_SUCCESS';
export const STUDENT_CREDENTIALS_SAVE_ERROR = 'STUDENT_CREDENTIALS_SAVE_ERROR';
export const STUDENT_CREDENTIALS_UPDATE_LOAD =
  'STUDENT_CREDENTIALS_UPDATE_LOAD';
export const STUDENT_CREDENTIALS_UPDATE_SUCCESS =
  'STUDENT_CREDENTIALS_UPDATE_SUCCESS';
export const STUDENT_CREDENTIALS_UPDATE_ERROR =
  'STUDENT_CREDENTIALS_UPDATE_ERROR';
export const STUDENT_CREDENTIALS_DELETE_LOAD =
  'STUDENT_CREDENTIALS_DELETE_LOAD';
export const STUDENT_CREDENTIALS_DELETE_SUCCESS =
  'STUDENT_CREDENTIALS_DELETE_SUCCESS';
export const STUDENT_CREDENTIALS_DELETE_ERROR =
  'STUDENT_CREDENTIALS_DELETE_ERROR';
export const STUDENT_CREDENTIALS_CLEAR = 'STUDENT_CREDENTIALS_CLEAR';

export const STUDENT_METRICS_REQUEST = 'STUDENT_METRICS_REQUEST';
export const STUDENT_METRICS_SUCCESS = 'STUDENT_METRICS_SUCCESS';
export const STUDENT_METRICS_ERROR = 'STUDENT_METRICS_ERROR';
export const STUDENT_METRICS_CLEAR = 'STUDENT_METRICS_CLEAR';

//GET INFO
export const REQUEST_PRIVACY_SETTING = 'REQUEST_PRIVACY_SETTING';
export const RECIEVE_PRIVACY_SETTING = 'RECIEVE_PRIVACY_SETTING ';
export const REQUEST_PRIVACY_SETTING_ERROR = 'REQUEST_PRIVACY_SETTING _ERROR';
// SAVE INFO
export const REQUEST_SAVE_PRIVACY_SETTING = 'REQUEST_SAVE_PRIVACY_SETTING';
export const RECIEVE_SAVE_PRIVACY_SETTING = 'RECIEVE_SAVE_PRIVACY_SETTING ';
export const REQUEST_SAVE_PRIVACY_SETTING_ERROR =
  'REQUEST_SAVE_PRIVACY_SETTING _ERROR';

// DASHBOARD_PROGRAMS
export const DASHBOARD_PROGRAMS_REQUEST = 'DASHBOARD_PROGRAMS_REQUEST';
export const DASHBOARD_PROGRAMS_SUCCESS = 'DASHBOARD_PROGRAMS_SUCCESS';
export const DASHBOARD_PROGRAMS_ERROR = 'DASHBOARD_PROGRAMS_ERROR';
export const DASHBOARD_PROGRAMS_CLEAR = 'DASHBOARD_PROGRAMS_CLEAR';

// CHANGE PASSWORD
export const ERROR_CHANGE_PASSWORD = 'ERROR_CHANGE_PASSWORD';
export const REQUEST_CHANGE_PASSWORD = 'REQUEST_CHANGE_PASSWORD';
export const RECEIVED_CHANGE_PASSWORD = 'RECEIVED_CHANGE_PASSWORD';

// CHANGE EMAIL
export const CHANGE_EMAIL_ERROR = 'CHANGE_EMAIL_ERROR';
export const CHANGE_EMAIL_REQUEST = 'CHANGE_EMAIL_REQUEST';
export const CHANGE_EMAIL_SUCCESS = 'CHANGE_EMAIL_SUCCESS';
