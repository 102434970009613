import React from 'react';
import {useSelector} from 'react-redux';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import resumeBuilderData from 'data/resumeBuilder.json';
import {Button, Grid, RequestErrorLoader} from 'mui-core';
import {goAIButtonBanner, goAIButtonIcon} from 'assets/images';
import {
  Box,
  styled,
  Dialog,
  useTheme,
  Typography,
  useMediaQuery,
  Backdrop,
} from '@mui/material';
import {getCareerCraftData} from 'redux/modules/resume-builder';

export const AIModalID = 'ai-modal-paper';

const {
  goAIButton: {title, subTitle, buttonLabel, closeLabel, modalButtonLabel},
} = resumeBuilderData || {};

const StyledBanner = styled(Box)(({theme}) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  minHeight: '120px',
  whiteSpace: 'nowrap',
  backgroundSize: 'cover',
  justifyContent: 'center',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundImage: `url(${goAIButtonBanner})`,
  [theme.breakpoints.down('md')]: {
    height: '120px',
  },
}));
const SubTitleGoAI = styled(Box)(({theme}) => ({
  position: 'relative',
  left: '90px',
  top: '-6px',
  fontWeight: '300',
  fontSize: '11px',
  background: 'rgba(29, 15, 72, 0.5)',
  boxShadow: '2px 0px 5px #1d0f48',
}));

const GoAIModal = ({
  open,
  onClose,
  children,
  description,
  descriptionCenter = false,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const {
    data: careerCraft,
    error: careerCraftErr,
    request: careerCraftReq,
  } = useSelector(getCareerCraftData);
  return (
    <Dialog
      open={open}
      maxWidth='lg'
      onClose={onClose}
      fullWidth={isMobile ? true : false}
      fullScreen={isMobile ? true : false}
      PaperProps={{
        id: AIModalID,
        style: {
          overflowX: 'hidden',
          width: !isMobile && '70%',
          borderRadius: !isMobile && '16px',
        },
      }}>
      <IconButton
        onClick={onClose}
        aria-label='close'
        sx={{
          top: 7,
          right: 8,
          zIndex: 9999,
          fontSize: '16px',
          fontWeight: '500',
          position: 'absolute',
          color: 'primary.main',
        }}>
        <CloseIcon sx={{color: 'white'}} />
      </IconButton>
      <Grid container sx={{background: theme.palette.background.paper}}>
        <Grid item xs={12}>
          <StyledBanner>
            <Box
              display='flex'
              alignItems='end'
              flexDirection='column'
              justifyContent='center'>
              <Typography
                color='white'
                sx={{
                  background: 'rgba(29, 15, 72, 0.5)',
                  boxShadow: '2px 0px 5px #1d0f48',
                }}
                variant='title32'
                dangerouslySetInnerHTML={{__html: title || ''}}
              />
              <SubTitleGoAI variant='body2' color='white'>
                {subTitle}
              </SubTitleGoAI>
            </Box>
          </StyledBanner>
        </Grid>
        <Grid item sm={12}>
          <Grid container>
            <Grid
              item
              p={2}
              sm={12}
              textAlign={descriptionCenter ? 'center' : 'start'}>
              <Typography my={2} color='text.primary' variant='desc16'>
                {description}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <RequestErrorLoader
                fitToParent
                fullWidth
                hideEmpty
                // minHeight={isMobile ? 'calc(100vh - 200px)' : 200}
                body={{
                  error: careerCraftErr,
                  request: careerCraftReq,
                }}
              />
              {children}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

const GoAIButton = ({
  children,
  fetchData,
  openDialog,
  description,
  setOpenDialog,
  descriptionCenter,
}) => {
  return (
    <>
      <Button
        size='small'
        variant='contained'
        sx={{
          width: '100px',
          borderRadius: '18px',
          background:
            'linear-gradient(180deg, rgba(120,78,229,1) 0%, rgba(90,34,170,1) 100%)',
        }}
        onClick={() => fetchData()}
        endIcon={
          <img height={16} width={16} src={goAIButtonIcon} alt='GoAI Icon' />
        }>
        {buttonLabel}
      </Button>
      {openDialog && (
        <GoAIModal
          open={openDialog}
          children={children}
          description={description}
          onClose={() => setOpenDialog(false)}
          descriptionCenter={descriptionCenter}
        />
      )}
    </>
  );
};

export default GoAIButton;
