// hooks
import {useAuth} from 'core/hooks';
import {useSnackbar} from 'notistack';
import {useInstance} from 'core/hooks';
import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
// redux
import {
  unSaveOpportunity,
  getSavedOpportunities,
  fetchSavedOpportunities,
  getAppliedOpportunities,
  updateOpportunityInterest,
  fetchAppliedOpportunities,
  removeAppliedOpportunities,
} from 'redux/modules/occupation';
import {openLoginScreen} from 'redux/modules/auth/actions';
import {fetchJobBoardsDetails, getJobBoardsDetails} from 'redux/modules/jobs';
// component
import {
  Grid,
  EmptyBox,
  DialogBox,
  ContentBox,
  themeColorMode,
  RequestErrorLoader,
} from 'mui-core';
import {
  Box,
  Chip,
  Stack,
  Button,
  styled,
  Tooltip,
  Divider,
  useTheme,
  IconButton,
  Typography,
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {Done, Launch, Favorite, FavoriteBorder} from '@mui/icons-material';
// utils
import {
  removeJobModalProps,
  saveConfirmModalProps,
  applyConfirmModalProps,
  saveJobSuccessModalProps,
  removeSavedJobModalProps,
  applyJobSuccessModalProps,
} from '../../Actions';
import isArray from 'lodash/isArray';
import {externalLinkHandler} from 'mui-core/utils';
import {capitalizeFirstLetter, numberInUSFormat} from 'core/utils';
import jobsStaticData from 'data/jobs.json';
import {benefitsArr} from 'data/opportunities.json';
import CloseIcon from '@mui/icons-material/Close';

const {
  jobsDetails: {
    emptyMsg,
    labels = {},
    removeJobMsg,
    alreadySavedMsg,
    appliedSuccessMsg,
    alreadyAppliedMsg,
    saveButtonLabels = {},
    applyButtonLabels = {},
  },
  filterForm: {jobTypes = []},
} = jobsStaticData || {};

let isAction = null;
let modalCallback = null;
let confirmModalCallback = null;

const defaultModalProps = {
  title: '',
  visible: false,
  buttonText: '',
  description: [''],
};

const defaultConfirmModalProps = {
  title: '',
  okText: '',
  cancelText: '',
  visible: false,
  description: [''],
};

const StyledTypography = styled(Typography)(({theme}) => ({
  textTransform: 'capitalize',
  color:
    theme.palette.mode === 'light'
      ? theme.palette.shadeyBlack.main
      : theme.palette.shadeyBlack.contrastText,
}));

const DescriptionContainer = styled(Typography)(({theme}) => ({
  '& ul': {
    listStyle: 'disc',
    paddingLeft: '16px',
    '& > li': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.shadeyBlack.main
          : theme.palette.shadeyBlack.contrastText,
    },
  },
}));

const formatString = str => str?.replace(/[\W_]/g, ' ');

const JobDetails = ({
  jobCardInfo,
  isMobile,
  onMobileDetailsClose,
  jobId,
  jobsRequest,
}) => {
  const theme = useTheme();
  const [token] = useAuth();
  const history = useHistory();
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const {isCriminalJustice} = useInstance();
  const SavedOpportunity = useSelector(getSavedOpportunities);
  const {job_id, job_title, company, job_source} = jobCardInfo;
  const AppliedOpportunity = useSelector(getAppliedOpportunities);
  const {request = false, data = {}} = useSelector(getJobBoardsDetails);

  const [checkSave, setCheckSave] = useState(false);
  const [checkApply, setCheckApply] = useState(false);
  const [modalProps, setModalProps] = useState(defaultModalProps);
  const [loaders, setLoaders] = useState({apply: false, save: false});
  const [confirmModalProps, setConfirmModalProps] = useState(
    defaultConfirmModalProps,
  );

  const {
    URL,
    title,
    job_url,
    city = '',
    state = '',
    skills = [],
    location = '',
    no_of_position,
    opportunity_id,
    description = '',
    opportunity_type,
    experience_level,
    job_type: jobType,
    location_type = '',
    job_post_thumbnail_cloudinary,
    institute_details: {institution_uuid, institution_name} = {},
  } = data || {};

  const themeModeColor = themeColorMode(
    theme,
    theme.palette.shadeyBlack.main,
    theme.palette.shadeyBlack.contrastText,
  );

  let locationValue = '';
  if (city && state) locationValue = `${city}, ${state}`;
  if (location) locationValue = location;
  if (location_type === 'remote') locationValue = location_type;

  const getValuesByKey = (key = '', list = []) => {
    let newValues = [];
    newValues = list?.map(item => item[key]);
    return newValues;
  };
  const salaryValidation = () => {
    let isValid = false;
    if (
      (data?.pay_type === 'range' && data?.range_from && data?.range_to) ||
      (data?.pay_type === 'up_to' && data?.range_to) ||
      ((data?.pay_type === 'starting_at' || data?.pay_type === 'exact_rate') &&
        data?.range_from)
    ) {
      isValid = true;
    }
    return isValid;
  };

  const getPayDetails = () => {
    let PayDetails = 'Salary:';
    if (salaryValidation() && data?.pay_type) {
      const PAY_TYPE = data?.pay_type.toLowerCase();
      if (PAY_TYPE === 'range') {
        PayDetails = PayDetails.substring(0, PayDetails.length - 1);
      } else if (PAY_TYPE === 'starting_at' || PAY_TYPE === 'up_to') {
        PayDetails += ` ${capitalizeFirstLetter(formatString(data?.pay_type))}`;
      }

      const Frequency = capitalizeFirstLetter(
        formatString(data?.range_frequency),
      );

      switch (PAY_TYPE) {
        case 'exact_rate':
        case 'starting_at': {
          PayDetails += ` $${numberInUSFormat(data?.range_from)} ${Frequency}`;
          break;
        }
        case 'up_to': {
          PayDetails += ` $${numberInUSFormat(data?.range_to)} ${Frequency}`;
          break;
        }
        default: {
          PayDetails += ` $${numberInUSFormat(
            data?.range_from,
          )} - $${numberInUSFormat(data?.range_to)} ${Frequency}`;
        }
      }
    } else {
      PayDetails = '';
    }
    return PayDetails;
  };

  const filteredBenefits = data?.benefits
    ?.map(ele => benefitsArr?.find(obj => obj.value === ele))
    .filter(obj => obj);

  const filteredJobType = jobTypes?.filter(obj => obj.value === jobType)[0]
    ?.label;

  const JobDetails = [
    {
      label: labels?.jobType,
      value: filteredJobType,
    },
    {label: labels?.numberOfOpenings, value: no_of_position},
    {label: labels?.pay, value: getPayDetails()},
    {
      label: labels?.benefits,
      value: filteredBenefits?.map(item => {
        return item.label;
      }),
    },
    {
      label: labels?.experienceLevel,
      value: getValuesByKey('name', experience_level),
    },
    {
      label: labels?.educationLevel,
      value: getValuesByKey('education_level', data?.education_level),
    },
    {
      label: labels?.requiredSkills,
      value:
        job_source === 'local' &&
        Array.isArray(skills) &&
        skills?.map(item => item?.skills),
    },
    {label: labels?.jobDescription, value: description},
  ];

  const onModalOk = () => {
    setModalProps(defaultModalProps);
    if (modalCallback) modalCallback();
    modalCallback = null;
  };

  const onModalClose = () => {
    setModalProps(defaultModalProps);
  };

  const openWarnModal = (modalProps, callback) => {
    setModalProps({...modalProps, visible: true});
    modalCallback = callback;
  };

  const onSaveJob = async () => {
    const data = {
      action_type: 'save',
      opportunity_name: title,
      institute_uuid: institution_uuid,
      institute_name: institution_name,
      bp_opportunity_id: opportunity_id,
      opportunity_type: opportunity_type,
      job_post_thumbnail_cloudinary: job_post_thumbnail_cloudinary,
    };
    setLoaders({save: true});
    dispatch(
      updateOpportunityInterest(data, err => {
        setLoaders({save: false});
        if (err) {
          enqueueSnackbar(err, {
            variant: 'error',
          });
        } else {
          setCheckSave(true);
        }
      }),
    );
    setLoaders({save: false});
    isAction = null;
  };

  const initLoginData = () => {
    if (isAction != 'apply' && token) {
      dispatch(fetchAppliedOpportunities());
    }
    if (isAction != 'save' && token) {
      dispatch(fetchSavedOpportunities());
    }
  };

  const onSaveJobClick = () => {
    if (token) {
      const successOpportunitiesModal = saveConfirmModalProps({
        job_type: 'job',
        openWarning: true,
      });
      openWarnModal(successOpportunitiesModal, () => {
        onSaveJob();
      });
    } else {
      isAction = 'save';
      dispatch(
        openLoginScreen({
          callback: () => {
            dispatch(
              fetchSavedOpportunities((jobs = []) => {
                const exists = jobs.filter(
                  i => i.bp_opportunity_id === opportunity_id,
                );
                if (exists[0]) {
                  enqueueSnackbar(alreadySavedMsg, {
                    variant: 'success',
                  });
                } else {
                  const successOpportunitiesModal = saveConfirmModalProps({
                    job_type: 'job',
                  });
                  openWarnModal(successOpportunitiesModal, () => {
                    onSaveJob();
                  });
                }
              }),
            );
          },
        }),
      );
    }
  };

  const openConfirmModal = (modalProps, callback) => {
    setConfirmModalProps({...modalProps, visible: true});
    confirmModalCallback = callback;
  };

  const onUnSaveJob = async () => {
    openConfirmModal(
      removeSavedJobModalProps({
        job_type: 'job',
      }),
      async () => {
        const opportunitySavedData = isOpportunitySaved();
        setLoaders({save: true});
        try {
          dispatch(
            unSaveOpportunity(opportunitySavedData.app_id, err => {
              if (!err) {
                enqueueSnackbar(removeJobMsg, {
                  variant: 'success',
                });
                dispatch(fetchSavedOpportunities());
              }
            }),
          );
          setLoaders({save: false});
        } catch (err) {
          setLoaders({save: false});
        }
      },
    );
  };

  const onApplyJob = async () => {
    setLoaders({apply: true});
    const data = {
      job_post_thumbnail_cloudinary: job_post_thumbnail_cloudinary,
      bp_opportunity_id: opportunity_id,
      opportunity_name: title,
      opportunity_type: opportunity_type,
      institute_uuid: institution_uuid,
      institute_name: institution_name,
      action_type: 'apply',
    };
    dispatch(
      updateOpportunityInterest(data, err => {
        if (err) {
          enqueueSnackbar(err, {
            variant: 'error',
          });
        } else {
          const opportunitySavedData = isOpportunitySaved();
          if (opportunitySavedData.status) {
            dispatch(unSaveOpportunity(opportunitySavedData.app_id));
          }
          enqueueSnackbar(appliedSuccessMsg, {
            variant: 'success',
          });
          setCheckApply(true);
        }
        setLoaders({apply: false});
      }),
    );
    isAction = null;
  };

  const onApplyButtonClick = () => {
    setLoaders({apply: true});
    if (job_source === 'local') {
      if (token) {
        openWarnModal(applyConfirmModalProps, () => {
          onApplyJob();
        });
      } else {
        isAction = 'apply';
        dispatch(
          openLoginScreen({
            callback: () => {
              dispatch(
                fetchAppliedOpportunities((jobs = []) => {
                  const exists = jobs.filter(
                    i => i.bp_opportunity_id === opportunity_id,
                  );
                  if (exists[0]) {
                    enqueueSnackbar(alreadyAppliedMsg, {
                      variant: 'success',
                    });
                  } else {
                    onApplyJob();
                  }
                }),
              );
            },
          }),
        );
      }
    } else {
      if (URL || job_url) {
        window.open(URL || job_url, '_blank');
      }
    }
    setLoaders({apply: false});
  };

  const onRemoveApply = async () => {
    openConfirmModal(
      removeJobModalProps({
        job_type: 'job',
      }),
      async () => {
        const job = opportunityApplied(true);
        setLoaders({apply: true});
        try {
          await dispatch(
            removeAppliedOpportunities(job.opp_application_uuid, data => {
              if (data.Success) {
                enqueueSnackbar(removeJobMsg, {
                  variant: 'success',
                });
              }
            }),
          );
          dispatch(fetchSavedOpportunities());
          dispatch(fetchAppliedOpportunities());
          setLoaders({apply: false});
        } catch (e) {
          setLoaders({apply: false});
        }
      },
    );
  };

  const showDescription = modalProps.visible
    ? modalProps.description
    : confirmModalProps.description;
  const showCancel = modalProps.visible
    ? modalProps.cancelText
    : confirmModalProps.cancelText;
  const showOk = modalProps.visible
    ? modalProps.buttonText
    : confirmModalProps.okText;
  const showTitle = modalProps.visible
    ? modalProps.title
    : confirmModalProps.title;

  const isOpportunitySaved = () => {
    if (SavedOpportunity && SavedOpportunity?.data) {
      const exists = SavedOpportunity?.data?.filter(
        i => i?.bp_opportunity_id === opportunity_id,
      );
      if (exists[0]) {
        return {
          app_id: exists[0].opp_application_uuid,
          status: true,
        };
      }
    }
    return {
      status: false,
    };
  };

  const opportunityApplied = isReturnAppliedJobData => {
    if (AppliedOpportunity && AppliedOpportunity.data) {
      const exists = AppliedOpportunity?.data?.filter(
        i => i.bp_opportunity_id === opportunity_id,
      );
      if (exists[0]) return isReturnAppliedJobData ? exists[0] : true;
    }
    return false;
  };

  const {status: opportunitySavedStatus} = isOpportunitySaved();
  const isOpportunityApplied = opportunityApplied();

  const onConfirmModalOk = () => {
    setConfirmModalProps(defaultConfirmModalProps);
    if (confirmModalCallback) confirmModalCallback();
    confirmModalCallback = null;
  };

  const onConfirmModalCancel = () => {
    setConfirmModalProps(defaultConfirmModalProps);
  };

  useEffect(() => {
    job_source === 'local' && initLoginData();
    const id = job_source === 'local' ? job_title : job_id;
    if (id !== 'null')
      dispatch(fetchJobBoardsDetails(id, job_source, company, 'job'));
  }, [jobId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (checkSave) {
      const successModal = saveJobSuccessModalProps({
        job_type: 'job',
        job_title: title,
        company_name: institution_name,
      });
      openWarnModal(successModal, () => {
        history.push('/dashboard/opportunities');
      });
      setCheckSave(false);
    }
    token && job_source === 'local' && dispatch(fetchSavedOpportunities());
  }, [checkSave]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (checkApply) {
      const successModal = applyJobSuccessModalProps({
        job_type: 'job',
        job_title: title,
        company_name: institution_name,
      });
      openWarnModal(successModal, () => {
        history.push('/dashboard/opportunities');
      });
      setCheckApply(false);
    }
    token && job_source === 'local' && dispatch(fetchAppliedOpportunities());
  }, [checkApply]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box>
      <RequestErrorLoader
        fullScreen
        hideBackground
        overrideNoDataContainer={<EmptyBox emptyText={emptyMsg} />}
        body={{
          request: request || jobsRequest,
          data: data,
        }}>
        <Grid sm={8}>
          <ContentBox
            variant='outlined'
            sx={{p: 3, position: 'relative', pt: isMobile ? 5 : 3}}
            className='paper-c'>
            {isMobile && (
              <IconButton
                size='small'
                sx={{position: 'absolute', left: 21, top: 5}}
                onClick={onMobileDetailsClose}>
                <CloseIcon fontSize='small' />
              </IconButton>
            )}
            <Stack gap={1}>
              <Stack
                alignItems='center'
                flexDirection='row'
                justifyContent='space-between'>
                <StyledTypography variant='h5'>
                  {job_source === 'local' ? title : job_title}
                </StyledTypography>
                {job_source === 'local' && !isOpportunityApplied && (
                  <Tooltip
                    title={
                      opportunitySavedStatus
                        ? saveButtonLabels?.saveForLater
                        : saveButtonLabels?.save
                    }>
                    <IconButton
                      color={opportunitySavedStatus ? 'success' : 'black'}
                      disabled={SavedOpportunity?.request || loaders?.save}
                      onClick={
                        opportunitySavedStatus ? onUnSaveJob : onSaveJobClick
                      }>
                      {opportunitySavedStatus ? (
                        <Favorite />
                      ) : (
                        <FavoriteBorder />
                      )}
                    </IconButton>
                  </Tooltip>
                )}
              </Stack>
              {company && (
                <StyledTypography variant='h6'>{company}</StyledTypography>
              )}
              {((city && state) || location) && (
                <StyledTypography variant='body1'>
                  {locationValue}
                </StyledTypography>
              )}
              {!isCriminalJustice &&
                (job_url ? (
                  <Button
                    variant='contained'
                    sx={{width: '198px'}}
                    onClick={() => externalLinkHandler(job_url)}
                    endIcon={<Launch sx={{marginBottom: '2px'}} />}>
                    {applyButtonLabels?.applyNow}
                  </Button>
                ) : (
                  <LoadingButton
                    variant='contained'
                    sx={{width: '198px'}}
                    startIcon={isOpportunityApplied && <Done />}
                    color={isOpportunityApplied ? 'success' : 'primary'}
                    loading={AppliedOpportunity.request || loaders?.apply}
                    disabled={AppliedOpportunity.request || loaders?.apply}
                    onClick={
                      isOpportunityApplied ? onRemoveApply : onApplyButtonClick
                    }>
                    {isOpportunityApplied
                      ? applyButtonLabels?.applied
                      : applyButtonLabels?.applyNow}
                  </LoadingButton>
                ))}
            </Stack>
            <Divider sx={{margin: '12px 0px'}} />
            <Grid container rowSpacing={1.5} spacing={1} alignItems='baseline'>
              {JobDetails.map((item, index) => (
                <>
                  {item.value && item.value?.length > 0 && (
                    <>
                      <Grid key={`label-${index}`} item xs={3}>
                        <Typography variant='h7' color={themeModeColor}>
                          {item.label}
                        </Typography>
                      </Grid>
                      <Grid key={`value-${index}`} item xs={9}>
                        {isArray(item.value) ? (
                          <Stack direction='row' gap={1} flexWrap='wrap'>
                            {item.value.map(item => (
                              <Chip label={`${item}`} />
                            ))}
                          </Stack>
                        ) : item.label === 'Job Description:' ? (
                          <DescriptionContainer
                            color={themeModeColor}
                            dangerouslySetInnerHTML={{
                              __html: item.value,
                            }}
                          />
                        ) : (
                          <Typography variant='body1' color={themeModeColor}>
                            {item.value}
                          </Typography>
                        )}
                      </Grid>
                    </>
                  )}
                </>
              ))}
            </Grid>
          </ContentBox>
        </Grid>
      </RequestErrorLoader>

      <DialogBox
        openDialog={modalProps.visible || confirmModalProps.visible}
        closeDialog={modalProps.visible ? onModalClose : onConfirmModalCancel}
        showIcon={modalProps.visible ? modalProps.icon : confirmModalProps.icon}
        actions={
          <>
            {showCancel && (
              <Button
                variant='outlined'
                sx={{minWidth: {xs: '80px', md: '168px'}}}
                onClick={
                  modalProps.visible ? onModalClose : onConfirmModalCancel
                }>
                {showCancel}
              </Button>
            )}
            {showOk && (
              <Button
                variant='contained'
                sx={{minWidth: {xs: '80px', md: '168px'}}}
                onClick={modalProps.visible ? onModalOk : onConfirmModalOk}>
                {showOk}
              </Button>
            )}
          </>
        }>
        <Typography variant='h4' textAlign='center' color='text.primary'>
          {showTitle}
        </Typography>
        {showDescription?.map(desc => (
          <Typography
            key={desc}
            variant='body1'
            textAlign='center'
            color='text.primary'
            dangerouslySetInnerHTML={{
              __html: desc,
            }}
          />
        ))}
      </DialogBox>
    </Box>
  );
};

export default JobDetails;
