import {API} from 'config';
import * as TYPES from './constants';
import {Services} from 'core/Services';
import {
  clearNonProfitCompanyMembersList,
  requestNonProfitCompanyMembersList,
  recieveNonProfitCompanyMembersList,
  recieveNonProfitCompanyAllMembersList,
  requestNonProfitCompanyMembersListError,
} from './actions';

const initialState = {
  data: {},
  error: false,
  request: false,
};
const services = new Services();

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_NONPROFIT_COMPANY_MEMBERSLIST:
      return {
        ...state,
        error: false,
        request: true,
      };
    case TYPES.RECIEVE_NONPROFIT_COMPANY_MEMBERSLIST:
      return {
        ...state,
        error: false,
        request: false,
        data: action.payload,
      };
    case TYPES.RECIEVE_NONPROFIT_COMPANY_ALL_MEMBERSLIST:
      let data = action.payload;
      if (state.data && state.data.license) {
        data.license = [...state.data.license, ...data.license];
      }
      return {
        ...state,
        error: false,
        request: false,
        data,
      };
    case TYPES.REQUEST_NONPROFIT_COMPANY_LIST_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    case TYPES.CLEAR_NONPROFIT_COMPANY_LIST:
      return initialState;
    default:
      return state;
  }
};

export const fetchNonProfitCompanyMembersList = (
  nonprofit_id,
  params,
  filters,
  loadmore = false,
) => (dispatch, _getState) => {
  if (!nonprofit_id) {
    return;
  }

  const {page_size = 16, page = 1, sort = 'desc'} = params;
  let endPoint = `${API.srm.nonProfit}/?show=member_list&parent=${nonprofit_id}&page_size=${page_size}&page=${page}&sort=${sort}`;
  dispatch(requestNonProfitCompanyMembersList());

  const filterKeys = Object.keys(filters);
  if (filterKeys && filterKeys.length) {
    filterKeys.forEach(key => {
      if (!(key === 'type' && filters[key] === 'all') && filters[key]) {
        endPoint += `&${key}=${filters[key]}`;
      }
    });
  }
  try {
    return services.fetchRecords(endPoint, null, true).then(res => {
      if (res) {
        if (loadmore) {
          dispatch(recieveNonProfitCompanyAllMembersList(res)); // Concatinate prev result to current result on loadmore
        } else {
          dispatch(recieveNonProfitCompanyMembersList(res));
        }
        return;
      }
      dispatch(requestNonProfitCompanyMembersListError(res));
    });
  } catch (err) {
    console.log(err);
    dispatch(requestNonProfitCompanyMembersListError(err));
  }
};

export const resetNonProfitCompanyMembersList = () => (dispatch, getState) => {
  dispatch(clearNonProfitCompanyMembersList());
};
