import {useMemo} from 'react';
import {Grid} from 'mui-core';
import PropTypes from 'prop-types';
import {isObject, isEmpty} from 'lodash';
import ErrorIcon from '@mui/icons-material/Error';
import NoDataIcon from 'mui-core/Icons/NoDataIcon';
import {alpha, useTheme} from '@mui/material/styles';
import {Box, Typography, CircularProgress} from '@mui/material';

const RequestErrorLoader = ({
  children,
  fullWidth,
  body = {},
  minHeight,
  hideEmpty,
  fullScreen,
  hideEmptyIcon,
  hideBackground, // UI is removed from dom while loading
  overridedLoader,
  title = 'Loading...',
  overrideNoDataContainer,
  emptyMsg = 'No Results Found',
  errorMsg = 'Something went wrong.',
  fitToParent,
}) => {
  const theme = useTheme();
  const {request, data, error} = body || {};
  let fullScreenStyles = {};
  if (fullScreen && request) {
    fullScreenStyles = {
      top: 0,
      left: 0,
      zIndex: 1020,
      width: fitToParent ? '100%' : '100vw',
      height: fitToParent ? '100%' : '100vh',
      display: 'flex',
      position: fitToParent ? 'absolute' : 'fixed',
      alignItems: 'center',
      backgroundColor: alpha(theme.palette.background.paper, 0.8),
    };
  }

  const LoadingScreen = () =>
    overridedLoader ? (
      overridedLoader
    ) : (
      <Grid
        xs
        display='flex'
        alignItems='center'
        justifyContent='center'
        sx={{position: 'absolute', width: '100%', height: '100%', zIndex: '5'}}>
        <CircularProgress size={30} />
        <Typography variant='subtitle1' ml={2} color='text.primary'>
          {title}
        </Typography>
      </Grid>
    );

  const EmptyScreen = () =>
    overrideNoDataContainer ? (
      overrideNoDataContainer
    ) : (
      <Grid
        xs
        display='flex'
        alignItems='center'
        flexDirection='column'
        justifyContent='center'
        sx={{position: 'relative', width: '100%'}}>
        {!hideEmptyIcon && <NoDataIcon sx={{fontSize: 64}} />}
        <Typography>{emptyMsg}</Typography>
      </Grid>
    );

  const ErrorScreen = () => (
    <Grid
      xs
      display='flex'
      alignItems='center'
      flexDirection='column'
      justifyContent='center'
      sx={{position: 'relative', width: '100%'}}>
      {!hideEmptyIcon && <ErrorIcon sx={{color: 'error.main', mb: 1}} />}
      <Typography>{errorMsg}</Typography>
    </Grid>
  );

  const ChildrenScreen = () => (
    <Box
      sx={{
        transition: '2s all',
        width: fullWidth ? '100%' : 'auto',
        opacity: request ? (!hideBackground ? 0.25 : 0) : 1,
      }}>
      {children}
    </Box>
  );

  const isDataEmpty = useMemo(() => {
    if (!data) {
      return true;
    } else {
      return data &&
        ((isObject(data) && isEmpty(data)) ||
          (Array.isArray(data) && data.length === 0))
        ? true
        : false;
    }
  }, [data]);

  return (
    <Box
      alignItems='center'
      justifyContent='center'
      display={isDataEmpty ? 'flex' : 'block'}
      sx={{
        position: 'relative',
        minHeight: minHeight || 'auto',
        ...fullScreenStyles,
      }}>
      {error ? (
        <ErrorScreen />
      ) : !hideBackground ? (
        <>
          {request && <LoadingScreen />}
          {!request && isDataEmpty && !hideEmpty && <EmptyScreen />}
          <ChildrenScreen />
        </>
      ) : (
        <>
          {request && <LoadingScreen />}
          {!request && isDataEmpty && !hideEmpty ? (
            <EmptyScreen />
          ) : (
            <ChildrenScreen />
          )}
        </>
      )}
    </Box>
  );
};

RequestErrorLoader.propTypes = {
  body: PropTypes.object,
  title: PropTypes.string,
  hideEmpty: PropTypes.bool,
  fullWidth: PropTypes.bool,
  fullScreen: PropTypes.bool, // To make the loader as full screen
  emptyMsg: PropTypes.string,
  errorMsg: PropTypes.string,
  minHeight: PropTypes.number,
  children: PropTypes.element,
  hideEmptyIcon: PropTypes.bool,
  hideBackground: PropTypes.bool,
  overridedLoader: PropTypes.element,
  overrideNoDataContainer: PropTypes.element,
};

export default RequestErrorLoader;
