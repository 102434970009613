import {Stack, Select, MenuItem, FormControl, InputLabel} from '@mui/material';
import {partnersListData} from 'data/business-partners.json';

const SortData = [
  {
    title: 'Newest',
    value: 'date_desc',
  },
  {
    title: 'A - Z',
    value: 'title_asc',
  },
  {
    title: 'Z - A',
    value: 'title_desc',
  },
];

const ApprenticeshipSorter = ({sort, onChange}) => {
  const {sorterStaticData} = partnersListData;

  return (
    <Stack alignItems='flex-end' mt={1}>
      <FormControl>
        <InputLabel id='sort-by-label' htmlFor={sorterStaticData.heading}>
          {sorterStaticData.heading}
        </InputLabel>
        <Select
          size='small'
          value={sort}
          defaultValue={sort}
          labelId='sort-by-label'
          label={sorterStaticData.heading}
          aria-label={sorterStaticData.heading}
          sx={{minWidth: '120px', mb: {xs: 1}}}
          inputProps={{id: sorterStaticData.heading}}
          onChange={event => onChange('sort', event.target.value)}>
          {SortData.map(({value, title}, idx) => (
            <MenuItem value={value} key={idx}>
              {title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};
export default ApprenticeshipSorter;
