import {createTheme, responsiveFontSizes} from '@mui/material/styles';
import {MyriadProFonts} from 'assets/fonts/MyriadPro';
import {PoppinsFonts} from 'assets/fonts/poppins';
import {componentsTheme} from 'mui-core';

const getAppTheme = mode => {
  const theme = createTheme({
    palette: {
      mode,
      primary: {
        main: '#4E36F5',
        dark: '#635BFF',
        light: '#635BFF',
        darker: '#4635AC',
      },
      secondary: {
        main: '#086EA1',
        dark: '#2A78C0',
        light: '#6EE2FF',
        constrastDark: '#21609C',
      },
      success: {
        main: '#52c41a',
        light: '#689f38',
        dark: '#507D2C',
        contrastText: 'white', // for button: success status
      },
      warn: {
        dark: '#A36200',
      },
      purplebg4perc: {
        main: '#7960DF0A', // '#faf9fd',
      },
      purplebg8perc: {
        main: '#efebfa', // '#7960DF14',
      },
      purplebg12perc: {
        main: '#7960DF1F',
      },
      purplebg: {
        main: '#f3f2fc',
      },
      darkGray: {
        main: '#333333',
        contrastText: 'white',
      },
      orange: {
        main: '#FFB400',
      },
      lightBlue: {
        main: '#3bc4ff',
      },
      grayLight: {
        main: '#f9f8ff',
      },
      lightGrey: {
        main: '#F9FAFB',
        light: '#F1F1F4',
      },
      midGray: {
        main: '#a0a4b5',
        contrastText: 'white',
      },
      grayBorder: {
        main: '#D8D8D8',
      },
      grey: {
        main: '#667085',
        contrastText: '#fff',
      },
      grayText: {
        main: '#707070',
        contrastText: 'white',
      },
      dialogDarkBG: {
        main: '#121212',
      },
      shadeyBlack: {
        main: '#212636',
        contrastText: 'white',
      },
    },
    components: {
      ...componentsTheme.components,
      MuiButton: {
        styleOverrides: {
          root: ({ownerState}) => ({
            ...(ownerState.color === 'primary' && {
              color: '#635BFF',
            }),
            ...(ownerState.variant === 'contained' &&
              ownerState.color === 'primary' &&
              ownerState.disabled !== true && {
                color: '#fff',
                border: '1px solid #4E36F5',
                boxShadow: '0px 1px 2px 0px #00000014',
                background: 'linear-gradient(#635BFF, #4E36F5)',
              }),
            ...(ownerState.variant === 'outlined' &&
              ownerState.color === 'primary' && {
                color: '#635BFF',
                border: '1px solid #635BFF80',
                boxShadow: '0px 1px 2px 0px #00000014',
              }),
            ...(ownerState.size === 'medium' && {
              fontSize: '14px',
              minHeight: '40px',
              padding: '4px 12px',
              lineHeight: '24px',
            }),
            ...(ownerState.size === 'small' && {
              fontSize: '12px',
            }),
            ...(ownerState.size === 'large' && {
              fontSize: '17px',
            }),
            ...(ownerState.width && {
              width: ownerState.width,
            }),
            borderRadius: '8px',
            textTransform: 'capitalize',
          }),
        },
      },
      MuiCssBaseline: {
        styleOverrides:
          //     `
          //     @font-face {
          //       font-family: 'Poppins';
          //       src:  url('${PoppinsFonts.REGULAR.EOT}');
          //       src:  url('${PoppinsFonts.REGULAR.EOT}?#iefix') format('embedded-opentype'),
          //             url('${PoppinsFonts.REGULAR.WOFF2}') format('woff2'),
          //             url('${PoppinsFonts.REGULAR.OTF}') format('otf'),
          //             url('${PoppinsFonts.REGULAR.WOFF}') format('woff'),
          //             url('${PoppinsFonts.REGULAR.TTF}') format('truetype');
          //       font-weight: normal;
          //       font-style: normal;
          //       font-display: swap;
          //     }
          //     @font-face {
          //       font-family: 'Poppins';
          //       src:  url('${PoppinsFonts.LIGHT.EOT}');
          //       src:  url('${PoppinsFonts.LIGHT.EOT}?#iefix') format('embedded-opentype'),
          //             url('${PoppinsFonts.LIGHT.WOFF2}') format('woff2'),
          //             url('${PoppinsFonts.LIGHT.WOFF}') format('woff'),
          //             url('${PoppinsFonts.LIGHT.TTF}') format('truetype');
          //       font-weight: 300;
          //       font-style: normal;
          //       font-display: swap;
          //     }
          //     @font-face {
          //       font-family: 'Poppins';
          //       src:  url('${PoppinsFonts.MEDIUM.EOT}');
          //       src:  url('${PoppinsFonts.MEDIUM.EOT}?#iefix') format('embedded-opentype'),
          //             url('${PoppinsFonts.MEDIUM.WOFF2}') format('woff2'),
          //             url('${PoppinsFonts.MEDIUM.WOFF}') format('woff'),
          //             url('${PoppinsFonts.MEDIUM.TTF}') format('truetype');
          //       font-weight: 500;
          //       font-style: normal;
          //       font-display: swap;
          //     }
          //     @font-face {
          //       font-family: 'Poppins';
          //       src:  url('${PoppinsFonts.BOLD.EOT}');
          //       src:  url('${PoppinsFonts.BOLD.EOT}?#iefix') format('embedded-opentype'),
          //             url('${PoppinsFonts.BOLD.WOFF2}') format('woff2'),
          //             url('${PoppinsFonts.BOLD.WOFF}') format('woff'),
          //             url('${PoppinsFonts.BOLD.TTF}') format('truetype');
          //       font-weight: 600;
          //       font-style: normal;
          //       font-display: swap;
          //     }
          //     /* Scrollbar styles */
          //     &::-webkit-scrollbar {
          //       width: 4px !important;
          //       height: 4px !important;
          //       background-color: rgba(123, 96, 223, 0.3);
          //     }
          //     &::-webkit-scrollbar-thumb {
          //       background-color: #7960DF !important;
          //       border-radius: 14px;
          //     }
          // `,

          ` @font-face {
      font-family: 'MyriadPro';
      src: url('${MyriadProFonts.REGULAR.OTF}');
      src: url('${MyriadProFonts.REGULAR.OTF}') format('otf');
      src: url('${MyriadProFonts.REGULAR.TTF}') format('ttf');
      src: url('${MyriadProFonts.REGULAR.TTF}') format('eot');
      src: url('${MyriadProFonts.REGULAR.TTF}') format('woff'); 
      src: url('${MyriadProFonts.REGULAR.TTF}') format('woff2');
      font-weight: 400;
      font-style: normal;
      font-display: swap;
    }
    @font-face {
      font-family: 'MyriadPro';
      src: url('${MyriadProFonts.LIGHT.OTF}');
      src: url('${MyriadProFonts.LIGHT.OTF}') format('otf');
      src: url('${MyriadProFonts.LIGHT.TTF}') format('ttf');
      src: url('${MyriadProFonts.LIGHT.TTF}') format('eot');
      src: url('${MyriadProFonts.LIGHT.TTF}') format('woff');
      src: url('${MyriadProFonts.LIGHT.TTF}') format('woff2');
      font-weight: 300;
      font-display: swap;
    }
    @font-face {
      font-family: 'MyriadPro';
      src: url('${MyriadProFonts.SEMIBOLD.OTF}');
      src: url('${MyriadProFonts.SEMIBOLD.OTF}') format('otf');
      src: url('${MyriadProFonts.SEMIBOLD.TTF}') format('ttf');
      src: url('${MyriadProFonts.SEMIBOLD.TTF}') format('eot');
      src: url('${MyriadProFonts.SEMIBOLD.TTF}') format('woff');
      src: url('${MyriadProFonts.SEMIBOLD.TTF}') format('woff2');
      font-weight: 500;
      font-style: normal;
      font-display: swap;
    }
    @font-face {
      font-family: 'MyriadPro';
      src: url('${MyriadProFonts.BOLD.OTF}');
      src: url('${MyriadProFonts.BOLD.OTF}') format('otf');
      src: url('${MyriadProFonts.BOLD.TTF}') format('ttf');
      src: url('${MyriadProFonts.BOLD.TTF}') format('eot');
      src: url('${MyriadProFonts.BOLD.TTF}') format('woff');
      src: url('${MyriadProFonts.BOLD.TTF}') format('woff2');
      font-weight: 600;
      font-style: normal;
      font-display: swap;
    }
    /* Scrollbar styles */
    &::-webkit-scrollbar {
      width: 4px !important;
      height: 4px !important;
      background-color: rgba(123, 96, 223, 0.3);
    }
    &::-webkit-scrollbar-thumb {
      background-color: #0367FF !important;
      border-radius: 14px;
    }`,
      },
    },
    typography: {
      fontFamily: 'MyriadPro',
      h1: {
        fontSize: '50px',
        fontWeight: 500,
      },
      h2: {
        fontSize: '45px',
        fontWeight: 500,
      },
      h3: {
        fontSize: '30px',
        fontWeight: 500,
      },
      h4: {
        fontSize: '25px',
        fontWeight: 500,
      },
      h5: {
        fontSize: '22px',
        fontWeight: 500,
      },
      h6: {
        fontSize: '16px',
        fontWeight: 500,
      },
      overline: {
        fontSize: '12px',
        fontWeight: 400,
      },
      caption: {
        fontSize: '12px',
        fontWeight: 400,
      },
      button: {
        fontWeight: 400,
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1400,
        xl: 1536,
      },
    },
  });
  // custom compoonents
  // typography
  const defaultFontProps = {
    fontFamily: 'MyriadPro',
  };
  theme.typography['h7'] = {
    ...defaultFontProps,
    fontSize: 14,
    fontWeight: 500,
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  };
  theme.typography['body1'] = {
    ...defaultFontProps,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  };
  theme.typography['body2'] = {
    ...defaultFontProps,
    fontSize: 12,
    fontWeight: 400,
    [theme.breakpoints.down('md')]: {
      fontSize: 10,
    },
  };
  theme.typography['bodyL'] = {
    ...defaultFontProps,
    fontSize: 16,
    fontWeight: 400,
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },
  };
  theme.typography['bodyXL'] = {
    ...defaultFontProps,
    fontSize: 18,
    fontWeight: 400,
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },
  };
  theme.typography['subtitle1'] = {
    ...defaultFontProps,
    fontSize: 16,
    fontWeight: 400,
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },
  };
  theme.typography['subtitle2'] = {
    ...defaultFontProps,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  };
  theme.typography['bannerTitle'] = {
    ...defaultFontProps,
    fontSize: 50,
    fontWeight: 500,
    lineHeight: '50px',
    [theme.breakpoints.down('md')]: {
      fontSize: 42,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 32,
      lineHeight: '40px',
    },
  };
  theme.typography['metricNo'] = {
    ...defaultFontProps,
    fontSize: 28,
    fontWeight: 300,
    [theme.breakpoints.down('md')]: {
      fontSize: 24,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
  };
  theme.typography['title55'] = {
    ...defaultFontProps,
    // fontFamily: 'Poppins',
    lineHeight: '70px',
    fontWeight: 400,
    fontSize: 55,
    [theme.breakpoints.down('md')]: {
      lineHeight: '30px',
      fontSize: 28,
    },
  };
  theme.typography['title32'] = {
    ...defaultFontProps,
    // fontFamily: 'Poppins',
    lineHeight: '45px',
    fontWeight: 400,
    fontSize: 32,
    [theme.breakpoints.down('md')]: {
      lineHeight: '30px',
      fontSize: 28,
    },
  };
  theme.typography['desc25'] = {
    ...defaultFontProps,
    // fontFamily: 'Poppins',
    lineHeight: '35px',
    fontWeight: 300,
    fontSize: 25,
    [theme.breakpoints.down('md')]: {
      lineHeight: '24px',
      fontSize: 18,
    },
  };
  theme.typography['desc16'] = {
    ...defaultFontProps,
    // fontFamily: 'Poppins',
    lineHeight: '24px',
    fontWeight: 300,
    fontSize: 16,
    [theme.breakpoints.down('md')]: {
      lineHeight: '20px',
      fontSize: 14,
    },
  };
  theme.typography['pagetitle'] = {
    ...defaultFontProps,
    // fontFamily: 'Poppins',
    lineHeight: '60px',
    fontWeight: 600,
    fontSize: 50,
    [theme.breakpoints.down('md')]: {
      lineHeight: '30px',
      fontSize: 30,
    },
  };
  theme.typography['programtitle'] = {
    ...defaultFontProps,
    // fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 30,
    [theme.breakpoints.down('md')]: {
      lineHeight: '30px',
      fontSize: 24,
    },
  };
  theme.typography['pagedesc'] = {
    ...defaultFontProps,
    fontSize: 19,
    fontWeight: 400,
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
  };
  return responsiveFontSizes(theme);
};

export {getAppTheme};
