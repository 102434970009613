// hooks
import {useInstance} from 'core/hooks';
import {useEffect, useRef} from 'react';
import {useForm} from 'react-hook-form';
// components
import {
  Stack,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  IconButton,
  FormControl,
  Autocomplete,
  InputAdornment,
  createFilterOptions,
} from '@mui/material';
import {createField} from 'mui-core/Form';
import {DebounceInput, LocationSearch} from 'mui-core';
// utils
import {debounce} from 'lodash';
import PropTypes from 'prop-types';
import ClearIcon from '@mui/icons-material/Clear';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const menuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const filterBy = [
  {name: 'All', uuid: 'all'},
  {name: 'In-Demand', uuid: 'in_demand'},
  {name: 'Popular', uuid: 'popular_path'},
  {name: 'New', uuid: 'newest_path'},
];

const filterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: option => option.options,
});

const FiltersForm = ({
  onFinish,
  filters = [],
  careerAreaList = [],
  durationOptions = [],
  awardTypeOptions = [],
  connectedInstitutions = [],
}) => {
  const form = useForm();
  const autocompleteRef = useRef();
  const {reset, watch, setValue} = form;
  const {data: instanceData} = useInstance();
  const changeForm = watch();

  useEffect(() => {
    // console.log(changeForm);
    const subscription = watch(debounce(onFinish, 500));
    return () => subscription.unsubscribe();
  }, [changeForm]); // eslint-disable-line react-hooks/exhaustive-deps

  const onCareerAreaChange = e => {
    reset({
      page: 1,
      type: 'all',
      duration: 'all',
      award_type: null,
      location: filters.location,
      inst_uuid: filters.inst_uuid,
      cluster_name: e.target.value,
      path_title: filters.path_title,
    });
    if (autocompleteRef && autocompleteRef.current) {
      const clearIcon = autocompleteRef.current.querySelector(
        '.MuiAutocomplete-clearIndicator',
      );
      if (clearIcon) clearIcon.click();
    }
  };

  const onTypeChange = (e, v) => {
    // console.log('onTypeChange', e, v);
    reset({
      page: 1,
      duration: 'all',
      award_type: null,
      type: e.target.value,
      location: filters.location,
      inst_uuid: filters.inst_uuid,
      path_title: filters.path_title,
    });
    if (autocompleteRef && autocompleteRef.current) {
      const clear = autocompleteRef.current.querySelector(
        '.MuiAutocomplete-clearIndicator',
      );
      if (clear) clear.click();
    }
  };

  return (
    <Stack gap={3}>
      {careerAreaList &&
        Array.isArray(careerAreaList) &&
        careerAreaList.length > 0 && (
          <>
            {createField('cluster_name', {
              form,
              isVirtual: true,
              defaultValue: 'all',
            })}
            <FormControl fullWidth>
              <InputLabel htmlFor='Career Area' id='cluster-area-label'>
                Career Area
              </InputLabel>
              <Select
                defaultValue='all'
                label='Career Area'
                MenuProps={menuProps}
                aria-label='Career Area'
                inputProps={{id: 'Career Area'}}
                onChange={onCareerAreaChange}>
                <MenuItem value='all' key='all'>
                  All
                </MenuItem>
                {careerAreaList.map(option => (
                  <MenuItem
                    key={option.cluster_uuid}
                    value={option.cluster_name}>
                    {option.cluster_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        )}
      {createField('type', {
        form,
        // isVirtual: true,
        label: 'Display',
        defaultValue: 'all',
      })}
      <FormControl fullWidth>
        <InputLabel id='display-label' htmlFor='Display'>
          Display
        </InputLabel>
        <Select
          label='Display'
          aria-label='Display'
          value={filters?.type}
          onChange={onTypeChange}
          inputProps={{id: 'Display'}}
          defaultValue={filters?.type}>
          {filterBy.map(option => (
            <MenuItem value={option.uuid} key={option.uuid}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {createField('path_title', {
        label: 'Search',
        form,
        defaultValue: '',
        timeout: 2000,
        isVirtual: true,
      })}
      <FormControl fullWidth>
        <InputLabel id='search-by-title' htmlFor='Search'>
          Search
        </InputLabel>
        <DebounceInput
          label='Search'
          variant='outlined'
          id='educator-title'
          aria-label='Search'
          inputProps={{id: 'Search'}}
          value={changeForm.path_title}
          placeholder='Search by title'
          onChange={e => setValue('path_title', e.target.value)}
          endAdornment={
            changeForm.path_title ? (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={() => setValue('path_title', '')}
                  edge='end'>
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ) : null
          }
          sx={{fontSize: {xs: 10, sm: 12, md: 15}}}
        />
      </FormControl>
      {createField('location', {
        form,
        defaultValue: null,
        isVirtual: true,
      })}
      {instanceData && !instanceData.super_type_name && (
        <LocationSearch
          filters={{}}
          LocationOptions={[]}
          onEnterPress={v => {
            if (v) setValue('location', v);
          }}
          calledFromForm
          onInputChange={(e, r, t) => {
            // console.log('dddd', e, r, t);
          }}
          onClear={() => setValue('location', '')}
          onLocationChange={val => {
            setValue('location', val.city ? val.city : val.location);
          }}
        />
      )}
      {createField('award_type', {
        form,
        defaultValue: null,
      })}
      <Autocomplete
        freeSolo
        ref={autocompleteRef}
        disablePortal
        filterOptions={filterOptions}
        onChange={(_e, v, r) => {
          if (r === 'clear' || r === 'selectOption') {
            setValue('award_type', v && v.options ? v.options : '');
          }
          // console.log(_e, v, r);
        }}
        id={'uuid'}
        value={
          changeForm.award_type
            ? awardTypeOptions.filter(
                i => i.options === changeForm.award_type,
              )[0]
            : null
        }
        options={awardTypeOptions || []}
        getOptionLabel={option => option.options}
        renderInput={params => (
          <TextField {...params} label='Award Type' placeholder='Award Type' />
        )}
      />
      {/* // <Select label='Award Type'>
              //   <MenuItem value={'all'} key={'all'}>
              //     All
              //   </MenuItem>
              //   {Array.isArray(awardTypeOptions) &&
              //     awardTypeOptions.map(option => (
              //       <MenuItem value={option.options} key={option.uuid}>
              //         {option.options}
              //       </MenuItem>
              //     ))}
              // </Select>, */}
      {createField('inst_uuid', {
        form,
        isVirtual: !(
          instanceData && instanceData.super_type_name === 'Consortium'
        ),
        defaultValue: 'all',
      })(
        Array.isArray(connectedInstitutions) &&
          instanceData &&
          instanceData.super_type_name === 'Consortium' &&
          connectedInstitutions.length > 0 ? (
          <FormControl fullWidth>
            <InputLabel id='institution-label' htmlFor='Institution'>
              {instanceData && instanceData.super_type_name === 'Consortium'
                ? 'Institution'
                : ''}
            </InputLabel>
            <Select
              label='Institution'
              inputProps={{id: 'Institution'}}
              onChange={e => setValue('inst_uuid', e.target.value)}
              defaultValue='all'
              aria-label='Institution'>
              <MenuItem value={'all'} key={'all'}>
                {'All'}
              </MenuItem>
              {Array.isArray(connectedInstitutions) &&
                connectedInstitutions.map(option => (
                  <MenuItem
                    value={option.institution_id}
                    key={option.institution_id}>
                    {option.institute_name} - {option.city}, {option.state_code}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        ) : (
          <div />
        ),
      )}
      {createField('duration', {
        form,
        defaultValue: 'all',
      })(
        <FormControl fullWidth>
          <InputLabel id='duration-label' htmlFor='Program Length'>
            Program Length
          </InputLabel>
          <Select
            label='Program Length'
            defaultValue='all'
            aria-label='Program Length'
            inputProps={{id: 'Program Length'}}
            onChange={e => setValue('duration', e.target.value)}>
            <MenuItem value={'all'} key={'all'}>
              All
            </MenuItem>
            {Array.isArray(durationOptions) &&
              durationOptions.map(option => (
                <MenuItem value={option.name} key={option.uuid}>
                  {option.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>,
      )}
    </Stack>
  );
};

FiltersForm.propTypes = {
  onFinish: PropTypes.func,
  filters: PropTypes.array,
  durationOptions: PropTypes.array,
  awardTypeOptions: PropTypes.array,
  connectedInstitutions: PropTypes.array,
};

export default FiltersForm;
