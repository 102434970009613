import {
  Grid,
  Button,
  CompanyCard,
  ErrorBoundary,
  RequestErrorLoader,
  SearchResultsCounter,
} from 'mui-core';
import {Box, Stack} from '@mui/material';
import ApprenticeshipSorter from '../ApprenticeshipSorter';
import {apprenticeshipsListData} from 'data/apprenticeshipsList.json';
import {LocationOnOutlined, QueryBuilderOutlined} from '@mui/icons-material';

const ApprenticeshipsList = ({
  reqParams,
  onLoadMore,
  onSortChange,
  apprenticeshipsData,
}) => {
  const {loadMoreBtnText} = apprenticeshipsListData;
  const {data: apprenticeshipsList, error, request} = apprenticeshipsData || {};
  const {data = [], page_details = {}} = apprenticeshipsList || {};
  const recordsCount = data?.length || 0;
  const totalCount = page_details?.total_results || 0;
  const hideLoadMore =
    (data?.page_details &&
      reqParams?.page >= data?.page_details?.page_size &&
      !request &&
      !error) ||
    recordsCount === totalCount ||
    totalCount <= 12;

  let defaultDisplay = true;
  if (request && !(reqParams?.page > 1)) {
    defaultDisplay = false;
  }

  return (
    <Box pb={5}>
      <ErrorBoundary nameOfComponent='module-companies' typeOfUi='subPage'>
        <Stack
          mb={1}
          direction='row'
          alignItems='flex-end'
          justifyContent='space-between'>
          <SearchResultsCounter
            request={request}
            totalResults={totalCount}
            currentLength={recordsCount}
          />
          {data?.length > 0 && (
            <ApprenticeshipSorter {...reqParams} onChange={onSortChange} />
          )}
        </Stack>
        <RequestErrorLoader
          minHeight={300}
          body={{...apprenticeshipsList, data: data || [], request}}>
          {defaultDisplay && data?.length > 0 && (
            <>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{xs: 1, sm: 2}}
                justifyContent={{xs: 'center', sm: 'left'}}>
                {data?.map(
                  ({
                    job_id,
                    banner,
                    company,
                    location,
                    job_title,
                    company_logo,
                    program_length,
                    institution_id,
                    company_industry_sector,
                  }) => {
                    let path = `/opportunities/apprenticeships/${encodeURIComponent(
                      job_title,
                    )}/${encodeURIComponent(company)}`;
                    return (
                      <Grid
                        item
                        xs={12}
                        sm='auto'
                        key={institution_id}
                        justifyContent='center'
                        sx={{textAlign: 'center'}}>
                        <CompanyCard
                          width={360}
                          data={{
                            path: path,
                            title: job_title,
                            companyName: company,
                            companyBanner: banner,
                            companyLogo: company_logo,
                            industrySectorName: company_industry_sector,
                          }}
                          infoItems={[
                            program_length && {
                              Icon: QueryBuilderOutlined,
                              value: program_length,
                            },
                            location && {
                              Icon: LocationOnOutlined,
                              value: location,
                            },
                          ].filter(Boolean)}
                          key={job_id}
                          target='_self'
                          enableNavigation
                        />
                      </Grid>
                    );
                  },
                )}
              </Grid>
              {!hideLoadMore && (
                <Box
                  py={3}
                  display='flex'
                  alignContent='center'
                  justifyContent='center'>
                  <Button
                    size='large'
                    variant='outlined'
                    onClick={onLoadMore}
                    sx={{padding: '8px 24px'}}>
                    {request
                      ? loadMoreBtnText.loading
                      : loadMoreBtnText.loadMore}
                  </Button>
                </Box>
              )}
            </>
          )}
        </RequestErrorLoader>
      </ErrorBoundary>
    </Box>
  );
};

export default ApprenticeshipsList;
