import React, {useState, useEffect, useMemo} from 'react';
import {useSnackbar} from 'notistack';
import {Delete} from '@mui/icons-material';
import {styled} from '@mui/material/styles';
import {Box, IconButton} from '@mui/material';
import NoDataContainer from '../NoDataContainer';
import {useHistory, Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import RemoveCareerDialog from '../RemoveCareerDialog';
import {
  Button,
  BarChart,
  Accordion,
  ContentBox,
  ErrorBoundary,
  RequestErrorLoader,
} from 'mui-core';
import {
  getOccupationInterest,
  fetchOccupationInterests,
  deleteOccupationInterest,
} from 'redux/modules/profile';
import {career} from 'data/dashboard.json';
import {getInstanceVersion} from 'redux/modules/instance';

const {
  InterestRating,
  careerTasksSurvey,
  exploreCareersBtnTxt,
  careerUnsubscribeData,
} = career || {};
const {successMsg} = careerUnsubscribeData;
const {
  removeInterestRating: {title, description},
  noResults: {title: noDataTitle, description: noDataDesc},
} = InterestRating;
const {accordionDesc, accordionHeader} = careerTasksSurvey;

const sortInterest = interestData =>
  interestData &&
  Array.isArray(interestData) &&
  interestData?.length > 0 &&
  interestData?.sort((a, b) => b.score - a.score);

const ExploreBtn = styled(Box)(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  '& .exploreBtn:hover': {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
  },
}));

const CareerTaskSurvey = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const [baruuId, setBaruuId] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const occupationInterest = useSelector(getOccupationInterest) || {};
  const [removeInterestRating, setRemoveInterestRating] = useState(false);
  const {data: interestData = [], request, deleteRequest} =
    occupationInterest || {};
  const data = useMemo(() => sortInterest(interestData), [interestData]) || [];
  const {dashboard: {my_careers: {career_task_survey = false} = {}} = {}} =
    useSelector(getInstanceVersion) || {};

  const onLabelClick = label => {
    if (label) {
      history.push(`/careers/${encodeURIComponent(label)}`);
    }
  };
  const onDeleteClick = (idx, lbl) => {
    setSelectedValue(lbl, idx);
    setRemoveInterestRating(true);
    if (idx !== null && data[idx] && data[idx].uuid) {
      setBaruuId(data[idx].uuid);
    }
  };

  const barDialogClose = () => {
    setRemoveInterestRating(false);
  };

  const DeleteOccupationInterest = () => {
    if (baruuId) {
      dispatch(
        deleteOccupationInterest(baruuId, res => {
          if (res === 'success') {
            enqueueSnackbar(successMsg, {
              variant: 'success',
              anchorOrigin: {horizontal: 'center', vertical: 'top'},
            });
            setRemoveInterestRating(false);
          }
          dispatch(fetchOccupationInterests());
        }),
      );
    }
  };

  useEffect(() => {
    dispatch(fetchOccupationInterests());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ContentBox elevation={0}>
      <Accordion
        defaultOpen
        outlined={false}
        header={accordionHeader}
        description={accordionDesc}>
        <ErrorBoundary
          typeOfUi='subPage'
          nameOfComponent='module-dashboard-career-careerTaskSurvey'>
          <RequestErrorLoader
            hideBackground
            body={{data, request: request || deleteRequest}}
            overrideNoDataContainer={
              <NoDataContainer
                noDataDesc={noDataDesc}
                noDataTitle={noDataTitle}
                featureHidden={career_task_survey}
              />
            }>
            <ExploreBtn>
              <Link to='/careers'>
                <Button variant='outlined' className='exploreBtn'>
                  {exploreCareersBtnTxt}
                </Button>
              </Link>
            </ExploreBtn>
            <BarChart
              data={data?.map(i => (i && i.score ? i.score : 0))}
              labels={data?.map(i =>
                i &&
                i.occupation_details &&
                i.occupation_details.occupation_name
                  ? i.occupation_details.occupation_name
                  : '',
              )}
              onLabelClick={onLabelClick}
              onActionClick={onDeleteClick}
              actionComponent={
                <IconButton aria-label='close'>
                  <Delete />
                </IconButton>
              }
            />
          </RequestErrorLoader>
        </ErrorBoundary>
        {removeInterestRating && (
          <RemoveCareerDialog
            dialogTitle={title}
            dialogDesc={description}
            dialogClose={barDialogClose}
            showDialog={removeInterestRating}
            selectedValueIndex={selectedValue.idx}
            selectedValueLabel={selectedValue.lbl}
            dialogConfirm={DeleteOccupationInterest}
          />
        )}
      </Accordion>
    </ContentBox>
  );
};

export default CareerTaskSurvey;
