import moment from 'moment';
import {nlx_logo} from 'assets/images';
import {Card, LazyImage, themeColorMode} from 'mui-core';
import {Box, Stack, Typography, alpha, styled} from '@mui/material';

const CardLayout = styled(Card)(({theme, selected}) => ({
  margin: '8px 0px',
  padding: '28px 24px',
  backgroundColor: themeColorMode(
    theme,
    theme.palette.background.paper,
    '#2a2a2a',
  ),
  boxShadow:
    '0px 0px 0px 0px rgba(0, 0, 0, 0.06), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)',
  '&:hover': {
    transform: 'none',
    boxShadow: `0px 0px 0px 1px ${themeColorMode(
      theme,
      alpha(theme.palette.primary.main, 0.7),
      '#fff',
    )}`,
    transition: '0.1s all',
    cursor: 'pointer',
    '& ._title': {
      textDecoration: 'underline',
    },
  },
  ...(selected
    ? {
        boxShadow: `0px 0px 0px 2px ${themeColorMode(
          theme,
          alpha(theme.palette.primary.main, 0.7),
          '#fff',
        )}`,
      }
    : {}),
}));

const JobCard = ({data, key, onClickCard, jobSelected}) => {
  const {
    job_id = '',
    company = '',
    location = '',
    job_title = '',
    job_source = '',
    date_posted = '',
    company_logo = '',
  } = data || {};

  const getTimeDiff = () => {
    try {
      let timeDiff = moment().diff(moment(date_posted), 'day');
      if (timeDiff === 0) return 'Today';
      return `${timeDiff > 30 ? '30+ days ago' : `${timeDiff} days ago`}`;
    } catch (e) {
      return '';
    }
  };

  return (
    <CardLayout
      key={key}
      onClick={() => onClickCard(data)}
      selected={jobSelected?.job_id === job_id}>
      <Stack direction={'row'} alignItems={'center'} spacing={2.5}>
        {/* <img style={{backgroundColor: '#f0f0f0'}} width={40} height={40} /> */}
        <Box
          sx={{
            width: 40,
            height: 40,
            ['& img']: {
              width: 40,
              height: 40,
              borderRadius: 1,
              objectFit: 'contain',
            },
          }}>
          <LazyImage
            type='bp'
            renderSrcSet
            imageType={'logo'}
            src={company_logo}
            alt='company-banner'
            defaultImage={job_source === 'cos' ? nlx_logo : null}
          />
        </Box>

        <Box>
          <Typography
            variant='h6'
            fontWeight={500}
            className='_title'
            color={'text.primary'}>
            {job_title}
          </Typography>
          <Typography
            mt={0.5}
            variant='h7'
            fontWeight={500}
            color={'text.primary'}>
            {company}
          </Typography>
          <Typography variant='body2'>{location}</Typography>
          <Typography variant='body2'>Posted: {getTimeDiff()}</Typography>
        </Box>
      </Stack>
    </CardLayout>
  );
};

export default JobCard;
