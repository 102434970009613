import {combineReducers} from 'redux';

import OccupationSearch from './occupationSearch';
import OccupationDetails from './occupationDetails';
import OccupationRoles from './occupationRoles';
import JobOpportunities from './jobOpportunities';
import JobZoneDetails from './jobZoneDetails';
import MyOpportunities from './myOpportunities';
import SubscribeOccupation from './subscribeOccupation';
import OpportunityInterest from './opportunityInterest';
import SavedOpportunity from './savedOpportunities';
import AppliedOpportunity from './appliedOpportunities';
import NewOpportunity from './newOpportunities';
import LocationSearch from './locationSearch';

export const occupationReducer = combineReducers({
  OccupationSearch,
  OccupationDetails,
  OccupationRoles,
  JobOpportunities,
  JobZoneDetails,
  SubscribeOccupation,
  OpportunityInterest,
  SavedOpportunity,
  AppliedOpportunity,
  NewOpportunity,
  LocationSearch,
  MyOpportunities,
});
