// PATH OCCUPATION SEARCH
export const OCCUPATION_SEARCH_REQUEST = 'OCCUPATION_SEARCH_REQUEST';
export const OCCUPATION_SEARCH_SUCCESS = 'OCCUPATION_SEARCH_SUCCESS';
export const OCCUPATION_SEARCH_ERROR = 'OCCUPATION_SEARCH_ERROR';

// PATH OCCUPATION DETAILS
export const OCCUPATION_DETAILS_REQUEST = 'OCCUPATION_DETAILS_REQUEST';
export const OCCUPATION_DETAILS_SUCCESS = 'OCCUPATION_DETAILS_SUCCESS';
export const OCCUPATION_DETAILS_ERROR = 'OCCUPATION_DETAILS_ERROR';
export const CLEAR_OCCUPATION_DETAILS = 'CLEAR_OCCUPATION_DETAILS';

// JOB OPPORTUNITIES
export const JOB_OPPORUNITIES_REQUEST = 'JOB_OPPORUNITIES_REQUEST';
export const JOB_OPPORUNITIES_SUCCESS = 'JOB_OPPORUNITIES_SUCCESS';
export const JOB_OPPORUNITIES_ERROR = 'JOB_OPPORUNITIES_ERROR';
export const CLEAR_JOB_OPPORUNITIES = 'CLEAR_JOB_OPPORUNITIES';

// JOB ZONE DETAILS
export const JOBZONE_DETAILS_REQUEST = 'JOBZONE_DETAILS_REQUEST';
export const JOBZONE_DETAILS_SUCCESS = 'JOBZONE_DETAILS_SUCCESS';
export const JOBZONE_DETAILS_ERROR = 'JOBZONE_DETAILS_ERROR';

// OCCUPATION ROLES
export const OCCUPATION_ROLE_REQUEST = 'OCCUPATION_ROLE_REQUEST';
export const OCCUPATION_ROLE_SUCCESS = 'OCCUPATION_ROLE_SUCCESS';
export const OCCUPATION_ROLE_ERROR = 'OCCUPATION_ROLE_ERROR';

// OCCUPATION RATE SAVE
export const OCCUPATION_RATE_SAVE_REQUEST = 'OCCUPATION_RATE_SAVE_REQUEST';
export const OCCUPATION_RATE_SAVE_SUCCESS = 'OCCUPATION_RATE_SAVE_SUCCESS';
export const OCCUPATION_RATE_SAVE_ERROR = 'OCCUPATION_RATE_SAVE_ERROR';

// OCCUPATION SUBSCRIBE
export const OCCUPATION_SUBSCRIBE_GET_REQUEST =
  'OCCUPATION_SUBSCRIBE_GET_REQUEST';
export const OCCUPATION_SUBSCRIBE_GET_SUCCESS =
  'OCCUPATION_SUBSCRIBE_GET_SUCCESS';
export const OCCUPATION_SUBSCRIBE_GET_ERROR = 'OCCUPATION_SUBSCRIBE_GET_ERROR';
export const OCCUPATION_SUBSCRIBE_REQUEST = 'OCCUPATION_SUBSCRIBE_REQUEST';
export const OCCUPATION_SUBSCRIBE_SUCCESS = 'OCCUPATION_SUBSCRIBE_SUCCESS';
export const OCCUPATION_SUBSCRIBE_ERROR = 'OCCUPATION_SUBSCRIBE_ERROR';
export const OCCUPATION_SUBSCRIBE_RESET = 'OCCUPATION_SUBSCRIBE_RESET';

// OPPORTUNITY INTEREST (SAVE/APPLY)
export const OPPORTUNITY_INTEREST_REQUEST = 'OPPORTUNITY_INTEREST_REQUEST';
export const OPPORTUNITY_INTEREST_SUCCESS = 'OPPORTUNITY_INTEREST_SUCCESS';
export const OPPORTUNITY_INTEREST_ERROR = 'OPPORTUNITY_INTEREST_ERROR';
export const CLEAR_OPPORTUNITY_INTEREST = 'CLEAR_OPPORTUNITY_INTEREST';

// UNSAVE OPPORTUNITY
export const OPPORTUNITY_UNSAVE_REQUEST = 'OPPORTUNITY_UNSAVE_REQUEST';
export const OPPORTUNITY_UNSAVE_SUCCESS = 'OPPORTUNITY_UNSAVE_SUCCESS';
export const OPPORTUNITY_UNSAVE_ERROR = 'OPPORTUNITY_UNSAVE_ERROR';

// SAVED OPPORTUNITIES
export const OPPORTUNITY_SAVED_REQUEST = 'OPPORTUNITY_SAVED_REQUEST';
export const OPPORTUNITY_SAVED_SUCCESS = 'OPPORTUNITY_SAVED_SUCCESS';
export const OPPORTUNITY_SAVED_ERROR = 'OPPORTUNITY_SAVED_ERROR';
export const CLEAR_OPPORTUNITY_SAVED = 'CLEAR_OPPORTUNITY_SAVED';

// APPLIED OPPORTUNITIES
export const OPPORTUNITY_APPLIED_REQUEST = 'OPPORTUNITY_APPLIED_REQUEST';
export const OPPORTUNITY_APPLIED_SUCCESS = 'OPPORTUNITY_APPLIED_SUCCESS';
export const OPPORTUNITY_APPLIED_ERROR = 'OPPORTUNITY_APPLIED_ERROR';
export const CLEAR_OPPORTUNITY_APPLIED = 'CLEAR_OPPORTUNITY_APPLIED';

// NEW OPPORTUNITIES
export const NEW_OPPORTUNITIES_REQUEST = 'NEW_OPPORTUNITIES_REQUEST';
export const NEW_OPPORTUNITIES_RECEIVE = 'NEW_OPPORTUNITIES_RECEIVE';
export const NEW_OPPORTUNITIES_ERROR = 'NEW_OPPORTUNITIES_ERROR';
export const CLEAR_NEW_OPPROTUNITIES = 'CLEAR_NEW_OPPROTUNITIES';

//LOCATION SEARCH
export const LOCATION_SEARCH_REQUEST = 'LOCATION_SEARCH_REQUEST';
export const LOCATION_SEARCH_RECEIVE = 'LOCATION_SEARCH_RECEIVE';
export const LOCATION_SEARCH_ERROR = 'LOCATION_SEARCH_ERROR';

//MY OPPORTUNITIES
export const MY_OPPORTUNITY_REQUEST = 'MY_OPPORTUNITY_REQUEST';
export const MY_OPPORTUNITY_SUCCESS = 'MY_OPPORTUNITY_SUCCESS';
export const MY_OPPORTUNITY_ERROR = 'MY_OPPORTUNITY_ERROR';
export const CLEAR_MY_OPPORTUNITY = 'CLEAR_MY_OPPORTUNITY';
