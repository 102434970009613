import jobsStaticData from 'data/jobs.json';

import {
  Box,
  Grid,
  Radio,
  Stack,
  Divider,
  useTheme,
  RadioGroup,
  Typography,
  OutlinedInput,
  useMediaQuery,
  FormControlLabel,
} from '@mui/material';
import {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {createField} from 'mui-core/Form';
import {LocationSearch, FilterDrawer} from 'mui-core';

const {
  filterForm: {
    labels,
    jobTypes,
    placeholders,
    datePostedOptions,
    workArrangementOptions,
  },
} = jobsStaticData || {};

const FilterForm = ({
  filters,
  onFinish,
  nearbyMSA,
  isDrawerOpen,
  onDrawerClose,
  onFilterReset,
  initialFilters,
  hideCompanyFilter,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const form = useForm();
  const {reset, handleSubmit, setValue, getValues} = form;

  const onResetClick = () => {
    const resetObj = {
      company: '',
      work_arr: null,
      job_type: null,
      no_of_days: null,
      location_type: null,
      location: initialFilters?.location || '',
    };
    onFilterReset(resetObj);
    reset(resetObj);
  };

  useEffect(() => {
    if (filters?.location) {
      setValue('location', filters.location);
    }
  }, [filters, setValue]);

  const handleLocationChange = value => {
    setValue('location', value.location);
  };

  const onInputChange = value => {
    setValue('location', value.location);
  };

  const companyField = createField('company', {
    form,
    label: labels.company,
    defaultValue: getValues('company') || '',
  })(
    <OutlinedInput label={labels.company} placeholder={placeholders.company} />,
  );

  return (
    <FilterDrawer
      onClose={onDrawerClose}
      onReset={onResetClick}
      openFilter={isDrawerOpen}
      handleSubmit={handleSubmit(onFinish)}>
      <Stack spacing={3}>
        {createField('location', {
          form,
          defaultValue: getValues('location') || '',
        })(
          <LocationSearch
            calledFromForm
            LocationOptions={nearbyMSA}
            onInputChange={onInputChange}
            onLocationChange={handleLocationChange}
            filters={{location: getValues('location')}}
          />,
        )}
        {!hideCompanyFilter && companyField}
        <Box>
          <Typography mb={1} variant='h5' color='text.primary'>
            {labels.datePosted}
          </Typography>

          <Grid container>
            {createField('no_of_days', {
              form,
              defaultValue: getValues('no_of_days') || null,
            })(
              <RadioGroup row>
                {datePostedOptions.map((item, index) => (
                  // <Grid item xs={6} md={6} key={index}>
                  <FormControlLabel
                    key={index}
                    sx={{width: 'calc(50% - 5px)'}}
                    value={item.value}
                    label={item.label}
                    control={<Radio />}
                  />
                  // </Grid>
                ))}
              </RadioGroup>,
            )}
          </Grid>
        </Box>
        <Divider />

        <Box>
          <Typography mb={1} variant='h5' color='text.primary'>
            {labels.jobType}
          </Typography>

          <Grid container>
            {createField('job_type', {
              form,
              defaultValue: getValues('job_type') || null,
            })(
              <RadioGroup row>
                {jobTypes.map((item, index) => (
                  <FormControlLabel
                    key={index}
                    sx={{width: 'calc(50% - 5px)'}}
                    value={item.value}
                    label={item.label}
                    control={<Radio />}
                  />
                ))}
              </RadioGroup>,
            )}
          </Grid>
        </Box>
        <Divider />

        <Box>
          <Typography mb={1} variant='h5' color='text.primary'>
            {labels.workArrangement}
          </Typography>

          <Grid container>
            {createField('location_type', {
              form,
              defaultValue: getValues('location_type') || null,
            })(
              <RadioGroup row>
                {workArrangementOptions.map((item, index) => (
                  <FormControlLabel
                    key={index}
                    sx={{width: 'calc(50% - 5px)'}}
                    value={item.value}
                    label={item.label}
                    control={<Radio />}
                  />
                ))}
              </RadioGroup>,
            )}
          </Grid>
        </Box>
      </Stack>
    </FilterDrawer>
  );
};

export default FilterForm;
