import React, {useState} from 'react';
import {useSnackbar} from 'notistack';
import {useDispatch} from 'react-redux';
import {Box, Stack, Typography} from '@mui/material';
import {Button, Carousel, DialogBox, EventCard} from 'mui-core';
import {withdrawEvent, removeMyEvent} from 'redux/modules/events';
import {home as homeJson} from 'data/dashboard';

const {
  events: {
    withdrawErrorMsg,
    withdrawCancelBtn,
    withdrawConfirmBtn,
    withdrawDialogTitle,
    withdrawDialogContent,
  },
} = homeJson;

const EventList = ({events}) => {
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const [selectedValue, setSelectedValue] = useState(null);
  const [showWithdrawDialog, setShowWithdrawDialogStatus] = useState(false);

  const onWithdraw = data => {
    setShowWithdrawDialogStatus(true);
    setSelectedValue(data);
  };
  const withDrawDialogClose = () => {
    setShowWithdrawDialogStatus(false);
  };

  const displayEllipses = [
    {
      passData: true,
      title: 'Withdraw',
      action: onWithdraw,
    },
  ];

  const withDrawEventConfirm = () => {
    const {uuid: event_uuid = ''} = selectedValue || {};
    if (event_uuid) {
      try {
        withDrawDialogClose();
        dispatch(
          withdrawEvent(event_uuid, res => {
            if (!res?.Success) {
              enqueueSnackbar(res?.error || res?.Error || withdrawErrorMsg, {
                variant: 'error',
                anchorOrigin: {horizontal: 'center', vertical: 'top'},
              });
            } else {
              dispatch(removeMyEvent(event_uuid));
              enqueueSnackbar(res?.Success, {
                variant: 'success',
                anchorOrigin: {horizontal: 'center', vertical: 'top'},
              });
            }
          }),
        );
      } catch (error) {
        enqueueSnackbar(error.message || withdrawErrorMsg, {
          variant: 'error',
          anchorOrigin: {horizontal: 'center', vertical: 'top'},
        });
      }
    }
  };

  return (
    <>
      <Carousel
        slidesToShow={4}
        content={events.map(path => (
          <Box key={path?.uuid} sx={{padding: '8px 16px'}}>
            <EventCard
              data={path}
              enableNavigation
              key={path?.event_id}
              displayEllipses={displayEllipses}
            />
          </Box>
        ))}
      />

      {showWithdrawDialog && (
        <DialogBox
          openDialog={showWithdrawDialog}
          closeDialog={withDrawDialogClose}
          actions={
            <>
              <Button
                variant='outlined'
                onClick={withDrawDialogClose}
                sx={{minWidth: {xs: '80px', md: '168px'}}}>
                {withdrawCancelBtn}
              </Button>
              <Button
                variant='contained'
                onClick={withDrawEventConfirm}
                sx={{minWidth: {xs: '80px', md: '168px'}}}>
                {withdrawConfirmBtn}
              </Button>
            </>
          }>
          <Stack spacing={1}>
            <Typography textAlign='center' variant='h4' color='text.primary'>
              {withdrawDialogTitle}
            </Typography>
            <Typography variant='body1' textAlign='center' color='text.primary'>
              {withdrawDialogContent}
            </Typography>
          </Stack>
        </DialogBox>
      )}
    </>
  );
};
export default EventList;
