import {useEffect, useState} from 'react';
import {
  Box,
  Paper,
  Stack,
  styled,
  Pagination,
  Typography,
  paperClasses,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import JobCard from './components/JobCard';
import jobsStaticData from 'data/jobs.json';
import {useOpportunitiesData} from 'core/hooks';
import JobDetails from './components/JobDetails';
import {addMultiQueryParamsToURL, numberInUSFormat} from 'core/utils';
import {RequestErrorLoader, themeColorMode} from 'mui-core';
import {useLocation} from 'react-router-dom/cjs/react-router-dom.min';

const {
  jobList: {title: jobLayoutTitle},
} = jobsStaticData || {};

const Layout = styled(Stack)(({theme, variant}) => ({
  marginTop: 30,
  marginBottom: 30,
}));

const JobListLayout = styled(Box)(({theme, variant}) => ({
  position: 'relative',
  minHeight: 200,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
  [theme.breakpoints.up('sm')]: {
    width: '36%',
    minWidth: '36%',
  },
  [`&>.${paperClasses.root}`]: {
    minHeight: 500,
    backgroundColor: themeColorMode(
      theme,
      theme.palette.lightGrey.main,
      theme.palette.darkGray.main,
    ),
    borderRadius: '20px',
    padding: '16px 20px',
  },
}));
const JobDetailsLayout = styled(Box)(({theme, isMobileOpen}) => ({
  flexGrow: 1,
  [' .paper-c ']: {
    maxHeight: 'calc(100vh - 140px)',
    overflowY: 'auto',
  },
  [theme.breakpoints.down('sm')]: {
    ...(isMobileOpen
      ? {
          position: 'fixed',
          top: 56,
          left: 0,
          width: '100%',
          height: 'calc(100% - 56px)',
          backgroundColor: theme.palette.background.paper,
          zIndex: 444,
          margin: '0!important',
          padding: 0,
          ['&>div']: {
            position: 'relative',
            top: 0,
            ['& .paper-c']: {
              maxHeight: 'calc(100vh - 56px)',
            },
          },
        }
      : {
          display: 'none',
        }),
  },
}));

const getJobFromQueryParamsForInitialLoad = location => {
  const search = new URLSearchParams(location.search);
  const job_id = decodeURIComponent(search.get('jobId'));
  const job_title = decodeURIComponent(search.get('title'));
  const company = decodeURIComponent(search.get('company'));
  const job_source = decodeURIComponent(search.get('source'));
  if (job_source && job_title && company && job_id) {
    return {job_title, company, job_source, job_id};
  } else return false;
};

const JobLayout = ({pageOptions, onPageChange, reqParams}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const [init, setInit] = useState();
  const [jobDetailsOnMobile, setJobDetailsOnMobile] = useState(false);
  const {jobsData} = useOpportunitiesData();
  const [jobSelected, setSelectedJob] = useState();
  const {data: jobsPage = [], request: jobsRequest} = jobsData || {};
  const {data: jobs, page_details} = jobsPage || {};
  const {total_results} = page_details || {};
  const pages = Math.round(total_results / pageOptions.page_size);

  useEffect(() => {
    const initialJobFromQuery = getJobFromQueryParamsForInitialLoad(location);
    if (
      !init &&
      location?.search &&
      initialJobFromQuery?.job_title !== 'null'
    ) {
      onJobClick(initialJobFromQuery, true, true);
    }
  }, []);

  useEffect(() => {
    if (Array.isArray(jobs) && jobs.length > 0) {
      if ((init || !jobSelected) && jobs[0].job_type !== 'apprenticeship') {
        onJobClick(jobs[0]);
      }
      if (!init) setInit(true);
    }
  }, [jobs]); // eslint-disable-line react-hooks/exhaustive-deps

  const onJobClick = (job, skipParamsUpdate, openOnMobileView) => {
    if (openOnMobileView && isMobile) setJobDetailsOnMobile(true);
    setSelectedJob(job);
    if (!skipParamsUpdate)
      addMultiQueryParamsToURL([
        {key: 'jobId', value: encodeURIComponent(job?.job_id)},
        {key: 'title', value: encodeURIComponent(job?.job_title)},
        {key: 'company', value: encodeURIComponent(job?.company)},
        {key: 'source', value: encodeURIComponent(job?.job_source)},
      ]);
  };
  const onMobileDetailsClose = () => {
    setJobDetailsOnMobile(false);
  };
  const emptyJobs = init && jobs && Array.isArray(jobs) && jobs.length === 0;

  return (
    <Layout direction={{xs: 'column', sm: 'row'}} spacing={2}>
      {emptyJobs && (
        <Stack
          width={'100%'}
          minHeight={300}
          alignItems={'center'}
          justifyContent={'center'}>
          <RequestErrorLoader
            body={{data: [], request: jobsRequest}}
            emptyMsg='No Jobs Found'
          />
        </Stack>
      )}
      <>
        <JobListLayout display={emptyJobs ? 'none' : 'block'}>
          <RequestErrorLoader body={jobsData} fitToParent fullScreen />
          {Array.isArray(jobs) && jobs.length > 0 && (
            <Paper variant='elevation' elevation={0}>
              <Typography variant='h5' color={'text.primary'}>
                {jobLayoutTitle}
              </Typography>
              <Typography color={'GrayText'} my={1}>
                Showing {numberInUSFormat(total_results)} Results
              </Typography>
              <Box mt={2} position={'relative'} minHeight={200}>
                {jobs.map(job => (
                  <JobCard
                    data={job}
                    key={job.job_id}
                    jobSelected={jobSelected}
                    onClickCard={data => onJobClick(data, false, true)}
                  />
                ))}
              </Box>
            </Paper>
          )}
          {!jobsRequest && pages > 0 && (
            <Stack direction={'row'} justifyContent={'center'} my={2}>
              <Pagination
                count={pages}
                shape='rounded'
                variant='outlined'
                page={reqParams?.page || 1}
                onChange={(e, page) => onPageChange(page)}
              />
            </Stack>
          )}
        </JobListLayout>
        <JobDetailsLayout
          isMobileOpen={jobDetailsOnMobile}
          display={emptyJobs ? 'none' : 'block'}>
          <Box position={'sticky'} top={'120px'}>
            {jobSelected && (
              <JobDetails
                jobsRequest={jobsRequest}
                isMobile={isMobile}
                jobCardInfo={jobSelected}
                jobId={jobSelected.job_title || jobSelected.title}
                onMobileDetailsClose={onMobileDetailsClose}
              />
            )}
          </Box>
        </JobDetailsLayout>
      </>
    </Layout>
  );
};

export default JobLayout;
