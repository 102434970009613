import {ExpandMore} from '@mui/icons-material';
import {Accordion, BackgroundPaper, themeColorMode} from 'mui-core';
import {Box, Divider, Typography, styled, useTheme} from '@mui/material';

const CustomAccordion = styled(Accordion)(({theme}) => ({
  margin: '0px',
  width: '100%',
  padding: '0px',
  boxShadow: 'none',
  '& .MuiAccordionSummary-root': {
    paddingLeft: '0px',
  },
  '& .MuiAccordionSummary-content': {
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  '& .MuiAccordionDetails-root': {
    paddingTop: '0px',
    paddingLeft: '16px',
  },
}));

const Faq = ({data = []}) => {
  const theme = useTheme();
  return (
    <>
      {Array.isArray(data) && data?.length > 0 ? (
        <Box
          borderRadius={4}
          textAlign='center'
          p={{xs: 4, md: 6}}
          className='faq-wrapper'
          sx={{
            backgroundColor: themeColorMode(
              theme,
              theme.palette.lightGrey.main,
              theme.palette.dialogDarkBG.main,
            ),
          }}>
          <Typography mb={2} variant='title55' component='p'>
            Frequently Asked Questions
          </Typography>
          <BackgroundPaper
            pb={2}
            borderRadius={4}
            px={{xs: 4, md: 8}}
            pt={{xs: 2, md: 3}}
            sx={{maxHeight: 600, overflowY: 'scroll'}}
            background={theme.palette.grey.contrastText}>
            {data?.map((paragraph, index) => (
              <Box key={index}>
                <CustomAccordion
                  defaultOpen={true}
                  titleDivider={false}
                  expandIcon={<ExpandMore />}
                  sx={{background: 'transparent', px: 0}}
                  header={
                    <Typography
                      variant='h4'
                      color={themeColorMode(
                        theme,
                        theme.palette.shadeyBlack.main,
                        theme.palette.midGray.main,
                      )}>
                      {paragraph.question}
                    </Typography>
                  }>
                  <Typography
                    py={1}
                    component='p'
                    variant='bodyXL'
                    textAlign='left'
                    color={themeColorMode(
                      theme,
                      theme.palette.grey.main,
                      'white',
                    )}>
                    {paragraph.answer}
                  </Typography>
                </CustomAccordion>
                {index !== data.length - 1 && <Divider />}
              </Box>
            ))}
          </BackgroundPaper>
        </Box>
      ) : null}
    </>
  );
};

export default Faq;
