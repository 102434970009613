import React, {useEffect} from 'react';
import MyEvents from './MyEvents';
import {Stack} from '@mui/material';
import QuickLinks from './QuickLinks';
import Programs from './Programs/Programs';
import {useDispatch, useSelector} from 'react-redux';
import {getDashboardPrograms} from 'redux/modules/profile';
import {fetchActivePathData, fetchSavedPaths} from 'redux/modules/pathways';

const Home = () => {
  // hooks
  const dispatch = useDispatch();
  //effect
  useEffect(() => {
    dispatch(fetchSavedPaths());
    dispatch(fetchActivePathData());
  }, []);
  return (
    <Stack gap={2}>
      <QuickLinks />
      <Programs />
      <MyEvents />
    </Stack>
  );
};

export default Home;
