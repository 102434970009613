// hooks
import {useInstance, useOpportunitiesData} from 'core/hooks';
import {useDispatch, useSelector} from 'react-redux';
import {useState, useEffect, useCallback, useRef} from 'react';
// components
import {
  FilterForm,
  LearnMoreAboutModal,
  ApprenticeshipsList,
} from './components';
import {Box, Button, Divider, Container, Typography} from '@mui/material';
import {BackgroundPaper, SearchFilter, AppBreadcrumb} from 'mui-core';
// redux
import {
  getLinkedInDetails,
  fetchlinkedInDetails,
} from 'redux/modules/resume-builder';

// utils
import {useTheme} from '@mui/material/styles';
import StaticBanner from 'mui-core/StaticBanner';
import {apprenticeshipsBanner} from 'assets/images';
import {apprenticeshipsListData} from 'data/apprenticeshipsList.json';

const {
  bannerData: {bannerTitle, ribbonText, bannerDesc, bannerButtonText},
  breadcrumbData,
} = apprenticeshipsListData || {};

const Apprenticeships = ({
  title = '',
  company = '',
  subTitle = '',
  tabPage = false,
}) => {
  const {
    data: {
      nearby_msa = [],
      super_type_name = null,
      default_msa: {city = '', state_code = ''} = {},
    } = {},
  } = useInstance();
  const defaultLoc = super_type_name
    ? `${city ? city + ', ' : ''}${state_code}`
    : 'United States';
  const defaultParams = {
    page: 1,
    page_size: 16,
    sort: 'date_desc',
  };
  const theme = useTheme();
  const filterRef = useRef();
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({});
  const [showModal, setshowModal] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [reqParams, setReqParams] = useState(defaultParams);
  const [initialFilters, setInitialFilters] = useState(filters);
  const {
    apprenticeshipsData,
    clearApprenticeships,
    clearJobs,
    fetchAllApprenticeships,
  } = useOpportunitiesData();
  const linkedInData = useSelector(getLinkedInDetails);
  const {industry_name: industryList = []} = linkedInData;

  useEffect(() => {
    let updatedFilters = {
      ...filters,
      location: defaultLoc,
      company_name: company,
    };
    setInitialFilters(updatedFilters);
    dispatch(fetchlinkedInDetails('industry_name', 'all'));
    refetchData(reqParams, updatedFilters);
    return () => {
      clearApprenticeships();
      clearJobs();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const apiCall = (Obj, filters = null, loadMore = false) => {
    fetchAllApprenticeships(Obj, filters, loadMore);
  };

  const refetchData = (Obj, newfFilters, loadMore) => {
    setReqParams(Obj);
    const updatedFilters = {...filters, ...newfFilters};
    setFilters(updatedFilters);
    apiCall(Obj, updatedFilters, loadMore);
  };

  const onSortChange = (key, value) => {
    const Obj = Object.assign({}, reqParams, {[key]: value, page: 1});
    refetchData(Obj, filters);
  };

  const onLoadMore = () => {
    const newPage = reqParams.page + 1;
    const Obj = Object.assign({}, reqParams, {
      page: newPage,
    });
    refetchData(Obj, filters, true);
  };

  const onFilterReset = resetObj => {
    const Obj = {...defaultParams};
    refetchData(Obj, {...resetObj});
    onDrawerClose();
  };

  const onFinish = useCallback(
    values => {
      let filterApply = false;
      if (values) {
        const filterKeys = Object.keys(values);
        filterKeys.forEach(key => {
          if (values[key] || values[key] === '') {
            filterApply = true;
          }
          if (key === 'no_of_days') {
            values[key] = parseInt(values[key]);
            if (values[key] === 0) {
              values[key] = null;
            }
            filterApply = true;
          }
        });
        if (filterApply) {
          const Obj = Object.assign({}, reqParams, {
            page: 1,
            sort: 'date_desc',
          });
          refetchData(Obj, values);
          onDrawerClose();
        }
      }
    },
    [reqParams, filters], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const modalHandler = value => {
    setshowModal(value);
  };

  const onDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const onDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  return (
    <BackgroundPaper>
      {!tabPage ? (
        <>
          <Container maxWidth='xl'>
            <AppBreadcrumb dataList={breadcrumbData} />
          </Container>
          <StaticBanner
            desc={bannerDesc}
            title={bannerTitle}
            ribbonTxt={ribbonText}
            url={apprenticeshipsBanner}>
            <Button
              p={1}
              variant='contained'
              sx={{width: 'fit-content'}}
              onClick={() => setshowModal(true)}>
              {bannerButtonText}
            </Button>
          </StaticBanner>
        </>
      ) : (
        <Container maxWidth='xl'>
          <Typography variant='h3' color='text.primary'>
            {title}
          </Typography>
          <Typography variant='bodyxlarge'>{subTitle}</Typography>
          <Divider sx={{mt: 2}} />
        </Container>
      )}
      <LearnMoreAboutModal modalHandler={modalHandler} showModal={showModal} />
      <SearchFilter
        name='title'
        filters={filters}
        onReset={onFilterReset}
        onDrawerOpen={onDrawerOpen}
        onSearch={value => onFinish(value)}
        searchPlaceholder='Search by apprenticeship title or other keywords'
      />
      <FilterForm
        filters={filters}
        hideCompanyFilter={tabPage}
        initialFilters={initialFilters}
        onFinish={onFinish}
        nearbyMSA={nearby_msa?.map((l, idx) => {
          return {
            ...l,
            id: idx + 1,
            nearby_msa: 'Nearby Locations',
          };
        })}
        isDrawerOpen={isDrawerOpen}
        industryList={industryList}
        onDrawerClose={onDrawerClose}
        onFilterReset={onFilterReset}
      />
      <Container sx={{pt: 0}} maxWidth='xl'>
        <ApprenticeshipsList
          tabPage={tabPage}
          reqParams={reqParams}
          onLoadMore={onLoadMore}
          onSortChange={onSortChange}
          apprenticeshipsData={apprenticeshipsData}
        />
      </Container>
    </BackgroundPaper>
  );
};

export default Apprenticeships;
