import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {styled} from '@mui/material/styles';
import {useHistory} from 'react-router-dom';
import {MoreHoriz} from '@mui/icons-material';
import {defaultBannerImage} from 'assets/images';
import {Card, Menu, LazyImage, ErrorBoundary} from 'mui-core';
import {
  Box,
  Stack,
  IconButton,
  Typography,
  CardActionArea,
} from '@mui/material';

const EllipsesIcon = styled(IconButton)(({theme}) => ({
  zIndex: 1,
  top: '0px',
  right: '12px',
  float: 'right',
  padding: '2px',
  marginTop: '12px',
  marginRight: '10px',
  position: 'absolute',
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.background.paper,
  '&:hover': {
    cursor: 'pointer',
    color: theme.palette.primary.main,
    backgroundColor: `${theme.palette.background.paper} !important`,
  },
}));

const TitleStyle = styled(Box)(({theme}) => ({
  width: '90%',
  height: '48px',
  overflow: 'hidden',
  margin: '24px 0px',
  WebkitLineClamp: '2',
  display: '-webkit-box',
  textOverflow: 'ellipsis',
  WebkitBoxOrient: 'vertical',
}));

const TagContainer = styled(Stack)(({}) => ({
  top: 24,
  left: 0,
  zIndex: 1,
  position: 'absolute',
}));

const tagTypeColor = (theme, tagType) => {
  switch (tagType) {
    case 'in_demand': {
      return {background: `${theme.palette.success.dark}`, color: 'white'};
    }
    default:
      return {};
  }
};
const Tag = styled(Box)(({theme, type}) => ({
  padding: '4px 24px',
  borderRadius: '0 12px 12px 0',
  ...tagTypeColor(theme, type),
}));

const CareerCard = ({
  data,
  width,
  config,
  target = '_blank',
  displayEllipses,
  enableNavigation,
  imageHeight = 150,
}) => {
  const {
    uuid,
    title,
    video_code,
    video_details,
    occupation_title,
    banner_cloudinary,
    in_demand_tag = false,
    occupation_details: {occupation_name} = {},
  } = data || {};

  const {type} = config || {};
  const history = useHistory();
  const cardWidth = width ? {width} : {};
  const [anchorEl, setAnchorEl] = useState(null);
  const occupationName = title || occupation_title || occupation_name;
  const open = Boolean(anchorEl);
  const {
    location: {pathname},
  } = history;

  const handleClose = event => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleClick = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const onNavigation = () => {
    let url = '';
    if (type && occupationName) {
      if (type === 'occupation') {
        url = `/careers/${encodeURIComponent(occupationName)}`;
      }

      if (type === 'cluster') {
        url = `/career-survey/cluster-area/${encodeURIComponent(
          occupationName,
        )}`;
      }
      target === '_self' || pathname === '/' || pathname.includes('/dashboard')
        ? history.push(url)
        : window.open(url, '_blank').focus();
    }
  };

  const getMenu = () => {
    let menu = [];
    let dataPassed = {};
    if (displayEllipses && displayEllipses.length > 0) {
      displayEllipses.map(item => {
        if ('passingData' in item) {
          item.passingData.map(param => {
            dataPassed[param] = eval(param);
          });
          item['dataPassed'] = dataPassed || data;
        }
      });
      menu = displayEllipses;
    }
    return (
      <Menu data={menu} open={open} anchorEl={anchorEl} onClose={handleClose} />
    );
  };

  let imgURL = '';
  if (video_code) {
    imgURL = `https://cdn.careeronestop.org/OccVids/OccupationVideos/${video_code}.jpg`;
  }
  if (video_details?.[0]?.VideoCode) {
    imgURL = `https://cdn.careeronestop.org/OccVids/OccupationVideos/${video_details?.[0]?.VideoCode}.jpg`;
  }

  return (
    <ErrorBoundary nameOfComponent='explore-career-card'>
      <Card
        sx={{
          ...cardWidth,
          cursor: 'pointer',
          position: 'relative',
          margin: '0px auto 16px',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
        }}
        component={CardActionArea}
        onClick={() => enableNavigation && onNavigation()}>
        <TagContainer spacing={1}>
          {in_demand_tag && (
            <Tag alignItems='center' type='in_demand'>
              <Typography variant='body2'>In-Demand</Typography>
            </Tag>
          )}
        </TagContainer>
        {displayEllipses && (
          <>
            <EllipsesIcon
              size='small'
              id='basic-button'
              component='label'
              aria-haspopup='true'
              onClick={handleClick}
              aria-expanded={open ? 'true' : undefined}
              aria-controls={open ? 'basic-menu' : undefined}>
              <MoreHoriz />
            </EllipsesIcon>
            {getMenu()}
          </>
        )}
        <Box height={imageHeight}>
          {/* Default Banner Image */}
          {!banner_cloudinary && !imgURL && (
            <LazyImage
              src={defaultBannerImage}
              alt={`${occupationName?.slice(0, 50) || ''}-default-img`}
            />
          )}
          {/* Video Coded Image */}
          {!banner_cloudinary && imgURL && (
            <LazyImage
              src={imgURL}
              alt={`${occupationName?.slice(0, 50) || ''}-occupation-img`}
            />
          )}
          {/* Banner Cloudinary Image */}
          {banner_cloudinary && !imgURL && (
            <LazyImage
              type={type}
              renderSrcSet
              src={banner_cloudinary}
              alt={`${occupationName?.slice(0, 50) || ''}-banner-image`}
            />
          )}
        </Box>
        <TitleStyle>
          {occupationName && (
            <Typography
              mx={2}
              variant='h6'
              lineHeight={1.4}
              color='text.primary'
              title={`${occupationName || ''} `}>
              {occupationName}
            </Typography>
          )}
        </TitleStyle>
      </Card>
    </ErrorBoundary>
  );
};

CareerCard.propTypes = {
  width: PropTypes.number,
  config: PropTypes.object,
  enableNavigation: PropTypes.bool,
  data: PropTypes.object.isRequired,
  displayEllipses: PropTypes.bool || PropTypes.array,
};

export default CareerCard;
