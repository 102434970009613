import * as TYPES from './constants';
import {API} from 'config';
import {
  requestBusinessPartners,
  recieveBusinessPartners,
  requestBusinessPartnersError,
  clearBusinessPartners,
  recieveAllBusinessPartners,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false, error: false, data: null};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_BUSINESS_PARTNERS:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.RECIEVE_ALL_BUSINESS_PARTNERS:
      let data = action.payload;
      if (state.data && state.data.bp_details) {
        data.bp_details = [...state.data.bp_details, ...data.bp_details];
      }
      return {
        ...state,
        request: false,
        error: false,
        data,
      };
    case TYPES.RECIEVE_BUSINESS_PARTNERS:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.REQUEST_BUSINESS_PARTNERS_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    case TYPES.CLEAR_BUSINESS_PARTNERS:
      return initialState;
    default:
      return state;
  }
};

export const fetchBusinessPartners = (reqParams, filters, loadmore = false) => (
  dispatch,
  getState,
) => {
  const {sort = 'datedesc', page = 1} = reqParams || {};
  const pageSize = 16;
  let endPoint = `${API.srm.business_partners}?page_size=${pageSize}&page=${page}&sort=${sort}`;
  dispatch(requestBusinessPartners());
  if (filters) {
    const getFilterKeys = Object.keys(filters);
    if (getFilterKeys.length) {
      getFilterKeys.map(key => {
        if (filters[key]) {
          endPoint += `&${key}=${filters[key]}`;
        }
      });
    }
  }
  try {
    return services.fetchRecords(endPoint, null, true).then(res => {
      if (res?.bp_details) {
        if (loadmore) {
          dispatch(recieveAllBusinessPartners(res)); // Concatinate prev result to current result on loadmore
        } else {
          dispatch(recieveBusinessPartners(res));
        }
        return;
      }
      dispatch(requestBusinessPartnersError(res));
    });
  } catch (err) {
    console.log(err);
    dispatch(requestBusinessPartnersError(err));
  }
};

export const resetBusinessPartners = () => dispatch => {
  dispatch(clearBusinessPartners());
};
