import React, {useState, useEffect, Fragment} from 'react';
// Libraries
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
// hooks
import {useInstance} from 'core/hooks';
// redux
import {useDispatch} from 'react-redux';
import {fetchOccupationDetails} from 'redux/modules/occupation';
// Components
import {
  SkillsStack,
  CareerTrends,
  ErrorBoundary,
  themeColorMode,
  BackgroundPaper,
} from 'mui-core';
import {Box, Grid, Stack, Typography} from '@mui/material';
import {alpha, useTheme, styled} from '@mui/material/styles';
// Utils
import {hotTechnology, onetinit} from 'assets/images';
import {careerOverviewData} from 'data/occupation.json';
import {exploreCareer} from 'data/exploreCareer.json';

const StyledCareerBlock = styled(Stack)(({theme}) => ({
  width: '100%',
  overflow: 'auto',
  position: 'relative',
  paddingRight: '10px',
  textTransform: 'capitalize',
  '& ul > li': {
    lineHeight: 2,
    paddingBottom: '8px',
    background: 'transparent',
    color:
      theme.palette.mode === 'dark' ? 'white' : theme.palette.darkGray.main,
    listStyleType: 'none',
    textAlign: 'left',
    marginLeft: '-16px',
    '&::before': {
      content: '"\\2022"',
      position: 'absolute',
      marginTop: '-16px',
      left: '0px',
      color: '#3ac4ff',
      fontSize: '30px',
    },
  },
}));
// Custom Component for Background paper
const RenderBackgroundPaper = ({children, title}) => {
  const theme = useTheme();
  return (
    <Grid item lg={4} md={6} sm={12} width='100%'>
      <BackgroundPaper
        p={2}
        borderRadius={4}
        background={{
          dark: theme.palette.background.paper,
          light: theme.palette.purplebg8perc.main,
        }}
        border={`1px solid ${themeColorMode(theme, '#f0edfc', '#601AAD')}`}>
        <Stack spacing={2} alignItems='center'>
          {title && (
            <Typography variant='h3' pb={1} color='text.primary'>
              {title}
            </Typography>
          )}
          {children}
        </Stack>
      </BackgroundPaper>
    </Grid>
  );
};

const CareerOverview = ({location, setLocation, careerDetails: {data}}) => {
  // hooks
  const dispatch = useDispatch();
  // states
  const [renderVideo, setRenderVideo] = useState(false);
  const {defaultLocation} = exploreCareer;
  const {
    data: {default_msa: {city = '', state_code = '', msa_code = ''} = {}} = {},
  } = useInstance();

  useEffect(() => {
    setRenderVideo(false);
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  // utils
  const {
    occupation_name,
    occupation_details: {
      skills,
      abilities,
      knowledge,
      technology,
      on_the_job,
      work_values,
      work_styles,
      what_they_do,
      video_details,
      education_usually_needed,
      personality: {description, element} = {},
    } = {},
  } = data || {};
  const {VideoCode = ''} =
    video_details && video_details[0] ? video_details[0] : {};

  // MSA location for CareerTrends
  const onLocationChange = location => {
    let updatedParams = {query: occupation_name};
    if (typeof location === 'object') {
      updatedParams = {
        ...updatedParams,
        msa_code: location.msa_code,
        location: location.location,
      };
    } else {
      updatedParams = {...updatedParams, location: location};
    }
    if (setLocation) {
      setLocation(updatedParams.location);
    }
    dispatch(fetchOccupationDetails(updatedParams));
  };
  const resetLocation = () => {
    const defLoc = city ? `${city}, ${state_code}` : defaultLocation;
    let updatedParams = {query: occupation_name};
    updatedParams = {
      ...updatedParams,
      msa_code: msa_code ?? '',
      location: defLoc,
    };

    if (setLocation) {
      setLocation(updatedParams.location);
    }
    dispatch(fetchOccupationDetails(updatedParams));
  };

  return (
    <ErrorBoundary nameOfComponent='career-details-overview'>
      <Stack spacing={2} direction={{sm: 'column', md: 'row'}}>
        <Stack spacing={2} width={{sm: '100%', md: '50%'}}>
          {!renderVideo && (
            <RenderBackgroundPaper title='Career Video'>
              <ReactPlayer
                controls
                width='100%'
                height='fit-content'
                onError={() => setRenderVideo(true)}
                url={`https://cdn.careeronestop.org/OccVids/OccupationVideos/${VideoCode}.mp4`}
              />
            </RenderBackgroundPaper>
          )}
          {what_they_do && (
            <RenderBackgroundPaper title='What You Will Do'>
              <Box maxHeight={480} overflow='auto'>
                <Typography variant='body1' color='text.primary'>
                  {what_they_do}
                </Typography>
              </Box>
            </RenderBackgroundPaper>
          )}
        </Stack>
        <Stack spacing={2} width={{sm: '100%', md: '50%'}}>
          <Box mt={{xs: 2, md: 0}}>
            <CareerTrends
              data={data}
              reset={() => resetLocation()}
              location={location}
              onLocationChange={onLocationChange}
            />
          </Box>

          {education_usually_needed &&
            Array.isArray(education_usually_needed) &&
            education_usually_needed.length > 0 && (
              <RenderBackgroundPaper title='Education Needed'>
                <Box sx={{mt: '4px !important'}} height='54px' overflow='auto'>
                  <SkillsStack skillSet={education_usually_needed} />
                </Box>
              </RenderBackgroundPaper>
            )}

          {on_the_job && Array.isArray(on_the_job) && on_the_job.length > 0 && (
            <RenderBackgroundPaper title='On The Job'>
              <StyledCareerBlock height='250px'>
                <ul>
                  {on_the_job.map((point, idx) => {
                    return (
                      <Typography
                        key={idx}
                        variant='body1'
                        component={'li'}
                        color='text.primary'>
                        {point}
                      </Typography>
                    );
                  })}
                </ul>
              </StyledCareerBlock>
            </RenderBackgroundPaper>
          )}
        </Stack>
      </Stack>

      <Grid mt={0} container spacing={2} direction='row'>
        {knowledge && Array.isArray(knowledge) && knowledge.length > 0 && (
          <RenderBackgroundPaper title='Knowledge'>
            <StyledCareerBlock spacing={2} height='380px'>
              {knowledge.map(({title, element}, index) => (
                <>
                  <Typography variant='h5' color='text.primary'>
                    {title}
                  </Typography>
                  <ul>
                    {element.map((el, idx) => (
                      <Typography
                        variant='body1'
                        component={'li'}
                        key={`knowledge-element-${index}-${idx}`}>
                        {el}
                      </Typography>
                    ))}
                  </ul>
                </>
              ))}
            </StyledCareerBlock>
          </RenderBackgroundPaper>
        )}

        {skills && Array.isArray(skills) && skills.length > 0 && (
          <RenderBackgroundPaper title='Skills'>
            <StyledCareerBlock spacing={2} height='380px'>
              {skills.map(({title, element}, index) => (
                <>
                  <Typography variant='h5' color='text.primary'>
                    {title}
                  </Typography>
                  <ul>
                    {element.map((el, idx) => (
                      <Typography
                        variant='body1'
                        component={'li'}
                        key={`skill-element-${index}-${idx}`}>
                        {el}
                      </Typography>
                    ))}
                  </ul>
                </>
              ))}
            </StyledCareerBlock>
          </RenderBackgroundPaper>
        )}

        {abilities && Array.isArray(abilities) && abilities.length > 0 && (
          <RenderBackgroundPaper title='Abilities'>
            <StyledCareerBlock spacing={2} height='380px'>
              {abilities.map(({title, element}, index) => (
                <>
                  <Typography variant='h5' color='text.primary'>
                    {title}
                  </Typography>
                  <ul>
                    {element.map((el, idx) => (
                      <Typography
                        variant='body1'
                        component={'li'}
                        key={`knowledge-element-${index}-${idx}`}>
                        {el}
                      </Typography>
                    ))}
                  </ul>
                </>
              ))}
            </StyledCareerBlock>
          </RenderBackgroundPaper>
        )}

        {technology && Array.isArray(technology) && technology.length > 0 && (
          <RenderBackgroundPaper title='Technology'>
            <StyledCareerBlock spacing={2} height='380px'>
              {technology.map(({title, example}, index) => (
                <Fragment key={`techno-${index}`}>
                  <Typography variant='h5' color='text.primary'>
                    {title}
                  </Typography>
                  <ul>
                    {example.map(({name, hot_technology}, idx) => (
                      <Typography
                        variant='body1'
                        component={'li'}
                        key={`technology-${index}-${idx}`}>
                        {name}
                        {hot_technology > 0 && (
                          <img
                            src={hotTechnology}
                            style={{width: '20px'}}
                            alt='hotTechnology-logo'
                          />
                        )}
                      </Typography>
                    ))}
                  </ul>
                </Fragment>
              ))}
            </StyledCareerBlock>
          </RenderBackgroundPaper>
        )}

        {element && Array.isArray(element) && element.length > 0 && (
          <RenderBackgroundPaper title='Personality'>
            <StyledCareerBlock spacing={2} height='380px'>
              <Typography color='text.primary' variant='h5'>
                {description}
              </Typography>
              <Typography color='text.primary' variant='body1'>
                {careerOverviewData.theyDoWellTxt}:
              </Typography>
              <ul>
                {element.map((el, idx) => (
                  <Typography
                    variant='body1'
                    component={'li'}
                    key={`personality-${idx}`}>
                    {el}
                  </Typography>
                ))}
              </ul>
            </StyledCareerBlock>
          </RenderBackgroundPaper>
        )}

        {work_values && Array.isArray(work_values) && work_values.length > 0 && (
          <RenderBackgroundPaper title='Work values'>
            <StyledCareerBlock spacing={2} height='380px'>
              {work_values.map((res, index) => (
                <Fragment key={`workvalue-${index}`}>
                  <Typography variant='h5' color='text.primary'>
                    {res.name}
                  </Typography>
                  <ul>
                    <Typography
                      variant='body1'
                      component={'li'}
                      key={`workvalue-${index}-${index}`}>
                      {res.description}
                    </Typography>
                  </ul>
                </Fragment>
              ))}
            </StyledCareerBlock>
          </RenderBackgroundPaper>
        )}

        {work_styles && Array.isArray(work_styles) && work_styles.length > 0 && (
          <RenderBackgroundPaper title='Work Styles'>
            <StyledCareerBlock spacing={2} height='380px'>
              {work_styles.map((res, index) => (
                <Fragment key={`work-style-${index}`}>
                  <Typography variant='h5' color='text.primary'>
                    {res.name}
                  </Typography>
                  <ul>
                    <Typography
                      variant='body1'
                      component={'li'}
                      key={`work-style-${index}-${index}`}>
                      {res.description}
                    </Typography>
                  </ul>
                </Fragment>
              ))}
            </StyledCareerBlock>
          </RenderBackgroundPaper>
        )}

        <Grid item lg={8} md={6}>
          <Stack spacing={2} mt={2} direction='row'>
            <img src={onetinit} alt='onetinit-logo' />
            <Typography variant='body1' color='text.primary'>
              {careerOverviewData.siteInfo}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </ErrorBoundary>
  );
};

//validator
RenderBackgroundPaper.propTypes = {
  title: PropTypes.string,
  children: PropTypes.element,
};

export default CareerOverview;
