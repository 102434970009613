import {Button, DialogBox} from 'mui-core';
import PropTypes from 'prop-types';
import {Box, Typography} from '@mui/material';
import {exploreCareer} from 'data/exploreCareer.json';

const {yesTxt, cancelTxt} = exploreCareer;
const RemoveCareerDialog = ({
  showDialog,
  dialogDesc,
  dialogTitle,
  dialogClose,
  dialogConfirm,
  selectedValueLabel,
  selectedValueIndex,
}) => {
  return (
    <DialogBox
      openDialog={showDialog}
      closeDialog={dialogClose}
      dialogTitle={dialogTitle}
      actions={
        <>
          <Button
            variant='outlined'
            onClick={dialogClose}
            sx={{minWidth: {xs: '80px', md: '168px'}}}>
            {cancelTxt}
          </Button>
          <Button
            variant='contained'
            sx={{minWidth: {xs: '80px', md: '168px'}}}
            onClick={() => dialogConfirm(selectedValueIndex)}>
            {yesTxt}
          </Button>
        </>
      }>
      <Box textAlign='center'>
        <Typography variant='h4' color='text.primary'>
          {dialogDesc}
        </Typography>
        <Typography ml={0.5} variant='body1' color='#de4279'>
          {`${selectedValueLabel}?`}
        </Typography>
      </Box>
    </DialogBox>
  );
};

RemoveCareerDialog.prototype = {
  showDialog: PropTypes.bool.isRequired,
  dialogClose: PropTypes.func.isRequired,
  dialogDesc: PropTypes.string.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  dialogConfirm: PropTypes.func.isRequired,
  selectedValueLabel: PropTypes.string.isRequired,
  selectedValueIndex: PropTypes.string.isRequired,
};

export default RemoveCareerDialog;
