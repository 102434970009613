import React from 'react';
import {useSelector} from 'react-redux';
import {styled} from '@mui/material/styles';
import GoSurveyCard from 'mui-core/GoSurveyCard';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {getGoSurveyResultDetails} from 'redux/modules/survey';
import {Stack, Container, Typography, Box} from '@mui/material';
import {
  Button,
  NoResults,
  PageLoader,
  ErrorBoundary,
  themeColorMode,
  RequestErrorLoader,
} from 'mui-core';
import {goSurveyResults} from 'data/goSurvey.json';

const NextButton = styled(Button)(({theme, isModify}) => ({
  width: 160,
  color: 'white',
  padding: '4px 24px',
  borderRadius: '30px',
  background: isModify
    ? themeColorMode(theme, 'rgba(0, 0, 0, 0.87)', theme.palette.darkGray.main)
    : 'rgba(0, 0, 0, 0.87)',
  boxShadow:
    '0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
  ':hover': {
    background: theme.palette.shadeyBlack.main,
  },
}));

const SurveyResultPage = ({
  isDashboard,
  onModifySurvey,
  surveyResults = {},
}) => {
  const {request: goSurveyResultDetailsRequest} =
    useSelector(getGoSurveyResultDetails) || {};
  const resultsLength = surveyResults?.length || 0;
  const {
    btnText,
    pageTitle,
    pageDesc,
    noResultFoundTitle,
    noResultFoundSubTitle,
    noResultFoundBtnLabel,
  } = goSurveyResults;

  return (
    <ErrorBoundary nameOfComponent='module-career-list' typeOfUi='subPage'>
      <Container>
        <Stack>
          <Stack
            mt={isDashboard ? 0 : 4}
            mb={isDashboard ? 2 : 0}
            direction='row'
            alignItems='center'
            justifyContent={isDashboard ? 'flex-end' : 'space-between'}>
            {!isDashboard && (
              <Typography variant='h3' color='text.primary'>
                {pageTitle}
              </Typography>
            )}
            {resultsLength > 0 ? (
              isDashboard ? (
                <Button variant='outlined' onClick={onModifySurvey}>
                  {btnText}
                </Button>
              ) : (
                <NextButton
                  rounded
                  isModify
                  onClick={onModifySurvey}
                  startIcon={<ArrowBackIcon sx={{mb: '2px'}} />}>
                  {btnText}
                </NextButton>
              )
            ) : null}
          </Stack>

          {resultsLength > 0 ? (
            <>
              {!isDashboard && (
                <Stack gap={2} mt={2} mb={{xs: 2, md: 4}}>
                  <Typography variant='bodyXL'>
                    Here are the top {resultsLength} careers that align with
                    your preferences, ranked from highest matched to lowest.
                  </Typography>
                  <Typography variant='bodyXL'>{pageDesc}</Typography>
                </Stack>
              )}
              <Box
                sx={{
                  overflowX: 'hidden',
                  padding: {xs: 0, md: 2},
                  maxHeight: isDashboard ? 900 : 'auto',
                  overflowY: isDashboard ? 'auto' : 'visible',
                }}>
                {surveyResults.map((surveyResultsData, index) => (
                  <GoSurveyCard
                    key={index}
                    index={index}
                    data={surveyResultsData}
                  />
                ))}
              </Box>
            </>
          ) : (
            <Box py={2}>
              <NoResults
                title={noResultFoundTitle}
                onButtonClick={onModifySurvey}
                navTitle={noResultFoundBtnLabel}
                subTitle={noResultFoundSubTitle}
              />
            </Box>
          )}
        </Stack>
        <RequestErrorLoader
          hideEmpty
          fullScreen
          overridedLoader={
            <PageLoader isTransparent description='Loading...' />
          }
          body={{request: goSurveyResultDetailsRequest}}
        />
      </Container>
    </ErrorBoundary>
  );
};

export default SurveyResultPage;
