// hooks
import {useHistory} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {useCallback, useEffect, useMemo, useState} from 'react';
// redux
import {
  getPaths,
  clearPathSearch,
  fetchPathsSearchWithFilters,
} from 'redux/modules/search';
// components
import {
  Button,
  Layout,
  PathCard,
  ErrorBoundary,
  RequestErrorLoader,
  SearchResultsCounter,
} from 'mui-core';
import FiltersForm from './FiltersForm';
import ProgramsSorter from './ProgramsSorter';
import {Box, Grid, Typography, useMediaQuery, useTheme} from '@mui/material';
// utils
import PropTypes from 'prop-types';
import {licenseTypeKeys} from 'config';
import {queryStringParse} from 'core/utils';

const {LayoutHeader, LayoutFilter} = Layout;

const QualifyingProgramsList = ({
  title = '',
  category = '',
  searchKey = '',
  // fixedScrollHeight,
  careerAreaList = [],
  noResults = 'Sorry, we couldn’t find any programs that match your search right now.',
}) => {
  let hideLoadMore = true;
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const programs = useSelector(getPaths);
  const {data, pathsSearchRequest} = programs;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    location: {search},
  } = history;
  const {type: typeParam = 'all'} = queryStringParse(search); // defaults to all areas except when redirecting from home screen

  const ButtonGroupOptions = useMemo(
    () => [
      {
        key: category === 'gsearch' ? 'relevant' : 'date_desc',
        label: 'Relevancy',
      },
      {
        key: 'title_asc',
        label: 'A to Z',
      },
      {
        key: 'title_desc',
        label: 'Z to A',
      },
    ],
    [category],
  );

  const defaultFilters = useMemo(() => ({
    page: 1,
    type: typeParam,
    sort_by: ButtonGroupOptions[0].key,
  }));

  const [filters, setFilters] = useState(defaultFilters);

  const programsList = data?.path_details ? data?.path_details : [];
  const recordsCount = programsList?.length || 0;
  const programsTotalCount = data?.page_details?.total_count || 0;
  const durationOptions = data?.duration_list ? data?.duration_list : [];
  const connectedInstitutions = data?.connected_institutions
    ? data?.connected_institutions
    : [];
  const awardTypeOptions = data?.award_type_list ? data?.award_type_list : [];

  useEffect(() => {
    let newFilters = {...defaultFilters};
    if (category && searchKey) {
      newFilters[category] = searchKey;
    }
    dispatch(clearPathSearch());
    refetchData(newFilters);
    return () => {
      dispatch(clearPathSearch());
    };
  }, [searchKey]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadMorePrograms = () => {
    if (!hideLoadMore && !pathsSearchRequest) {
      const newPage = filters.page + 1;
      // const Obj = Object.assign({}, reqParams, {
      //   page: newPage,
      // });
      // setFilters({...filters, page: newPage});
      refetchData({...filters, page: newPage});
    }
  };

  const onFinish = values => {
    const newFilters = {...filters, ...values, page: 1};
    refetchData(newFilters);
  };

  const refetchData = useCallback(filtersObj => {
    setFilters(filtersObj);
    dispatch(fetchPathsSearchWithFilters(filtersObj));
  });

  const onSortChange = useCallback(value => {
    onFinish({...filters, sort_by: value, page: 1});
  });

  // key => {
  //   const Obj = Object.assign({}, reqParams, {
  //     page: 1,
  //     sort: key,
  //   });
  //   let newFilter = filters.map(filter =>
  //     filter.type === 'sort_by' ? {...filter, title: key} : filter,
  //   );
  //   setFilters(newFilter);
  //   setReqParams({...reqParams, ...Obj});
  //   refetchData(Obj, newFilter, 1);
  // },
  // [filters, refetchData],

  if (data) {
    hideLoadMore =
      data?.page_details && filters?.page >= data?.page_details?.no_of_pages;
  }
  const filterLength = (filters && Object.keys(filters).length) || 0;

  return (
    <ErrorBoundary
      nameOfComponent='module-qualifying-programs-list'
      typeOfUi='subPage'>
      {!programs?.pathsSearchRequest &&
      filters &&
      (category ? filterLength <= 4 : filterLength <= 3) &&
      !(
        programsList?.length &&
        durationOptions?.length > 0 &&
        awardTypeOptions?.length > 0
      ) ? (
        <Box
          py={5}
          display='flex'
          minHeight={500}
          textAlign='center'
          alignItems='center'
          justifyContent='center'>
          <Typography color='text.primary' variant='body1'>
            {noResults}
          </Typography>
        </Box>
      ) : (
        <>
          <LayoutHeader
            sx={{mt: 5}}
            title={title}
            rightContent={
              <ProgramsSorter
                {...filters}
                name='program'
                onSortChange={onSortChange}
                options={ButtonGroupOptions}
              />
            }
          />
          <LayoutFilter
            title='Filters'
            existFilter={filters}
            // fixedScrollHeight={fixedScrollHeight}
            filterContent={
              <FiltersForm
                filters={filters}
                onFinish={onFinish}
                careerAreaList={careerAreaList}
                durationOptions={durationOptions}
                awardTypeOptions={awardTypeOptions}
                connectedInstitutions={connectedInstitutions}
              />
            }>
            <Box
              p={2}
              minHeight={460}
              sx={
                !programs?.pathsSearchRequest && programsList?.length == 0
                  ? {
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }
                  : {}
              }>
              <RequestErrorLoader
                body={{
                  ...programs,
                  data: programsList,
                  request: pathsSearchRequest,
                }}
                emptyMsg={noResults}
                title='Loading programs...'
                minHeight={careerAreaList?.length ? 500 : 400}>
                {recordsCount > 0 && programsTotalCount > 0 && (
                  <>
                    <SearchResultsCounter
                      currentLength={recordsCount}
                      totalResults={programsTotalCount}
                      pathsSearchRequest={programs.pathsSearchRequest}
                    />
                    <Grid
                      container
                      columnSpacing={{xs: 2, sm: 2}}
                      rowSpacing={{xs: 2, sm: 2, md: 3, lg: 4}}
                      justifyContent={isMobile ? 'center' : 'left'}>
                      {programsList?.length > 0 &&
                        programsList.map(path => {
                          const isCriminalJustice =
                            path?.institute_details?.super_type_name ===
                            licenseTypeKeys?.criminalJustice;
                          return (
                            <Grid
                              item
                              xs={12}
                              sm='auto'
                              key={path?.id}
                              justifyContent='center'
                              sx={{
                                flexBasis: 'fit-content',
                                textAlign: {xs: 'center', sm: 'left'},
                              }}>
                              <PathCard
                                width={300}
                                data={path}
                                key={path?.id}
                                enableNavigation
                                hideLocation={isCriminalJustice}
                                hideAwardType={!path?.award_type_name}
                              />
                            </Grid>
                          );
                        })}
                    </Grid>
                    {!hideLoadMore && (
                      <Box
                        pt={4}
                        pb={3}
                        display='flex'
                        alignContent='center'
                        justifyContent='center'>
                        {(filters?.page === 1 ? !pathsSearchRequest : true) && (
                          <Button
                            size='large'
                            color='primary'
                            variant='outlined'
                            sx={{padding: '8px 54px'}}
                            onClick={loadMorePrograms}>
                            {pathsSearchRequest ? 'LOADING...' : 'LOAD MORE'}
                          </Button>
                        )}
                      </Box>
                    )}
                  </>
                )}
              </RequestErrorLoader>
            </Box>
          </LayoutFilter>
        </>
      )}
    </ErrorBoundary>
  );
};

QualifyingProgramsList.propTypes = {
  title: PropTypes.string,
  category: PropTypes.string,
  noResults: PropTypes.string,
  searchKey: PropTypes.string,
  // fixedScrollHeight: PropTypes.number,
};

export default QualifyingProgramsList;
