import React, {useEffect, useState} from 'react';
// config
import {IMG_CLOUDINARY} from 'config';
// hooks
import {useInstance} from 'core/hooks';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
// redux
import {
  fetchCareerAreaList,
  getAllCareerAreaList,
} from 'redux/modules/programs';
import {
  getCareerRank,
  clearCareerRank,
  fetchOccupationRank,
} from 'redux/modules/career-destination';
// Components
import {
  CareerList,
  CareerFilter,
} from './subModules/CareerListContainer/components';
import {
  Button,
  Layout,
  DialogBox,
  ErrorBoundary,
  ResourceBanner,
  BackgroundPaper,
} from 'mui-core';
import {Box, Stack, useTheme, Container, Typography} from '@mui/material';
// utils
import {careerBanner} from 'assets/images';
import {styled} from '@mui/material/styles';
import {updateQueryParams} from 'core/utils';
import {exploreCareer} from 'data/exploreCareer.json';
import {getAllCareerAreaListLoader} from 'redux/modules/programs/selectors';

const {
  subHeading1,
  subHeading2,
  filterLabel,
  bannerHeading,
  FilterOptions,
  takeSurveyBtn,
  readMoreLabel,
  defaultLocation,
} = exploreCareer;

// Add this to filterOptions in json to get most openings option
// {
//   "label": "Most Openings",
//   "value": "most_openings_rank",
//   "apiKey": "most_openings"
// },
const {LayoutFilter, LayoutHeader} = Layout;

const StyledSubHeading = styled(Typography)(({theme}) => ({
  '& .highlighted-text': {
    color:
      theme.palette.mode === 'light'
        ? theme.palette.primary.dark
        : theme.palette.primary.light,
  },
}));

const StyledReadMoreBtn = styled(Button)(({theme}) => ({
  color:
    theme.palette.mode === 'light'
      ? theme.palette.primary.main
      : theme.palette.primary.light,
}));

const ExploreCareer = () => {
  let showReadMore = false;
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const {search} = useLocation();
  const careerRank = useSelector(getCareerRank) || {};
  const [showDescDialog, setShowDescDialog] = useState(false);
  const careerAreaList = useSelector(getAllCareerAreaList) || {};
  const carrerAreaListLoader = useSelector(getAllCareerAreaListLoader);
  const {
    data: {
      nearby_msa = [],
      super_type_name = null,
      default_msa: {city = '', state_code = '', msa_code = ''} = {},
    } = {},
  } = useInstance();
  const defaultLoc = city ? `${city}, ${state_code}` : defaultLocation;
  const defaultParams = {
    page: 1,
    title: '',
    cluster_uuid: 'all',
    location: defaultLoc,
    msa_code: msa_code || '',
    selectedTab: FilterOptions[0],
  };
  const [reqParams, setReqParams] = useState(defaultParams);
  let selectedCareerArea =
    careerAreaList?.find(i => i.cluster_uuid === reqParams?.cluster_uuid) || {};
  let clusterDescription = selectedCareerArea?.cluster_description || '';
  if (clusterDescription?.length > 600) {
    showReadMore = true;
    clusterDescription = `${clusterDescription.slice(0, 600)}...`;
  }

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(search);
    const clusterName = urlSearchParams.get('cluster_area');

    if (clusterName) {
      dispatch(
        fetchCareerAreaList(true, newList => {
          let selectedClusterId = '';
          if (newList?.length > 0) {
            const career = newList?.find(
              cl => cl.cluster_name === clusterName.replace(/-/g, ' '),
            );
            if (career?.cluster_uuid) {
              selectedClusterId = career.cluster_uuid;
            }
          }
          initPage({
            selectedTab: FilterOptions[2],
            cluster_uuid: selectedClusterId,
          });
        }),
      );
    } else dispatch(fetchCareerAreaList(true, () => initPage()));
    return () => {
      dispatch(clearCareerRank());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const initPage = defaultParams => {
    let newParams = {...reqParams, ...defaultParams};

    if (super_type_name && msa_code) {
      const defaultLoc = city && state_code && `${city}, ${state_code}`;
      newParams = {...newParams, msa_code, location: defaultLoc};
    } else {
      newParams = {...newParams, location: defaultLocation};
    }
    refetchData(newParams);
  };

  const refetchData = (Obj, loadMore) => {
    setReqParams(Obj);
    dispatch(fetchOccupationRank(Obj, loadMore));
  };

  const handleFilterChange = (filterType, value) => {
    let updatedParams = {
      ...reqParams,
    };
    if (filterType === 'cluster_uuid') {
      const cluster = careerAreaList?.find(cl => cl.cluster_uuid === value);
      if (cluster?.cluster_name) {
        updateQueryParams({cluster_area: cluster.cluster_name});
      } else {
        history.push('/careers');
      }
      updatedParams = {...reqParams, page: 1, [filterType]: value};
    }
    if (filterType === 'selectedTab') {
      const resultKey = FilterOptions?.filter(key => key.value === value)[0];
      updatedParams = {
        ...reqParams,
        page: 1,
        selectedTab: resultKey,
      };
    }
    if (filterType === 'title') {
      updatedParams = {...reqParams, page: 1, [filterType]: value};
    }
    if (filterType === 'location') {
      if (value === 'reset') {
        updatedParams = {
          ...reqParams,
          page: 1,
          [filterType]: defaultParams.location,
          msa_code: defaultParams.msa_code,
        };
        refetchData(updatedParams);
        return;
      }
      if (reqParams.location === 'United States' && value === '') {
        return;
      }
      if (value.length < 2) {
        return;
      }

      if (value && value?.msa_code) {
        updatedParams = {
          ...reqParams,
          page: 1,
          [filterType]: value.location,
          msa_code: value.msa_code,
        };
      } else {
        updatedParams = {
          ...reqParams,
          page: 1,
          [filterType]: value?.location,
          msa_code: '',
        };
      }
    }
    refetchData(updatedParams);
  };

  return (
    <BackgroundPaper background={theme.palette.purplebg4perc.main}>
      <Container>
        <ErrorBoundary>
          {selectedCareerArea?.cluster_name ? (
            <ResourceBanner
              title={selectedCareerArea?.cluster_name}
              bannerImage={`${IMG_CLOUDINARY}/${selectedCareerArea?.cluster_banner_url}`}>
              <Typography lineHeight={2} variant='pagedesc'>
                {clusterDescription}
                {showReadMore && (
                  <StyledReadMoreBtn onClick={() => setShowDescDialog(true)}>
                    {readMoreLabel}
                  </StyledReadMoreBtn>
                )}
              </Typography>
            </ResourceBanner>
          ) : (
            <ResourceBanner
              title={bannerHeading}
              bannerImage={careerBanner}
              buttonLabel={takeSurveyBtn}
              onButtonClick={() => history.push('/career-survey')}>
              <Stack spacing={2}>
                <Typography lineHeight={2} variant='pagedesc'>
                  {subHeading1}
                </Typography>
                <StyledSubHeading
                  lineHeight={2}
                  variant='pagedesc'
                  dangerouslySetInnerHTML={{__html: subHeading2}}
                />
              </Stack>
            </ResourceBanner>
          )}
          <Box my={{xs: 3, md: 2, lg: 0}}>
            <LayoutHeader title={bannerHeading} />
            <LayoutFilter
              existFilter={true}
              title={filterLabel}
              filterContent={
                <CareerFilter
                  reqParams={reqParams}
                  LocationOptions={nearby_msa?.map((l, idx) => {
                    return {
                      ...l,
                      id: idx + 1,
                      nearby_msa: 'Nearby Locations',
                    };
                  })}
                  FilterOptions={FilterOptions}
                  careerAreaList={careerAreaList}
                  handleFilterChange={handleFilterChange}
                />
              }>
              <Box p={2} minHeight={460}>
                <CareerList
                  reqParams={reqParams}
                  careerRank={careerRank}
                  refetchData={refetchData}
                  carrerAreaListLoader={carrerAreaListLoader}
                />
              </Box>
            </LayoutFilter>
          </Box>
          <DialogBox
            size='md'
            disableIcon
            openDialog={showDescDialog}
            title={selectedCareerArea?.cluster_name}
            closeDialog={() => setShowDescDialog(false)}>
            <Typography
              variant='body1'
              color='text.primary'
              dangerouslySetInnerHTML={{
                __html: selectedCareerArea?.cluster_description,
              }}
            />
          </DialogBox>
        </ErrorBoundary>
      </Container>
    </BackgroundPaper>
  );
};

export default ExploreCareer;
