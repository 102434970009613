import React from 'react';
import ReactPlayer from 'react-player';
import {Box, Paper, styled, Typography, Container, Stack} from '@mui/material';

const VideoBannerWrapper = styled(Box)(({theme}) => ({
  width: '100%',
  height: '100%',
  maxHeight: '580px',
  overflow: 'hidden',
  position: 'relative',
  backgroundPosition: 'center',
  background: 'rgba(0, 0, 0, 0.87)',
  [`& .overlay`]: {
    opacity: 0.5,
    width: '100%',
    marginTop: '-10%',
    marginBottom: '-5px',
  },
  [theme.breakpoints.down('md')]: {
    height: 'auto',
  },
}));

const StyledRibbon = styled(Paper)(({theme}) => ({
  zIndex: 1,
  bottom: 0,
  height: 80,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  backgroundColor: 'rgb(0 0 0 / 60%)',
  [theme.breakpoints.down('md')]: {
    height: 40,
  },
}));

const TextWrapper = styled(Box)(({theme}) => ({
  top: '0',
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  backgroundColor: 'rgb(0 0 0 / 25%)',
}));

const ContentBox = styled(Stack)(({theme}) => ({
  width: '60%',
  padding: '0px',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    padding: '36px 8px 42px',
  },
}));

const VideoBanner = ({bannerURL, ribbonText, children}) => {
  return (
    <VideoBannerWrapper>
      <Box className='overlay'>
        <ReactPlayer
          loop
          muted
          playing
          playsinline
          width='100%'
          height='100%'
          url={bannerURL}
        />
      </Box>
      <TextWrapper>
        <Container maxWidth='xl'>
          <ContentBox spacing={2} alignItems='left' justifyContent='left'>
            {children}
          </ContentBox>
        </Container>
      </TextWrapper>
      {ribbonText && (
        <StyledRibbon>
          <Container maxWidth='xl'>
            <Typography variant='h4' color='white'>
              {ribbonText}
            </Typography>
          </Container>
        </StyledRibbon>
      )}
    </VideoBannerWrapper>
  );
};

export default VideoBanner;
