import PropTypes from 'prop-types';
import {styled} from '@mui/material/styles';
import {
  Box,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  FormControlLabel,
} from '@mui/material';

const StyledSelect = styled(Select)(({theme}) => ({
  minWidth: 216,
  paddingLeft: 8,
  paddingRight: 8,
  border: 'none !important',
  whiteSpace: {xs: 'noWrap', sm: 'none'},
  [theme.breakpoints.down('md')]: {
    fontSize: '12px !important',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '15px',
  },
}));

const ProgramsSorter = ({
  sort_by,
  options,
  name = '',
  onSortChange,
  title = 'Sort By',
  label = 'Sort By',
}) => (
  <Box>
    <FormControl sx={{mt: 1, minWidth: 80}}>
      <InputLabel id='sort-by-label' htmlFor='Sort By'>
        Sort By
      </InputLabel>
      <StyledSelect
        label={label}
        title={title}
        value={sort_by}
        variant='outlined'
        aria-label='Sort By'
        id={`${name}-sorter-select`}
        inputProps={{id: 'Sort By'}}
        onChange={event => onSortChange(event.target.value)}>
        {options.map(({key, label}) => (
          <MenuItem key={`${key}`} value={key}>
            {label}
          </MenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  </Box>
);

ProgramsSorter.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  label: PropTypes.string,
  defValue: PropTypes.object,
  onMenuSelection: PropTypes.func,
  options: PropTypes.array.isRequired,
};

export default ProgramsSorter;
