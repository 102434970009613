import * as TYPES from './constants';

export const requestCareerRank = () => ({
  type: TYPES.REQUEST_CAREER_RANK,
});
export const recieveCareerRank = (json, loadMore = false) => ({
  type: TYPES.RECIEVE_CAREER_RANK,
  payload: json,
  loadMore,
});
export const requestCareerRankError = err => ({
  type: TYPES.REQUEST_CAREER_RANK_ERROR,
  err,
});
export const clearCareerRank = () => ({
  type: TYPES.CLEAR_CAREER_RANK,
});

// Occupation by cluster
export const requestOccupationByCluster = () => ({
  type: TYPES.REQUEST_OCCUPATION_BY_CLUSTER,
});
export const recieveOccupationByCluster = (json, loadMore = false) => ({
  type: TYPES.RECIEVE_OCCUPATION_BY_CLUSTER,
  payload: json,
  loadMore,
});
export const requestOccupationByClusterError = err => ({
  type: TYPES.REQUEST_OCCUPATION_BY_CLUSTER_ERROR,
  err,
});
