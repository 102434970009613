import {Stack} from '@mui/material';
import CareerInterest from './CareerInterest';
import CareerFavorites from './CareerFavorites';
import CareerTaskSurvey from './CareerTaskSurvey';

const Career = () => {
  return (
    <Stack gap={2}>
      <CareerFavorites />
      <CareerInterest />
      <CareerTaskSurvey />
    </Stack>
  );
};

export default Career;
