import {WarnModal} from 'core/components';
import {capitalizeFirstLetter} from 'core/utils';
import {
  saveGreen,
  redWarning,
  tickGreenCircle,
  rocketStarsIcon,
} from 'assets/images';
import {utilsMessage} from 'data/opportunities.json';
export {WarnModal};

const {successTitle, cancelText, successDescription} = utilsMessage;
export const applyConfirmModalProps = {
  icon: rocketStarsIcon,
  buttonText: 'APPLY',
  title: successTitle,
  description: [successDescription],
};

export const saveConfirmModalProps = ({job_type}) => {
  const confirmSaveLabel =
    job_type === 'other' ? 'Other opportunity' : job_type;
  return {
    icon: saveGreen,
    buttonText: 'SAVE',
    title: `Are you sure want to save this ${capitalizeFirstLetter(
      confirmSaveLabel,
    )}?`,
  };
};

export const applyJobSuccessModalProps = ({
  job_type,
  job_title,
  company_name,
}) => {
  const jobApplyLabel = job_type === 'other' ? 'other opportunity' : job_type;
  return {
    icon: tickGreenCircle,
    cancelText: cancelText,
    buttonText: `VIEW APPLIED ${job_type.toUpperCase()}`,
    title: `${capitalizeFirstLetter(jobApplyLabel)} Application Submitted!`,
    description: [
      `You have successfully applied to the <span class="text-pink">${job_title}</span> ${jobApplyLabel} at <span class="text-pink">${company_name}</span>.`,
      `Be sure to check your email for all ${jobApplyLabel} application updates. Good Luck!`,
    ],
  };
};

export const saveJobSuccessModalProps = ({
  job_type,
  job_title,
  company_name,
}) => {
  const jobSaveLabel = job_type === 'other' ? 'other opportunity' : job_type;
  return {
    icon: tickGreenCircle,
    title: `${capitalizeFirstLetter(jobSaveLabel)} Saved!`,
    buttonText: `VIEW SAVED ${job_type.toUpperCase()}`,
    description: [
      `You have successfully saved to the <span class="text-pink">${job_title}</span> ${jobSaveLabel} at <span class="text-pink">${company_name}</span>.`,
      `You can view the list of saved ${jobSaveLabel} in your profile.`,
    ],
  };
};

export const removeJobModalProps = ({job_type}) => {
  const jobLabel = job_type === 'other' ? 'other opportunity' : job_type;
  return {
    icon: redWarning,
    title: `Remove ${capitalizeFirstLetter(jobLabel)} Application`,
    description: [
      `Are you sure you want to remove this ${jobLabel} application? `,
      `By doing so, your profile will be removed from consideration for this position.`,
    ],
    okText: 'Yes',
    cancelText: 'Cancel',
  };
};

export const removeSavedJobModalProps = ({job_type}) => {
  const RemoveSaveLabel = job_type === 'other' ? 'other opportunity' : job_type;
  return {
    title: `Remove Saved ${capitalizeFirstLetter(RemoveSaveLabel)}`,
    icon: redWarning,
    description: [
      `Are you sure you want to remove this saved ${RemoveSaveLabel}?`,
    ],
    okText: 'Yes',
    cancelText: 'Cancel',
  };
};
