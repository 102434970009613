import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {noCollageLogo} from 'assets/images';
import {styled} from '@mui/material/styles';
import {numberInUSFormat} from 'core/utils';
import {Card, LazyImage, ErrorBoundary} from 'mui-core';
import {industryIconBlack, industryIconWhite} from 'assets/images';
import verifiedCheckmark from '../../assets/images/icons/verified-checkmark.svg';
import {
  Box,
  Stack,
  Divider,
  useTheme,
  Typography,
  CardActionArea,
} from '@mui/material';

const CompanyLogo = styled(Box)(({theme}) => ({
  '& img': {
    width: 40,
    height: 40,
    maxWidth: '100%',
    objectFit: 'contain',
    borderRadius: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));
const StyledTypography = styled(Typography)(({}) => ({
  width: '80%',
  overflow: 'hidden',
  color: 'text.primary',
  display: '-webkit-box',
  wordBreak: 'break-word',
  textOverflow: 'ellipsis',
  '-webkit-line-clamp': '2',
  '-webkit-box-orient': 'vertical',
}));

const renderInfoItem = (infoItems, textColor) => {
  const {Icon, value, count, label = ''} = infoItems || {};
  return (
    <Stack direction='row' spacing={0.5} flexWrap='wrap' alignItems='center'>
      {Icon && (
        <Icon
          sx={{width: 18, height: 18, marginBottom: '4px', color: textColor}}
        />
      )}
      {count >= 0 && label ? (
        <Typography variant='body2' color={textColor}>
          {`${numberInUSFormat(count)} ${label}`}
        </Typography>
      ) : (
        <Typography variant='body2' color={textColor}>
          {value}
        </Typography>
      )}
    </Stack>
  );
};

const CompanyCard = ({
  data,
  width,
  children,
  infoItems,
  target = '_blank',
  showVerfiedIcon = false,
  enableNavigation = false,
}) => {
  const {
    path,
    title,
    companyName,
    companyLogo,
    companyBanner,
    industrySectorName,
  } = data || {};

  const theme = useTheme();
  const history = useHistory();
  const {
    location: {pathname},
  } = history;
  const industryIcon =
    theme?.palette?.mode === 'light' ? industryIconBlack : industryIconWhite;

  const handleNavigation = () => {
    if (target === '_self' || pathname === '/') {
      history.push(path);
    } else {
      window.open(path, target);
    }
  };

  const themeTextColor =
    theme.palette.mode === 'light'
      ? theme.palette.grey.main
      : theme.palette.grey.contrastText;

  return (
    <ErrorBoundary nameOfComponent='CompanyCard'>
      <Card
        sx={{
          mb: 2,
          width,
          textAlign: 'left',
          position: 'relative',
          display: 'inline-block',
        }}
        component={CardActionArea}
        onClick={() => enableNavigation && handleNavigation()}>
        <Box height={200}>
          <LazyImage
            type='bp'
            renderSrcSet
            alt='company-banner'
            src={companyBanner}
          />
        </Box>
        <Stack m={2} spacing={2} height={title ? 120 : 70}>
          {title && (
            <Typography
              noWrap
              variant='h6'
              color='text.primary'
              title={title || ''}>
              {title}
            </Typography>
          )}
          <Stack direction='row' spacing={1} alignItems='center'>
            <CompanyLogo>
              <LazyImage
                src={companyLogo}
                alt='company-logo'
                imageType='cardLogo'
                defaultImage={noCollageLogo}
              />
            </CompanyLogo>
            {companyName && (
              <StyledTypography variant='h7' title={companyName || ''}>
                {companyName}
              </StyledTypography>
            )}
            {showVerfiedIcon && (
              <Box>
                <img
                  width={18}
                  height={18}
                  src={verifiedCheckmark}
                  alt='verified Checkmark icon'
                />
              </Box>
            )}
          </Stack>
          {industrySectorName && (
            <Stack direction='row' mt={1} spacing={1} alignItems='center'>
              <img
                width={18}
                height={18}
                src={industryIcon}
                alt='industry icon'
              />
              <Typography
                noWrap
                variant='body2'
                color={themeTextColor}
                title={industrySectorName || ''}>
                {industrySectorName || ''}
              </Typography>
            </Stack>
          )}
        </Stack>
        {(infoItems || children) && <Divider sx={{borderStyle: 'dashed'}} />}
        {infoItems && (
          <Stack
            mx={2}
            direction='row'
            sx={{height: 60}}
            spacing={infoItems?.length > 1 ? 3 : 0}>
            {infoItems &&
              Array.isArray(infoItems) &&
              infoItems?.length > 0 &&
              infoItems.map(infoItems =>
                renderInfoItem(infoItems, themeTextColor),
              )}
          </Stack>
        )}
        {children}
      </Card>
    </ErrorBoundary>
  );
};

CompanyCard.propTypes = {
  data: PropTypes.object,
  width: PropTypes.number,
  enableNavigation: PropTypes.bool,
};

export default CompanyCard;
