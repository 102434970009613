// hooks
import {useEffect, useState} from 'react';
import ReactPlayer from 'react-player';
import {useParams} from 'react-router-dom';
import {styled} from '@mui/material/styles';
import {getCloudinaryImage} from 'mui-core/utils';
import {Box, Chip, Stack, Typography, chipClasses} from '@mui/material';
import {ErrorBoundary, RequestErrorLoader, themeColorMode} from 'mui-core';

const AboutContentWrapper = styled(Box)(({theme}) => ({
  display: 'flex',
  borderRadius: '20px',
  justifyContent: 'left',
  padding: '32px',
  backgroundColor: themeColorMode(
    theme,
    theme.palette.grayLight.main,
    theme.palette.darkGray.main,
  ),
  border: `0.5px solid ${themeColorMode(
    theme,
    theme.palette.lightGrey.light,
    theme.palette.primary.light,
  )}`,
  boxShadow: '0px 4px 8px rgba(0,0,0,0.08)',
  [theme.breakpoints.down('md')]: {
    padding: '16px',
  },
}));

const StyledPlayerWrapper = styled('div')(({theme}) => ({
  width: 520,
  borderRadius: 20,
  overflow: 'hidden',
  [theme.breakpoints.down('md')]: {
    marginTop: '16px',
    width: 'fit-content',
  },
}));

const Image = styled('img')(({theme}) => ({
  width: 520,
  height: 300,
  borderRadius: 20,
  boxShadow: '0px 4px 8px rgba(0,0,0,0.2)',
  [theme.breakpoints.down('lg')]: {
    width: 430,
    height: 350,
  },
  [theme.breakpoints.down('md')]: {
    marginTop: '16px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    height: '100%',
  },
}));

export const AboutContentLayout = ({
  title,
  children,
  titleAlignment = 'center',
}) => {
  return (
    <AboutContentWrapper>
      <Stack spacing={2} width='100%'>
        {title && (
          <Typography
            variant='h4'
            textTransform='capitalize'
            textAlign={{xs: 'center', md: titleAlignment}}>
            {title}
          </Typography>
        )}
        {children}
      </Stack>
    </AboutContentWrapper>
  );
};

const RenderChip = ({chipValue}) => {
  const styles = {
    m: 1,
    [`&.${chipClasses.root}`]: {
      minHeight: 39,
      paddingTop: '6px',
      height: 'initial',
      paddingBottom: '6px',
    },
    [`& .${chipClasses.label}`]: {
      overflow: 'initial',
      textOverflow: 'initial',
      whiteSpace: 'normal',
    },
  };
  return (
    <Stack direction='row' justifyContent='center' flexWrap='wrap'>
      {typeof chipValue === 'string' ? (
        <Chip
          sx={styles}
          label={<Typography variant='bodyXL'>{chipValue}</Typography>}
        />
      ) : (
        chipValue?.map(item => (
          <Chip
            sx={styles}
            label={<Typography variant='bodyXL'>{item}</Typography>}
          />
        ))
      )}
    </Stack>
  );
};

const RenderAboutContent = ({chipTitle = false, surveyArr}) => {
  return surveyArr.map(({title, description}) => (
    <Stack px={{xs: 0, md: 24}}>
      {chipTitle ? (
        <RenderChip chipValue={title} />
      ) : (
        <Typography textAlign='center' variant='h5'>
          {title}
        </Typography>
      )}
      <Typography textAlign='center' variant='pagedesc'>
        {description}
      </Typography>
    </Stack>
  ));
};

const getContentOf = obj => {
  if (obj && !Array.isArray(obj)) {
    const keys = Object.keys(obj);
    const values = Object.values(obj);
    const result = keys?.map((key, index) => {
      return {
        title: key || '',
        description: values[index] || '',
      };
    });
    const filteredResult = result?.filter(
      item => item?.title !== 'Median Wage Value',
    );
    return filteredResult;
  }
};

const isDataAvailable = arr => {
  return arr && Array.isArray(arr) && arr.length > 0;
};

const About = ({goSurveyResultDetails = {}}) => {
  // hooks
  const {career_name = ''} = useParams();
  const [renderVideo, setRenderVideo] = useState(false);

  const {request: goSurveyResultDetailsRequest, data: goSurveyResultData = {}} =
    goSurveyResultDetails || {};
  const {
    wage = {},
    tasks = [],
    skills = [],
    knowledge = {},
    education = {},
    work_style = [],
    work_values = [],
    technology = [],
    personality = {},
    what_is_title = '',
    work_activities = [],
    other_attributes = [],
    occupation_details = {},
  } = goSurveyResultData?.description || {};

  const {video_details = [], cip4_image_cloudinary = ''} =
    occupation_details || {};
  const {VideoCode} = video_details && video_details[0] ? video_details[0] : {};

  useEffect(() => {
    if (VideoCode) {
      setRenderVideo(true);
    } else {
      setRenderVideo(false);
    }
  }, [VideoCode]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ErrorBoundary nameOfComponent='mod-career-occupation-banner'>
      <RequestErrorLoader
        fullScreen
        hideBackground
        body={{
          ...goSurveyResultDetails,
          data: goSurveyResultData,
          request: goSurveyResultDetailsRequest,
        }}>
        <Stack mb={3} spacing={3}>
          <AboutContentLayout titleAlignment='left'>
            <Stack
              justifyContent='space-between'
              direction={{xs: 'column', md: 'row'}}>
              <Stack
                spacing={2}
                justifyContent='center'
                maxWidth={{xs: '100%', md: '55%'}}>
                <Typography variant='h4'>{career_name}</Typography>
                <Typography variant='pagedesc'>{what_is_title}</Typography>
              </Stack>

              {renderVideo ? (
                <StyledPlayerWrapper>
                  <ReactPlayer
                    controls
                    width='100%'
                    height='100%'
                    onError={() => setRenderVideo(true)}
                    url={`https://cdn.careeronestop.org/OccVids/OccupationVideos/${VideoCode}.mp4`}
                  />
                </StyledPlayerWrapper>
              ) : (
                <Image
                  src={getCloudinaryImage(cip4_image_cloudinary)}
                  alt={`${career_name?.slice(0, 50) || ''}-default-img`}
                />
              )}
            </Stack>
          </AboutContentLayout>

          {/* Wage and employer Trends */}
          {isDataAvailable(getContentOf(wage)) && (
            <AboutContentLayout title='Wage and employer Trends'>
              <Stack spacing={3}>
                <RenderAboutContent surveyArr={getContentOf(wage)} />
              </Stack>
            </AboutContentLayout>
          )}

          {/* personalityCharacteristics */}
          {isDataAvailable(getContentOf(personality)) && (
            <AboutContentLayout title='Personality Characteristics'>
              <Stack spacing={3}>
                <RenderAboutContent surveyArr={getContentOf(personality)} />
              </Stack>
            </AboutContentLayout>
          )}

          {/* Knowledge you will receive */}
          {isDataAvailable(getContentOf(knowledge)) && (
            <AboutContentLayout title='Knowledge you will receive'>
              <RenderAboutContent surveyArr={getContentOf(knowledge)} />
            </AboutContentLayout>
          )}

          {/* Skills You Will Receive */}
          {isDataAvailable(skills) && (
            <AboutContentLayout title='Skills You Will Receive'>
              <RenderChip chipValue={skills} />
            </AboutContentLayout>
          )}

          {/* Education Requirements */}
          {isDataAvailable(getContentOf(education)) && (
            <AboutContentLayout title='Education Requirements'>
              <RenderAboutContent
                chipTitle={true}
                surveyArr={getContentOf(education)}
              />
            </AboutContentLayout>
          )}

          {/* Technology/Tools Used */}
          {isDataAvailable(skills) && (
            <AboutContentLayout title='Technology/Tools Used'>
              <RenderChip chipValue={technology} />
            </AboutContentLayout>
          )}

          {/* Work Activities */}
          {isDataAvailable(work_activities) && (
            <AboutContentLayout title='Work Activities'>
              <RenderChip chipValue={work_activities} />
            </AboutContentLayout>
          )}

          {/* Work Values */}
          {isDataAvailable(work_values) && (
            <AboutContentLayout title='Work Values'>
              <RenderChip chipValue={work_values} />
            </AboutContentLayout>
          )}

          {/* Tasks Associated with This Career */}
          {isDataAvailable(tasks) && (
            <AboutContentLayout title='Tasks Associated with This Career'>
              <RenderChip chipValue={tasks} />
            </AboutContentLayout>
          )}

          {/* Work Styles */}
          {isDataAvailable(work_style) && (
            <AboutContentLayout title='Work Styles'>
              <RenderChip chipValue={work_style} />
            </AboutContentLayout>
          )}

          {/* Other Attributes Important to Know */}
          {isDataAvailable(other_attributes) && (
            <AboutContentLayout title='Other Attributes Important to Know'>
              <RenderChip chipValue={other_attributes} />
            </AboutContentLayout>
          )}
        </Stack>
      </RequestErrorLoader>
    </ErrorBoundary>
  );
};

export default About;
