import * as TYPES from './constants';
import {API} from 'config';

import {
  requestAllOpportunities,
  recieveAllOpportunities,
  requestAllOpportunitiesError,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.ALL_OPPORTUNITIES_REQUEST:
      return {
        ...state,
        error: false,
        request: true,
      };
    case TYPES.ALL_OPPORTUNITIES_SUCCESS:
      if (action.loadMore) {
        return {
          ...state,
          error: false,
          request: false,
          data: {
            ...action.payload,
            data: [...state?.data?.data, ...action.payload?.data],
            datalength:
              state.data.datalength +
                (action?.payload?.data &&
                  Array.isArray(action.payload.data) &&
                  action.payload.data.length) || 0,
          },
        };
      }
      return {
        ...state,
        error: false,
        request: false,
        data: {
          ...action.payload,
          datalength:
            (action?.payload?.data &&
              Array.isArray(action.payload.data) &&
              action.payload.data.length) ||
            0,
        },
      };
    case TYPES.ALL_OPPORTUNITIES_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    case TYPES.CLEAR_ALL_OPPORTUNITIES:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export const fetchAllOpportunities = (type, reqParams, filters, loadMore) => (
  dispatch,
  getState,
) => {
  const {
    auth: {auth: {token = null} = {}},
  } = getState();
  dispatch(requestAllOpportunities());
  const {
    page = 1,
    location = '',
    min_perct = 0,
    skills_option,
    page_size = 25,
    sort = 'date_desc',
  } = reqParams || {};
  const body = {};
  try {
    let endPoint = `${API.srm.allJobs}`;
    if (skills_option) {
      body['skills'] = 'true';
      body['min_perct'] = min_perct;
    }
    body['sort'] = sort;
    body['page'] = page;
    body['location'] = location;
    body['page_size'] = page_size;
    body['search'] = type || 'jobs';
    // if (filters.job_type) filters.job_type = 'remote';
    if (reqParams.company_name) body['company_name'] = reqParams.company_name;
    if (filters) {
      const getFilterKeys = Object.keys(filters);
      if (getFilterKeys.length) {
        getFilterKeys.map(key => {
          if (filters[key] || key === 'no_of_days') {
            // endPoint += `&${key}=${encodeURIComponent(filters[key])}`;
            body[key] = filters[key];
          }
        });
      }
    }
    // if (body.job_type) body.job_type = 'remote';
    // token, endpoint, data, method
    return services
      .createUpdateRecord(skills_option ? token : null, endPoint, body, 'POST')
      .then(res => {
        if (res) {
          dispatch(recieveAllOpportunities(res, loadMore));
          return;
        }
        dispatch(requestAllOpportunitiesError(res));
      });
  } catch (err) {
    console.log(err);
    dispatch(requestAllOpportunitiesError(err));
  }
};
