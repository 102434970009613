import {Grid} from 'mui-core';
import {BASE_URL} from 'assets/images';
import {Typography, Container} from '@mui/material';

const BenefitsOverview = ({cardsInfo}) => {
  return (
    <Container sx={{py: {xs: 4, md: 10}, pt: {xs: -4, md: 10}}}>
      <Typography textAlign='center' variant='h2' mb={8}>
        Key Benefits
      </Typography>
      <Grid
        container
        rowGap={4}
        spacing={3}
        display='flex'
        textAlign='center'
        justifyContent='center'>
        {cardsInfo.map(({icon, title, description}) => (
          <Grid
            item
            md={4}
            xs={12}
            gap={3}
            display='flex'
            alignItems='center'
            flexDirection='column'>
            {icon && (
              <img
                src={`${BASE_URL}${icon}`}
                alt='icon'
                width='130px'
                height='130px'
              />
            )}
            {title && <Typography variant='h5'>{title}</Typography>}
            {description && (
              <Typography variant='body1'>{description}</Typography>
            )}
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default BenefitsOverview;
