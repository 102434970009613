import {useEffect, useState} from 'react';
import {API} from 'config';
import {Box} from '@mui/material';
import {useAuth} from 'core/hooks';
import {useSnackbar} from 'notistack';
import {Services} from 'core/Services';
import {queryStringParse} from 'core/utils';
import {useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {PageLoader, RequestErrorLoader} from 'mui-core';
import {SurveyResultPage, QuestionnairePage} from './components';
import {goSurveyResultsSuccess} from 'redux/modules/survey/actions';
import {
  getGoSurveyAnswers,
  getGoSurveyResults,
  fetchGoSurveyResults,
} from 'redux/modules/survey';
import {goSurvey} from 'data/goSurvey.json';

const services = new Services();
const {
  loaderContent: {
    title: loadTitle,
    description: loadDesc,
    surveyResultsFetchMsg,
  },
} = goSurvey;

const compareAnswers = (oldAnswers, newAnswers) => {
  if (oldAnswers.length !== newAnswers.length) {
    return false;
  }

  return oldAnswers.every((oldAnswer, index) => {
    let newAnswer = newAnswers[index];
    return (
      oldAnswer.question_id === newAnswer.question_id &&
      oldAnswer.student_response === newAnswer.student_response
    );
  });
};

const SurveyPage = () => {
  // hooks
  const [token] = useAuth();
  const dispatch = useDispatch();
  const {search} = useLocation();
  const {modify} = queryStringParse(search);
  // state
  const [requestSaveResult, setRequestSaveResult] = useState();
  const [screen, setScreen] = useState(modify ? 'questions' : 'results');
  const {enqueueSnackbar} = useSnackbar();
  const {data: surveyResults = [], request: surveyResultsRequest} = useSelector(
    getGoSurveyResults,
  );
  const {data: surveyAnswers = []} = useSelector(getGoSurveyAnswers);

  useEffect(() => {
    if (!surveyResults || surveyResults?.length === 0) {
      dispatch(fetchGoSurveyResults());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (surveyResults && surveyResults.length === 0) {
      setScreen('questions');
    }
  }, [surveyResults]);

  const saveStudentCIS = payload => {
    if (payload.length === 0 && surveyAnswers.length === 0) {
      setScreen('results');
      return;
    }
    // if (payload.length > 0) {
    //   const isSame = compareAnswers(surveyAnswers, payload);
    //   if (isSame) {
    //     setScreen('results');
    //     return;
    //   }
    // }
    setRequestSaveResult(true);
    services
      .createUpdateRecord(
        token,
        `${API.gps.student_cis_data}`,
        {go_ai: 'get_matching_results', question_list: payload},
        'POST',
      )
      .then(res => {
        const {career_suggestions} = res;
        if (career_suggestions?.length > 0) {
          dispatch(goSurveyResultsSuccess(career_suggestions));
          setRequestSaveResult(false);
          setScreen('results');
          setTimeout(() => {
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
          }, 280);
        } else {
          setRequestSaveResult(false);
          enqueueSnackbar(
            res.Error ||
              res.error ||
              'We’re currently experiencing high traffic. Please try again in a few minutes.',
            {
              variant: 'warning',
            },
          );
        }
      })
      .catch(err => {
        setRequestSaveResult(false);
        const errMsg = typeof err === 'string' ? err : null;
        enqueueSnackbar(
          errMsg ||
            'We’re currently experiencing high traffic. Please try again in a few minutes.',
          {
            variant: 'error',
          },
        );
      });
  };

  const onModifySurvey = () => setScreen('questions');

  return (
    <Box p={2}>
      <RequestErrorLoader
        hideEmpty
        fullScreen
        title={surveyResultsFetchMsg}
        body={{request: surveyResultsRequest}}
      />
      {requestSaveResult && (
        <PageLoader title={loadTitle} description={loadDesc} isTransparent />
      )}
      {screen === 'questions' ? (
        <QuestionnairePage saveStudentCIS={saveStudentCIS} />
      ) : (
        !surveyResultsRequest && (
          <SurveyResultPage
            surveyResults={surveyResults}
            onModifySurvey={onModifySurvey}
          />
        )
      )}
    </Box>
  );
};

export default SurveyPage;
