import React from 'react';
import {
  Box,
  Stack,
  Dialog,
  Divider,
  IconButton,
  DialogTitle,
  useMediaQuery,
  DialogContent,
  DialogActions,
} from '@mui/material';
import PropTypes from 'prop-types';
import Fade from '@mui/material/Fade';
import Slide from '@mui/material/Slide';
import {redWarning} from 'assets/images';
import CloseIcon from '@mui/icons-material/Close';
import {styled, useTheme} from '@mui/material/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const StyledDialogContainer = styled(Dialog)(({dialogPadding, theme}) => ({
  '& .MuiDialog-paper': {
    padding: dialogPadding,
  },
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3),
  },
  '& .MuiDialogActions-root': {
    paddingBottom: theme.spacing(2),
  },
}));

const DialogBox = ({
  actions,
  children,
  title = '',
  openDialog,
  closeDialog,
  customDialogsx,
  closeIcon = true,
  fullScreen = false,
  disableIcon = false,
  showDivider = false,
  showIcon = redWarning,
  dialogPadding = '0px',
  customizeable = false,
  closeIconColor = 'black',
  titleAlignment = 'left', // 'left' | 'center' | 'right'
  iconAlignment = 'center', // 'left' | 'center' | 'right'
  buttonAlignment = 'center', // 'start' | 'center' | 'end'
  showTransition = true, // This add's transition to the dialogBox
  size = 'sm', // 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false | string
  iconSize = 42,
}) => {
  // hooks
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const renderCloseIcon = () => (
    <IconButton
      aria-label='close'
      onClick={closeDialog}
      id='dialog_close_button'
      sx={{
        top: 12,
        right: 12,
        zIndex: '9',
        position: 'absolute',
        color: closeIconColor,
      }}>
      <CloseIcon />
    </IconButton>
  );

  return (
    <>
      {!customizeable ? (
        <StyledDialogContainer
          fullWidth
          maxWidth={size}
          open={openDialog}
          onClose={closeDialog}
          dialogPadding={dialogPadding}
          aria-labelledby='mui-core-dialog-box'
          fullScreen={isMobile && fullScreen ? fullScreen : false}
          TransitionComponent={showTransition ? Transition : Fade}>
          {title && (
            <DialogTitle
              variant='h3'
              color='text.primary'
              textAlign={titleAlignment}
              id='mui-core-dialog-box-title'>
              {title}
            </DialogTitle>
          )}
          {closeDialog && closeIcon && renderCloseIcon()}
          {showDivider && <Divider />}
          <DialogContent>
            {showIcon && !disableIcon && (
              <Box mb={2} mt={4} textAlign={iconAlignment}>
                <img
                  src={showIcon}
                  width={iconSize}
                  alt='mui-core-dialog-box-icon'
                />
              </Box>
            )}
            {children}
          </DialogContent>
          {actions && (
            <Stack alignItems={buttonAlignment}>
              <DialogActions>{actions}</DialogActions>
            </Stack>
          )}
        </StyledDialogContainer>
      ) : (
        <Dialog
          fullWidth
          maxWidth={size}
          open={openDialog}
          onClose={closeDialog}
          aria-labelledby='custom-dialog-box'
          fullScreen={isMobile && fullScreen ? fullScreen : false}
          sx={{
            '& .MuiBackdrop-root': {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
            ...customDialogsx,
          }}>
          {title && (
            <DialogTitle
              variant='h3'
              color='text.primary'
              textAlign={titleAlignment}
              id='customized-dialog-title'>
              {title}
            </DialogTitle>
          )}
          {closeDialog && closeIcon && renderCloseIcon()}
          {showDivider && <Divider />}
          {children && <DialogContent>{children}</DialogContent>}
        </Dialog>
      )}
    </>
  );
};

DialogBox.propTypes = {
  ...Dialog.propTypes,
  size: PropTypes.string,
  title: PropTypes.string,
  closeIcon: PropTypes.bool,
  openDialog: PropTypes.bool,
  actions: PropTypes.element,
  fullScreen: PropTypes.bool,
  showIcon: PropTypes.string,
  disableIcon: PropTypes.bool,
  closeDialog: PropTypes.func,
  children: PropTypes.element,
  showDivider: PropTypes.bool,
  customizeable: PropTypes.bool,
  showTransition: PropTypes.bool,
  dialogPadding: PropTypes.string,
  iconAlignment: PropTypes.string,
  customDialogsx: PropTypes.object,
  titleAlignment: PropTypes.string,
  buttonAlignment: PropTypes.string,
};

export default DialogBox;
