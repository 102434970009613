import {useAuth, useUser} from 'core/hooks';
import React, {useEffect, useState} from 'react';
import {goSurvey} from 'data/goSurvey.json';
import {useHistory} from 'react-router-dom';
import {openLoginScreen} from 'redux/modules/auth';
import {useDispatch, useSelector} from 'react-redux';
import WelcomeGoSurvey from './components/WelcomeGoSurvey';
import {Box, Stack, useTheme, Container} from '@mui/material';
import {fetchGoSurveyResults, getGoSurveyResults} from 'redux/modules/survey';
import {
  Faq,
  themeColorMode,
  BackgroundPaper,
  LayoutStaticBanner,
  RequestErrorLoader,
} from 'mui-core';

const {
  banners,
  buttonLabel,
  goSurveyBanner: {
    title = '',
    image = '',
    titleImage = '',
    description = '',
  } = {},
  faq,
} = goSurvey;
let listenUser = false;
const GOSurvey = () => {
  const theme = useTheme();
  const [token] = useAuth();
  const user = useUser();
  const history = useHistory();
  const dispatch = useDispatch();
  const {request} = useSelector(getGoSurveyResults);
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);

  const handleSubmitModal = () => {
    setShowWelcomeModal(false);
    history.push('/go-survey');
  };

  const handleSurveyNavigation = () => {
    dispatch(
      fetchGoSurveyResults(surveyResults => {
        if (surveyResults && surveyResults.length === 0) {
          setShowWelcomeModal(true);
        } else {
          history.push('/go-survey');
        }
      }),
    );
  };

  useEffect(() => {
    if (listenUser && user?.data?.student_uuid) {
      handleSurveyNavigation();
      listenUser = false;
    }
  }, [user]);

  const handleOpenLogin = () => {
    if (!token) {
      listenUser = true;
      dispatch(
        openLoginScreen({
          // callback: () => handleSurveyNavigation(),
        }),
      );
    } else {
      handleSurveyNavigation();
    }
  };

  const gradientFadeColor = themeColorMode(
    theme,
    'rgba(255, 255, 255, 0.7)',
    'rgba(18, 18, 18, 0.7)',
  );

  return (
    <BackgroundPaper>
      <Stack
        justifyContent='center'
        sx={{
          background:
            'linear-gradient(180deg, rgba(0, 128, 128, 0.7), ' +
            gradientFadeColor +
            ' )',
        }}>
        <Container maxWidth='xl' sx={{pt: {xs: 4, md: 0}}}>
          <LayoutStaticBanner
            imageFirst={false}
            button={buttonLabel}
            buttonAction={handleOpenLogin}
            detail={{
              heading: title,
              imgpath: image,
              titleImage: titleImage,
              subHeading: description,
            }}
          />
        </Container>
      </Stack>
      <Stack>
        {banners.map((banner, index) => (
          <LayoutStaticBanner
            key={banner.key}
            imageFirst={index % 2 === 0}
            buttonAction={handleOpenLogin}
            button={banner.button ? buttonLabel : ''}
            backgroundColor={
              index % 2 !== 0 &&
              themeColorMode(
                theme,
                theme.palette.lightGrey.main,
                theme.palette.darkGray.main,
              )
            }
            detail={{
              heading: banner.title,
              imgpath: banner.image,
              boxSizes: banner.boxSizes,
              imageBoxSx: banner.imageBoxSx,
              subHeading: banner.description,
            }}
          />
        ))}
        <Box
          sx={{
            background: themeColorMode(
              theme,
              theme.palette.lightGrey.main,
              theme.palette.dialogDarkBG.main,
            ),
          }}>
          <Container>
            <Faq data={faq}></Faq>
          </Container>
        </Box>
      </Stack>
      <WelcomeGoSurvey
        visible={showWelcomeModal}
        onSubmit={handleSubmitModal}
        onClose={() => setShowWelcomeModal(false)}
      />
      {request && <RequestErrorLoader hideEmpty fullScreen body={{request}} />}
    </BackgroundPaper>
  );
};

export default GOSurvey;
