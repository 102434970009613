import * as TYPES from './constants';

// Clusters
export const requestClusters = () => ({
  type: TYPES.CLUSTERS_REQUEST,
});
export const recieveClusters = json => ({
  type: TYPES.CLUSTERS_SUCCESS,
  payload: json,
});
export const requestClustersError = err => ({
  type: TYPES.CLUSTERS_ERROR,
  err,
});
export const clearClusters = () => ({
  type: TYPES.CLUSTERS_CLEAR,
});

// Path Occupation Search
export const requestPathOccupationSearch = () => ({
  type: TYPES.PATH_OCCUPATION_SEARCH_REQUEST,
});
export const recievePathOccupationSearch = json => ({
  type: TYPES.PATH_OCCUPATION_SEARCH_SUCCESS,
  payload: json,
});
export const requestPathOccupationSearchError = err => ({
  type: TYPES.PATH_OCCUPATION_SEARCH_ERROR,
  err,
});

// Award Type
export const requestAwardTypes = () => ({
  type: TYPES.AWARD_TYPES_REQUEST,
});
export const recieveAwardTypes = json => ({
  type: TYPES.AWARD_TYPES_SUCCESS,
  payload: json,
});
export const requestAwardTypesError = err => ({
  type: TYPES.AWARD_TYPES_ERROR,
  err,
});
export const clearAwardTypes = () => ({
  type: TYPES.AWARD_TYPES_CLEAR,
});

// Schools
export const requestSchools = () => ({
  type: TYPES.SCHOOLS_REQUEST,
});
export const recieveSchools = json => ({
  type: TYPES.SCHOOLS_SUCCESS,
  payload: json,
});
export const requestSchoolsError = err => ({
  type: TYPES.SCHOOLS_ERROR,
  err,
});
export const clearSchools = () => ({
  type: TYPES.SCHOOLS_CLEAR,
});

// Duration
export const requestDuration = () => ({
  type: TYPES.DURATION_REQUEST,
});
export const recieveDuration = json => ({
  type: TYPES.DURATION_SUCCESS,
  payload: json,
});
export const requestDurationError = err => ({
  type: TYPES.DURATION_ERROR,
  err,
});
export const clearDuration = () => ({
  type: TYPES.DURATION_CLEAR,
});

// Paths Search
export const requestPathsSearch = () => ({
  type: TYPES.PATHS_SEARCH_REQUEST,
});
export const recieveNewestPathsSearch = json => ({
  type: TYPES.NEWEST_PATHS_SEARCH_SUCCESS,
  payload: json,
});
export const recievePathsSearch = json => ({
  type: TYPES.PATHS_SEARCH_SUCCESS,
  payload: json,
});
export const requestPathsSearchError = err => ({
  type: TYPES.PATHS_SEARCH_ERROR,
  err,
});
export const clearPathSearch = () => ({
  type: TYPES.PATHS_SEARCH_CLEAR,
});
export const resetInitalLoad = () => ({
  type: TYPES.RESET_INITIAL_LOAD,
});

// Career Search
export const requestCareerSearch = () => ({
  type: TYPES.CAREER_SEARCH_REQUEST,
});
export const recieveCareerSearch = json => ({
  type: TYPES.CAREER_SEARCH_SUCCESS,
  payload: json,
});
export const requestCareerSearchError = err => ({
  type: TYPES.CAREER_SEARCH_ERROR,
  err,
});
export const clearCareerSearch = () => ({
  type: TYPES.CAREER_SEARCH_CLEAR,
});

// popular_paths

// Career Search
export const requestPopularPaths = () => ({
  type: TYPES.POPULAR_PATH_REQUEST,
});
export const recievePopularPaths = json => ({
  type: TYPES.POPULAR_PATH_SUCCESS,
  payload: json,
});
export const requestPopularPathsError = err => ({
  type: TYPES.POPULAR_PATH_REQUEST_ERROR,
  err,
});

//  in_demand_paths
export const requestInDemandPaths = () => ({
  type: TYPES.INDEMAND_PATH_REQUEST,
});
export const recieveInDemandPaths = json => ({
  type: TYPES.INDEMAND_PATH_SUCCESS,
  payload: json,
});
export const requestInDemandPathsError = err => ({
  type: TYPES.INDEMAND_PATH_REQUEST_ERROR,
  err,
});
