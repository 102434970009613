export const CLEAR_CAREER_RANK = 'CLEAR_CAREER_RANK';
export const REQUEST_CAREER_RANK = 'REQUEST_CAREER_RANK';
export const RECIEVE_CAREER_RANK = 'RECIEVE_CAREER_RANK';
export const REQUEST_CAREER_RANK_ERROR = 'REQUEST_CAREER_RANK_ERROR';

// Occupation by cluster
export const REQUEST_OCCUPATION_BY_CLUSTER = 'REQUEST_OCCUPATION_BY_CLUSTER';
export const RECIEVE_OCCUPATION_BY_CLUSTER = 'RECIEVE_OCCUPATION_BY_CLUSTER';
export const REQUEST_OCCUPATION_BY_CLUSTER_ERROR =
  'REQUEST_OCCUPATION_BY_CLUSTER_ERROR';
