import {useAuth} from 'core/hooks';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {openLoginScreen} from 'redux/modules/auth';
import {
  Faq,
  themeColorMode,
  BackgroundPaper,
  LayoutStaticBanner,
} from 'mui-core';
import {Box, Stack, useTheme, Container} from '@mui/material';
import {goResume} from 'data/goResume.json';

const {
  faq,
  banners,
  buttonLabel,
  goResumeBanner: {
    title = '',
    image = '',
    titleImage = '',
    description = '',
  } = {},
} = goResume;

const GOResume = () => {
  const theme = useTheme();
  const [token] = useAuth();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleOpenLogin = () => {
    if (!token) {
      dispatch(
        openLoginScreen({
          callback: () => history.push('/resume-builder'),
        }),
      );
    } else {
      history.push('/resume-builder');
    }
  };

  const gradientFadeColor = themeColorMode(
    theme,
    'rgba(255, 255, 255, 0.7)',
    'rgba(18, 18, 18, 0.7)',
  );

  return (
    <BackgroundPaper>
      <Stack
        justifyContent='center'
        sx={{
          background:
            'linear-gradient(180deg, rgba(99, 91, 255, 0.7), ' +
            gradientFadeColor +
            ' )',
        }}>
        <Container maxWidth='xl' sx={{pt: {xs: 4, md: 0}}}>
          <LayoutStaticBanner
            imageFirst={false}
            button={buttonLabel}
            buttonAction={handleOpenLogin}
            imgShadow='1px 5px 60px #c7b3fc'
            detail={{
              heading: title,
              imgpath: image,
              titleImage: titleImage,
              subHeading: description,
            }}
          />
        </Container>
      </Stack>
      <Stack>
        {banners.map((banner, index) => (
          <LayoutStaticBanner
            key={banner.key}
            imageFirst={index % 2 === 0}
            buttonAction={handleOpenLogin}
            imgShadow='1px 5px 60px #c7b3fc'
            backgroundColor={
              index % 2 !== 0 &&
              themeColorMode(
                theme,
                theme.palette.lightGrey.main,
                theme.palette.darkGray.main,
              )
            }
            button={banner.button ? banner?.buttonLabel || '' : ''}
            detail={{
              heading: banner.title,
              imgpath: banner.image,
              boxSizes: banner.boxSizes,
              imageBoxSx: banner.imageBoxSx,
              subHeading: banner.description,
              imageMinHeight: banner?.imageMinHeight
                ? banner.imageMinHeight
                : '',
            }}
          />
        ))}
        <Box
          sx={{
            background: themeColorMode(
              theme,
              theme.palette.lightGrey.main,
              theme.palette.dialogDarkBG.main,
            ),
          }}>
          <Container>
            <Faq data={faq}></Faq>
          </Container>
        </Box>
      </Stack>
    </BackgroundPaper>
  );
};

export default GOResume;
