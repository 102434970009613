import React, {useState, useEffect} from 'react';
import {useSnackbar} from 'notistack';
import {Accordion, ContentBox} from 'mui-core';
import SubscribedCareers from './SubscribedCareers';
import {useDispatch, useSelector} from 'react-redux';
import RemoveCareerDialog from '../RemoveCareerDialog';
import {
  getSubscribedOccupation,
  unSubscribeForOccupation,
  fetchSubscribedOccupation,
} from 'redux/modules/occupation';
import {career} from 'data/dashboard.json';

const {careerFavorites, careerUnsubscribeData} = career;
const {accordionDesc, accordionHeader} = careerFavorites;
const {title, description, successMsg} = careerUnsubscribeData;

const CareerFavorites = () => {
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const [selectedValue, setSelectedValue] = useState(null);
  const [showUnsubscribeDialog, setUnsubscribeDialog] = useState(false);
  const subscribedOccupation = useSelector(getSubscribedOccupation) || {};

  const onUnsubscribe = data => {
    setSelectedValue(data);
    setUnsubscribeDialog(true);
  };

  const unSubscribeDialogClose = () => {
    setUnsubscribeDialog(false);
  };

  const unSubscribeOccupation = occupation_uuid => {
    if (occupation_uuid) {
      dispatch(
        unSubscribeForOccupation(occupation_uuid, res => {
          if (res === 'success') {
            enqueueSnackbar(successMsg, {
              variant: 'success',
              anchorOrigin: {horizontal: 'center', vertical: 'top'},
            });
            unSubscribeDialogClose();
          }
        }),
      );
      dispatch(fetchSubscribedOccupation());
    }
  };

  useEffect(() => {
    dispatch(fetchSubscribedOccupation());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ContentBox elevation={0}>
      <Accordion
        defaultOpen
        outlined={false}
        header={accordionHeader}
        description={accordionDesc}>
        <SubscribedCareers
          onUnsubscribe={onUnsubscribe}
          subscribedOccupation={subscribedOccupation}
        />
        {showUnsubscribeDialog && (
          <RemoveCareerDialog
            dialogTitle={title}
            dialogDesc={description}
            showDialog={showUnsubscribeDialog}
            dialogClose={unSubscribeDialogClose}
            dialogConfirm={unSubscribeOccupation}
            selectedValueIndex={selectedValue.uuid}
            selectedValueLabel={selectedValue.occupation_name}
          />
        )}
      </Accordion>
    </ContentBox>
  );
};

export default CareerFavorites;
