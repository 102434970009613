import React from 'react';
import PropTypes from 'prop-types';
import {BASE_URL} from 'assets/images';
import {Button, themeColorMode} from 'mui-core';
import {
  Box,
  Stack,
  styled,
  useTheme,
  Container,
  Typography,
  useMediaQuery,
} from '@mui/material';

const Wrapper = styled(Stack)(({theme}) => ({
  padding: '8% 1%',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    padding: '16px 12px',
    marginBottom: '50px',
  },
  '& .sub-heading-list': {
    fontSize: '19px',
    textAlign: 'start',
  },
  '& .remove-list-padding': {
    paddingBottom: '0px',
  },
}));

const ImageWrapper = styled(Box)(({theme, imgShadow, background}) => ({
  padding: '8px',
  display: 'flex',
  objectFit: 'cover',
  borderRadius: '12px',
  boxShadow: imgShadow,
  alignItems: 'center',
  justifyContent: 'center',
  background: background && theme.palette.background.paper,
}));

const Image = styled('img')(() => ({
  width: '100%',
  objectFit: 'cover',
  borderRadius: '12px',
}));

const LayoutStaticBanner = ({
  button = '',
  buttonAction,
  imageFirst = false,
  backgroundColor = '',
  alignBasedOnImage = false,
  imgShadow = 'rgba(0, 0, 0, 0.08) 0px 5px 24px 0px',
  detail: {
    heading = '',
    imgpath = '',
    subHeading = '',
    titleImage = '',
    imageMinHeight = '',
    boxSizes = ['50%', '50%'],
    imageBoxSx = {background: true, shadow: true},
  },
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isLargeDevice = useMediaQuery(theme.breakpoints.up('lg'));

  let alignContent = 'left';
  let justifyContent = 'start';
  if (alignBasedOnImage) {
    alignContent = imageFirst ? 'right' : 'left';
    justifyContent = imageFirst ? 'flex-end' : 'flex-start';
  }

  return (
    <Box
      sx={{
        background: backgroundColor || theme.palette.background.main,
      }}>
      <Container maxWidth='xl'>
        <Wrapper
          alignItems='center'
          spacing={{xs: 2, sm: 4, md: 10}}
          direction={isMobile ? 'column' : imageFirst ? 'row-reverse' : 'row'}>
          <Box
            gap={3}
            display='grid'
            width={isMobile ? '100%' : boxSizes[0]}
            sx={{
              mb: {xs: 2, md: 18},
              mt: {xs: 2, sm: 3, md: 9},
              alignItems: {xs: 'center', md: 'start'},
              textAlign: {xs: 'center', md: alignContent},
              justifyItems: {xs: 'center', md: justifyContent},
            }}>
            {titleImage && (
              <Image
                alt='goSurvey logo'
                src={`${BASE_URL}${titleImage}`}
                sx={{
                  marginBottom: '8px',
                  width: {xs: '250px', sm: '320px', md: '360px', lg: '480px'},
                }}
              />
            )}
            {heading && (
              <Typography
                fontWeight={400}
                color='text.primary'
                textTransform='capitalize'
                variant={isLargeDevice ? 'title55' : 'h1'}
                dangerouslySetInnerHTML={{__html: heading}}
              />
            )}
            {subHeading && (
              <Typography
                color='text.primary'
                variant={isLargeDevice ? 'metricNo' : 'desc25'}
                sx={{
                  '& ul': {
                    paddingLeft: '16px',
                    '& li': {
                      fontWeight: 400,
                      fontSize: '19px',
                      lineHeight: '32px',
                      paddingLeft: '8px',
                      paddingBottom: '16px',
                    },
                    '& li::marker': {
                      color: theme.palette.primary.main,
                    },
                  },
                }}
                dangerouslySetInnerHTML={{__html: subHeading}}
              />
            )}
            {button && (
              <Box pt={{xs: 0, md: 4}}>
                <Button
                  size='medium'
                  variant='contained'
                  sx={{py: 1, px: 6}}
                  onClick={buttonAction}>
                  {button}
                </Button>
              </Box>
            )}
          </Box>
          {imgpath && (
            <ImageWrapper
              imgShadow={imgShadow}
              width={{md: boxSizes[1]}}
              background={imageBoxSx.background}>
              <Image
                alt={heading}
                src={`${BASE_URL}${imgpath}`}
                sx={{
                  minHeight: {md: imageMinHeight},
                  maxWidth: {xs: '600px', md: '775px'},
                }}
              />
            </ImageWrapper>
          )}
        </Wrapper>
      </Container>
    </Box>
  );
};

LayoutStaticBanner.propTypes = {
  button: PropTypes.string,
  imageFirst: PropTypes.bool,
  buttonAction: PropTypes.func,
  imgShadow: PropTypes.bool,
  backgroundColor: PropTypes.string,
  detail: PropTypes.shape({
    heading: PropTypes.string,
    imgpath: PropTypes.string,
    subHeading: PropTypes.string,
    titleImage: PropTypes.string,
    boxSizes: PropTypes.array,
    imageBoxSx: PropTypes.shape({
      background: PropTypes.bool,
      shadow: PropTypes.bool,
    }),
  }),
};

export default LayoutStaticBanner;
