import {useMemo} from 'react';
import PropTypes from 'prop-types';

import QualifyingProgramsList from './components';

const QualifyingPrograms = ({
  title,
  noResults,
  cipCode = '',
  clusterName = '',
  globalSearch = '',
  // fixedScrollHeight,
  occupationName = '',
  businessPartnerName = '',
}) => {
  const searchKey =
    cipCode ||
    clusterName ||
    globalSearch ||
    occupationName ||
    businessPartnerName ||
    '';
  const category = useMemo(() => {
    if (clusterName) {
      return 'cluster_name';
    } else if (cipCode) {
      return 'cip_code';
    } else if (globalSearch) {
      return 'gsearch';
    } else if (occupationName) {
      return 'occupation_name';
    } else if (businessPartnerName) {
      return 'company_name';
    } else {
      return '';
    }
  }, [clusterName, globalSearch, occupationName, businessPartnerName, cipCode]);

  return (
    <QualifyingProgramsList
      title={title}
      category={category}
      noResults={noResults}
      searchKey={searchKey}
      // fixedScrollHeight={fixedScrollHeight}
    />
  );
};

QualifyingPrograms.propTypes = {
  title: PropTypes.string,
  cipCode: PropTypes.string,
  noResults: PropTypes.string,
  clusterName: PropTypes.string,
  globalSearch: PropTypes.string,
  occupationName: PropTypes.string,
  // fixedScrollHeight: PropTypes.number,
  businessPartnerName: PropTypes.string,
};

export default QualifyingPrograms;
