import React, {useEffect, useState} from 'react';
import moment from 'moment';
import {useSnackbar} from 'notistack';
import {useForm} from 'react-hook-form';
import {createField} from 'mui-core/Form';
import {styled} from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import {useSelector, useDispatch} from 'react-redux';
import CompanyInformation from './CompanyInformation';
import ContactInformation from './ContactInformation';
import {infoIcon, InfoIconwhite, einModalIcon} from 'assets/images';
import {
  Box,
  Stack,
  useTheme,
  Backdrop,
  IconButton,
  Typography,
  OutlinedInput,
  useMediaQuery,
  CircularProgress,
} from '@mui/material';
import {
  Grid,
  Button,
  DialogBox,
  themeColorMode,
  AutoCompleteWrapper,
} from 'mui-core';
import {getStates, fetchStates} from 'redux/modules/general';
import {
  getLinkedInDetails,
  fetchlinkedInDetails,
} from 'redux/modules/resume-builder';
import {
  getROPList,
  fetchROPList,
  checkExistsStatus,
  fetchBusinessSignup,
} from 'redux/modules/business-signup';
import partnerSignup from 'data/partnersSignup.json';

const StyledInfoRounded = styled('img')(({theme}) => ({
  width: 15,
  height: 15,
  marginTop: -8,
}));

const {
  ein_no,
  EIN_modal,
  subdomain,
  alert_messages,
  industry_sector,
  consortium_list,
  company_information,
  contact_information,
} = partnerSignup;

const EmployerSignupForm = ({
  visible,
  onClose,
  onSubmit,
  setInfoDialog,
  infoDialog = false,
}) => {
  const form = useForm({
    defaultValues: {
      status: false,
      verification: 'Pending',
      institution_super_type_name: {
        uuid: 'a455aca9-782b-4663-b0e4-7c52a92737d5',
      },
      institution_type_id: '',
      number_of_users: 2,
      default_msa: {},
      nearby_msa: [],
      license_access: 'Enterprise',
      contract_start_date: moment(new Date()),
      subscription_period: 'Perpetual',
      institution_link: null,
      institution_list: [],
      institution_type_id: '',
      unique_member_code: '',
      included_modules: [
        'Home',
        'User Management',
        'Course Management',
        'Events',
        'Opportunities',
        'Programs',
        'Talent Exchange',
      ],
      institution_super_type_name: {
        uuid: 'a455aca9-782b-4663-b0e4-7c52a92737d5',
      },
    },
  });
  const theme = useTheme();
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const [loading, setLoading] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const linkedInData = useSelector(getLinkedInDetails) || {};
  const {industry_name: industry_name_options = []} = linkedInData;
  const {data: statesList = [], request: statesRequest} =
    useSelector(getStates) || {};
  const {data: ropList, request: ropReq} = useSelector(getROPList) || {};

  const [customErrors, setCustomErrors] = useState({
    ein_no: false,
    subdomain: false,
    name: false,
  });

  const InfoIconImage =
    theme?.palette?.mode === 'dark' ? InfoIconwhite : infoIcon;

  const {
    reset,
    watch,
    trigger,
    setValue,
    setError,
    getValues,
    formState,
    clearErrors,
    handleSubmit,
  } = form;
  const watchAllFields = watch();
  const {errors, isSubmitting} = formState;

  useEffect(() => {
    dispatch(fetchlinkedInDetails('industry_name', 'all'));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    !statesRequest && !statesList?.length && dispatch(fetchStates());
    dispatch(fetchROPList());
  }, []);

  const handleFormSubmit = data => {
    const rop_list = data?.consortium_list.map(e => e.institution_id);
    const users = [
      {
        role: 'Administrator',
        email: data.email,
        last_name: data.last_name,
        first_name: data.first_name,
        department: '',
        phone: data.phone,
      },
    ];
    const physical_address_details = {
      city: data.city,
      phone: data.phone,
      email: data.email,
      state: data.state,
      zipcode: data.zipcode,
      last_name: data.last_name,
      first_name: data.first_name,
      address_line1: `${data.address_line1} ${data.address_line2}`,
      business_name: data.name,
    };
    let preparedData = {
      ...data,
      ein_no: data.ein_no.replace('-', ''),
      consortium_list: rop_list,
      users: users,
      physical_address_details: physical_address_details,
    };
    delete preparedData.email;
    delete preparedData.phone;
    delete preparedData.address_line1;
    delete preparedData.address_line2;
    delete preparedData.first_name;
    delete preparedData.last_name;
    delete preparedData.city;
    delete preparedData.state;
    delete preparedData.zipcode;
    setLoading(true);
    dispatch(
      fetchBusinessSignup(preparedData, val => {
        if (val?.institution_id) {
          enqueueSnackbar(alert_messages?.success, {
            variant: 'success',
          });
          onCloseDialog();
          onSubmit();
        } else {
          enqueueSnackbar(val?.message || alert_messages?.error, {
            variant: 'error',
          });
        }
        setLoading(false);
      }),
    );
  };

  const onCloseDialog = () => {
    setCustomErrors({ein_no: false, subdomain: false, name: false});
    reset();
    onClose();
  };

  const onCloseInfoDialog = () => {
    setInfoDialog(false);
  };

  useEffect(() => {
    Object.keys(customErrors).forEach(ele => {
      if (customErrors[ele] && !errors[ele]) {
        setError(ele, {type: 'custom'});
      }
    });
  }, [watchAllFields]);

  const checkNameStatus = () => {
    if (getValues('name')?.length) {
      const obj = {
        license_name: getValues('name').trim(),
        license_type: 'a455aca9-782b-4663-b0e4-7c52a92737d5',
      };
      setLoading(true);
      dispatch(
        checkExistsStatus(obj, val => {
          if (val?.exists) {
            setCustomErrors({...customErrors, name: true});
          } else {
            setCustomErrors({...customErrors, name: false});
            clearErrors('name');
          }
          setLoading(false);
        }),
      );
    } else {
      setCustomErrors({...customErrors, name: false});
      clearErrors('name');
      trigger(['name']);
    }
  };

  const checkSubDomainStatus = () => {
    if (getValues('subdomain')?.length > 2) {
      const obj = {
        subdomain: getValues('subdomain').trim(),
      };
      if (!getValues('subdomain').match(/^[a-z0-9-]*$/)) {
        trigger(['subdomain']);
        return;
      }
      setLoading(true);
      dispatch(
        checkExistsStatus(obj, val => {
          if (val?.exists) {
            setCustomErrors({...customErrors, subdomain: true});
          } else {
            setCustomErrors({...customErrors, subdomain: false});
            clearErrors('subdomain');
          }
          setLoading(false);
        }),
      );
    } else {
      setCustomErrors({...customErrors, subdomain: false});
      clearErrors('subdomain');
      trigger(['subdomain']);
    }
  };
  const checkEINStatus = () => {
    const value = getValues('ein_no').replaceAll('-', '');
    const obj = {
      ein_no: value,
    };
    if (value?.length === 9 && value.match(/^[0-9]{9}$/i)) {
      setLoading(true);
      dispatch(
        checkExistsStatus(obj, val => {
          if (val?.exists) {
            setCustomErrors({...customErrors, ein_no: true});
          } else {
            setCustomErrors({...customErrors, ein_no: false});
            clearErrors('ein_no');
          }
          setLoading(false);
        }),
      );
    } else {
      setCustomErrors({...customErrors, ein_no: false});
      clearErrors('ein_no');
      trigger(['ein_no']);
    }
  };

  const maskEIN = value => {
    let maskedValue = '';
    const plainValue = value.slice(0, 10).replace('-', '');
    if (value.length <= 9) {
      if (value.length > 2) {
        const splited1 = plainValue.slice(0, 2);
        const splited2 = plainValue.slice(2, plainValue.length);
        maskedValue =
          splited2 !== '-' || splited2 !== ''
            ? `${splited1}-${splited2}`
            : splited1;
      } else {
        maskedValue = plainValue;
      }
      setValue('ein_no', maskedValue);
    }

    return maskedValue;
  };

  return (
    <>
      <DialogBox
        size='md'
        fullScreen
        customizeable
        openDialog={visible}
        customDialogsx={{
          '& .MuiPaper-root': {
            maxHeight: '100%',
            padding: '16px 8px',
            borderRadius: isMobile ? 0 : '20px',
          },
          '& .MuiDialogContent-root': {
            padding: '0px',
            overflowX: 'hidden',
          },
          '& #dialog_close_button': {
            mr: 3,
            top: '32px',
          },
          '& input[type=number]': {
            MozAppearance: 'textfield',
          },
          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            display: 'none',
          },
        }}>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <Box
            sx={{
              mx: 2,
              mt: '6px',
              display: 'flex',
              borderRadius: '12px',
              justifyContent: 'space-between',
              background: themeColorMode(
                theme,
                '#F1F1F4',
                theme.palette.darkGray.main,
              ),
            }}
            id='custom_dialog_header'>
            <Typography variant='h4' pl={2} py={2}>
              Business Partner Sign-Up Form
            </Typography>
            <IconButton
              aria-label='close'
              onClick={onCloseDialog}
              sx={{
                top: 0,
                zIndex: '9',
                color: themeColorMode(
                  theme,
                  theme.palette.darkGray.main,
                  'white',
                ),
              }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={{px: {xs: 2, sm: 3}}}>
            <CompanyInformation
              form={form}
              statesList={statesList}
              structure={company_information}
              checkNameStatus={checkNameStatus}
            />

            <Grid
              container
              rowSpacing={4}
              sx={{mx: '10px', pt: '10px'}}
              columnSpacing={{xs: 1, sm: 2, md: 3}}>
              <Grid item xs={12} sm={6} md={6}>
                {createField('subdomain', {
                  form,
                  size: 'small',
                  defaultValue: '',
                  label: subdomain.label,
                  rules: {
                    required: [subdomain.required, subdomain.requiredError],
                    custom: [true, subdomain.customError],
                    pattern: [/^[a-z0-9-]*$/, subdomain.invalidError],
                    maxLength: [60, subdomain.maxLengthError],
                  },
                })(
                  <OutlinedInput
                    variant='outlined'
                    id={subdomain.label}
                    label={subdomain.label}
                    onBlur={checkSubDomainStatus}
                    placeholder={subdomain.placeholder}
                  />,
                )}
              </Grid>
              <Grid
                item
                sm={6}
                md={6}
                xs={12}
                display='flex'
                position='relative'>
                {createField('ein_no', {
                  form,
                  size: 'small',
                  defaultValue: '',
                  label: ein_no.label,
                  rules: {
                    custom: [true, ein_no.customError],
                    pattern: [/^[0-9-]{10}$/, ein_no.maxLengthError],
                    required: [ein_no.required, ein_no.requiredError],
                  },
                })(
                  <OutlinedInput
                    id={ein_no.label}
                    variant='outlined'
                    label={ein_no.label}
                    onBlur={checkEINStatus}
                    placeholder={ein_no.placeholder}
                    onKeyUp={e => maskEIN(e.target.value)}
                  />,
                )}
                <IconButton
                  aria-label='info'
                  onClick={() => setInfoDialog(true)}
                  sx={{position: 'absolute', right: {xs: -24, sm: -20}}}>
                  <StyledInfoRounded alt='info-icon' src={InfoIconImage} />
                </IconButton>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                {createField('industry_sector', {
                  form,
                  rules: {
                    required: [
                      industry_sector.required,
                      industry_sector.requiredError,
                    ],
                  },
                  helperText: '',
                  defaultValue: getValues('industry_sector') || null,
                })(
                  <AutoCompleteWrapper
                    required
                    form={form}
                    variantSize='small'
                    fieldName='industry_sector'
                    label={industry_sector.label}
                    placeholder={industry_sector.placeholder}
                    options={
                      industry_name_options &&
                      Array.isArray(industry_name_options) &&
                      industry_name_options.length > 0
                        ? industry_name_options?.map(item => {
                            return {
                              ...item,
                              key: item.id,
                              label: item.industry_name,
                              title: item.industry_name,
                              industry_sector: item.industry_name,
                            };
                          })
                        : []
                    }
                  />,
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                {createField('consortium_list', {
                  form,
                  rules: {
                    required: [
                      consortium_list.required,
                      consortium_list.requiredError,
                    ],
                  },
                  helperText: consortium_list.helperText,
                })(
                  <AutoCompleteWrapper
                    required
                    multiple
                    form={form}
                    limitTags={2}
                    defaultValue={[]}
                    variantSize='small'
                    fieldName='consortium_list'
                    disableCloseOnSelect={true}
                    label={consortium_list.label}
                    keyNameIfMultiple='institution_id'
                    placeholder={consortium_list.placeholder}
                    options={
                      ropList && Array.isArray(ropList) && ropList.length > 0
                        ? ropList?.map(item => {
                            return {
                              ...item,
                              key: item.institution_id,
                              label: `${item.name} - ${item.default_msa__city}, ${item.default_msa__state_code}`,
                              title: `${item.name} - ${item.default_msa__city}, ${item.default_msa__state_code}`,
                              institution_id: item.institution_id,
                            };
                          })
                        : []
                    }
                  />,
                )}
              </Grid>
            </Grid>

            <ContactInformation form={form} structure={contact_information} />

            <Stack
              pb={4}
              pl={2}
              gap={2}
              display='flex'
              flexDirection='row'
              justifyContent='left'>
              <Button
                onClick={onCloseDialog}
                variant='outlined'
                sx={{
                  minWidth: '112px',
                }}>
                Cancel
              </Button>
              <Button
                type='submit'
                formNoValidate
                variant='contained'
                disabled={isSubmitting}
                sx={{minWidth: '112px'}}>
                Sign Up
              </Button>
            </Stack>
          </Box>
        </form>
        <Backdrop
          open={loading}
          sx={{color: '#fff', zIndex: theme => theme.zIndex.drawer + 1}}>
          <CircularProgress color='inherit' />
        </Backdrop>
      </DialogBox>
      <DialogBox
        size='md'
        customizeable={false}
        // disableIcon
        iconSize={130}
        openDialog={infoDialog}
        closeDialog={onCloseInfoDialog}
        //   closeIconColor='white'
        showIcon={einModalIcon}
        customDialogsx={{
          '& .MuiPaper-root': {
            borderRadius: '20px',
            padding: '16px 8px',
            maxHeight: '100%',
          },
          '& .MuiDialogContent-root': {
            padding: '0px',
          },
        }}>
        <Stack gap={2} mx={4} my={2}>
          <Typography variant='h2' textAlign='center'>
            {EIN_modal.title}
          </Typography>
          <Typography variant='bodyL' textAlign='left'>
            {EIN_modal.subTitle}
          </Typography>
          <Box>
            {EIN_modal.list.map(ele => (
              <Box key={ele}>
                <Typography variant='bodyL' textAlign='left'>
                  {ele}
                </Typography>
              </Box>
            ))}
          </Box>
          <Typography variant='bodyL' textAlign='left'>
            {EIN_modal.footer}
          </Typography>
        </Stack>
      </DialogBox>
    </>
  );
};

export default EmployerSignupForm;
