import {isTokenExpire} from 'core/utils';
import * as TYPES from '../modules/auth/constants';
import {getNewToken} from '../modules/auth/login';
import {doLogout} from '../modules/auth/actions';

export default ({dispatch, getState}) => next => async action => {
  const {
    auth: {auth: {token, refresh, refreshTokenRequest} = {}} = {},
  } = getState();
  const {type} = action;

  if (!type) {
    return next(action);
  }

  if (
    type !== TYPES.LOG_OUT &&
    type !== TYPES.RECEIVE_NEW_TOKEN &&
    type !== TYPES.REQUEST_NEW_TOKEN &&
    token &&
    !refreshTokenRequest
  ) {
    const {expiringInMins, isExpired} = await isTokenExpire(token);
    //console.log('MINS LEFT::', expiringInMins);
    //console.log('Is Token Expired::', isExpired);
    if (!refresh || isExpired) {
      return dispatch(doLogout(true));
    }
    if (expiringInMins < 5) {
      try {
        dispatch(getNewToken());
        setTimeout(() => {
          next(action);
        }, 500);
      } catch (err) {
        console.log('Token Refresh:', err);
        return dispatch(doLogout());
      }
    } else {
      return next(action);
    }
  } else {
    return next(action);
  }
};
