import React, {useEffect} from 'react';
import EventList from './EventList';
import {useDispatch, useSelector} from 'react-redux';
import {fetchMyEvents, getMyEvents} from 'redux/modules/events';
import {ContentBox, Accordion, RequestErrorLoader} from 'mui-core';
import {home as homeJson} from 'data/dashboard';

const {
  events: {header},
} = homeJson;
const MyEvents = () => {
  const dispatch = useDispatch();
  const {data: {event_details = []} = {}, request} =
    useSelector(getMyEvents) || {};

  useEffect(() => {
    if (!request) {
      // Hardcode page = 1 and no of items = 50
      dispatch(fetchMyEvents(1, false, true, 50));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {event_details?.length > 0 && (
        <ContentBox elevation={0}>
          <Accordion
            id='my_events'
            header={header}
            outlined={false}
            collpsable={true}
            defaultOpen={false}>
            <RequestErrorLoader body={{data: event_details, request}}>
              <EventList events={event_details} request={request} />
            </RequestErrorLoader>
          </Accordion>
        </ContentBox>
      )}
    </>
  );
};

export default MyEvents;
