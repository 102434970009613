import React from 'react';
import {Stack} from '@mui/material';
import {ContentBox} from 'mui-core';
import {JobMatches, MyOpportunities} from './components';

const Opportunities = ({}) => {
  return (
    <Stack gap={2}>
      <ContentBox elevation={0}>
        <JobMatches />
      </ContentBox>
      <ContentBox elevation={0}>
        <MyOpportunities />
      </ContentBox>
    </Stack>
  );
};

export default Opportunities;
