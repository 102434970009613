import React, {useState} from 'react';
//components
import {
  Card,
  Button,
  StaticBanner,
  themeColorMode,
  LayoutStaticBanner,
} from 'mui-core';
import {grey} from '@mui/material/colors';
import {BenefitsOverview} from '../component';
import {useTheme} from '@mui/material/styles';
import {Box, Stack, styled, Typography} from '@mui/material';
import {Check, EmailOutlined, ArrowForward} from '@mui/icons-material';
import EmployerSignupForm from '../components/EmployerSignupForm';
//utils
import {EmployerPartnerBanner, demoBanner} from 'assets/images';
import {
  banners,
  talentCTA,
  pricePlans,
  keyBenefits,
  partnersPage,
  pageBannerData,
} from 'data/partnersSignup.json';

const {transDesc, transformTitle} = talentCTA || {};
const {bannerCaptionTitle, employerBannerTitle} = pageBannerData || {};
const {
  demoRequest: {demoRequestBtn, demoRequestTitle},
} = partnersPage || {};

const {pricePlansList} = pricePlans || {};

const CutsomTypography = styled(Typography)(({theme}) => ({
  '& .purpletext': {
    fontWeight: 500,
    fontSize: '45px',
    color: theme.palette.primary.main,
  },
  [theme.breakpoints.down('md')]: {
    '& .purpletext': {
      fontSize: '36px',
    },
  },
}));

const EmployerPartners = () => {
  const theme = useTheme();
  const [infoDialog, setInfoDialog] = useState(false);
  const [showSignupModal, setShowSignupModal] = useState(false);
  const [activeCard, setActiveCard] = useState(2);

  const handleSubmitSignupModal = () => {
    setShowSignupModal(false);
  };

  const cardHandler = key => {
    // setActiveCard(key);
  };

  return (
    <Box>
      <StaticBanner
        url={EmployerPartnerBanner}
        captionTitle={bannerCaptionTitle}>
        <Typography
          variant='h1'
          sx={{mt: '-6px !important'}}
          color={theme.palette.grey.contrastText}
          dangerouslySetInnerHTML={{__html: employerBannerTitle}}
        />
      </StaticBanner>
      {Array.isArray(banners) &&
        banners.length > 0 &&
        banners.map((banner, index) => (
          <LayoutStaticBanner
            key={banner.key}
            alignBasedOnImage
            imageFirst={index % 2 !== 0}
            imgShadow='2px 0px 8px  #00000029'
            backgroundColor={
              index % 2 !== 0 &&
              themeColorMode(theme, grey[200], theme.palette.darkGray.main)
            }
            detail={{
              heading: banner.title,
              imgpath: banner.image,
              boxSizes: banner.boxSizes,
              imageBoxSx: banner.imageBoxSx,
              subHeading: banner.description,
            }}
          />
        ))}
      <Stack
        sx={{
          background:
            'linear-gradient(110deg, rgba(42,63,142,1) 0%, rgba(99,59,230,1) 63%)',
        }}>
        <Stack
          gap={4}
          textAlign='left'
          alignItems='flex-start'
          p={{xs: '32px', md: '72px'}}
          justifyContent='flex-start'
          sx={{
            backgroundRepeat: 'repeat',
            backgroundSize: '140px !important',
            backgroundBlendMode: 'screen, normal, normal',
          }}>
          <Typography
            color='white'
            variant='title55'
            dangerouslySetInnerHTML={{
              __html: transformTitle,
            }}
          />
          <Typography variant='bodyXL' color='white'>
            {transDesc}
          </Typography>
        </Stack>
      </Stack>
      <Box
        p={{xs: '32px', md: '72px'}}
        bgcolor={themeColorMode(
          theme,
          theme.palette.midGray.contrastText,
          theme.palette.shadeyBlack.main,
        )}>
        <CutsomTypography variant='h2'>
          Limited Time Offer: Unlock Your Free
          <Typography component='span' className='purpletext'>
            &nbsp;Recruitment Plus+&nbsp;
          </Typography>
          Business Account – Offer Ends
          <Typography component='span' className='purpletext'>
            &nbsp;January 1, 2025.
          </Typography>
        </CutsomTypography>
        <Stack
          mt={4}
          gap='20px'
          justifyContent='center'
          direction={{xs: 'column', sm: 'row'}}
          flexWrap={{sm: 'wrap', lg: 'nowrap'}}>
          {Array.isArray(pricePlansList) &&
            pricePlansList.length > 0 &&
            pricePlansList.map(
              (
                {
                  pricePlanBtn,
                  pricePlanRate,
                  pricePlanOffer,
                  pricePlansName,
                  pricePlanPoints,
                  pricePlanLearnMore,
                  pricePlanLearnSubText,
                },
                index,
              ) => (
                <Card
                  key={index}
                  sx={{
                    px: {xs: 3, sm: 5}, // Adjust padding for mobile
                    pt: {xs: '40px', sm: '50px'}, // Responsive top padding
                    pb: {xs: '20px', sm: '20px'},
                    minHeight: {xs: 'auto', sm: '575px'}, // Flexible minHeight for mobile
                    maxWidth: {xs: '100%', sm: '400px'},
                    minWidth: {xs: '100%', sm: '25%'},
                    border:
                      activeCard === index
                        ? `1px solid ${theme.palette.primary.dark}`
                        : '1px solid transparent',
                  }}
                  onMouseEnter={() => cardHandler(index)}>
                  <Stack
                    gap={3}
                    direction='column'
                    sx={{height: '100%'}}
                    alignContent='space-around'
                    justifyContent='space-between'>
                    <Stack gap='15px' sx={{flexGrow: 1}} position='relative'>
                      {pricePlanOffer && (
                        <Typography
                          p='4px'
                          top='-8%'
                          variant='body1'
                          textAlign='left'
                          bgcolor='#06AED4'
                          borderRadius='6px'
                          position='absolute'
                          sx={{width: 'fit-content'}}
                          color={theme.palette.grey.contrastText}>
                          {pricePlanOffer}
                        </Typography>
                      )}
                      <Typography
                        variant='h4'
                        textAlign='center'
                        color={theme.palette.primary.main}>
                        {pricePlansName}
                      </Typography>
                      <Stack
                        gap='4px'
                        direction='row'
                        alignItems='center'
                        justifyContent='center'>
                        <Typography variant='h3'>{pricePlanRate}</Typography>
                        <Typography component='span'>/mo</Typography>
                      </Stack>
                      <Typography
                        variant='body2'
                        textAlign='center'
                        color={theme.palette.primary.main}>
                        {pricePlanLearnMore}
                      </Typography>
                      <Typography variant='bodyL'>
                        {pricePlanLearnSubText}
                      </Typography>
                      <Stack gap='10px' mt={1}>
                        {pricePlanPoints.map(item => (
                          <Stack
                            gap={1.5}
                            key={item}
                            direction='row'
                            alignItems='center'>
                            <Check
                              sx={{
                                width: '20px',
                                height: '20px',
                                color: theme.palette.primary.main,
                              }}
                            />
                            <Typography variant='body1'>{item}</Typography>
                          </Stack>
                        ))}
                      </Stack>
                    </Stack>
                    <Button
                      variant='contained'
                      disabled={index !== 2}
                      endIcon={<ArrowForward />}
                      sx={{
                        background:
                          index === 2
                            ? theme.palette.primary.dark
                            : theme.palette.background.paper,
                        color:
                          index === 2
                            ? theme.palette.background.paper
                            : themeColorMode(
                                theme,
                                theme.palette.grayText.main,
                                theme.palette.midGray.contrastText,
                              ),
                        borderColor:
                          activeCard !== index && theme.palette.grayText.main,
                      }}
                      onClick={() => index === 2 && setShowSignupModal(true)}>
                      {pricePlanBtn}
                    </Button>
                  </Stack>
                </Card>
              ),
            )}
        </Stack>
      </Box>
      <BenefitsOverview cardsInfo={keyBenefits} />
      <EmployerSignupForm
        infoDialog={infoDialog}
        visible={showSignupModal}
        onSubmit={handleSubmitSignupModal}
        onClose={() => setShowSignupModal(false)}
        setInfoDialog={val => setInfoDialog(val)}
      />
      <StaticBanner
        url={demoBanner}
        bgGradient='rgb(0 0 0 / 6%)'
        backgroundImgPosition='center'>
        <Typography
          variant='h1'
          color={theme.palette.grey.contrastText}
          dangerouslySetInnerHTML={{__html: demoRequestTitle}}
        />
        <a href='mailto:info@goeducate.com.com' style={{cursor: 'default'}}>
          <Button
            p={1}
            endIcon={<EmailOutlined />}
            sx={{
              color: '#FFFFFF',
              width: 'fit-content',
              mt: {xs: 1, md: '8px !important'},
              backgroundColor: theme.palette.primary.light,
              '&:hover': {
                backgroundColor: theme.palette.primary.main,
              },
            }}>
            {demoRequestBtn}
          </Button>
        </a>
      </StaticBanner>
    </Box>
  );
};

export default EmployerPartners;
