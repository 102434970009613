import * as TYPES from './constants';

// User Data
export const requestUserData = () => ({
  type: TYPES.REQUEST_USER,
});
export const recieveUserData = res => ({
  type: TYPES.SUCCESS_USER,
  payload: res,
});
export const errorUserData = () => ({
  type: TYPES.ERROR_USER,
});

// PROFILE UPDATE
export const requestProfileUpdate = () => ({
  type: TYPES.PROFILE_UPDATE_LOAD,
});
export const recieveProfileUpdate = res => ({
  type: TYPES.PROFILE_UPDATE_SUCCESS,
  payload: res,
});
export const profileUpdateError = () => ({
  type: TYPES.PROFILE_UPDATE_ERROR,
});

// profile fetch request
export const requestProfileData = () => ({
  type: TYPES.PROFILE_DATA_LOAD,
});
export const recieveProfileData = res => ({
  type: TYPES.PROFILE_DATA_SUCCESS,
  payload: res,
});
export const profileDataError = () => ({
  type: TYPES.PROFILE_DATA_ERROR,
});
export const requestSeekingData = () => ({
  type: TYPES.SEEKING_DATA_LOAD,
});
export const recieveSeekingData = res => ({
  type: TYPES.SEEKING_DATA_SUCCESS,
  payload: res,
});
export const seekingDataError = () => ({
  type: TYPES.SEEKING_DATA_ERROR,
});

// Award Types
export const requestAwardTypes = () => ({
  type: TYPES.STUDENT_AWARD_TYPES_LOAD,
});
export const recieveAwardTypes = res => ({
  type: TYPES.STUDENT_AWARD_TYPES_SUCCESS,
  payload: res,
});
export const awardTypesError = () => ({
  type: TYPES.STUDENT_AWARD_TYPE_ERROR,
});

// FETCH SKILLS
export const requestSkills = () => ({
  type: TYPES.SKILLS_LOAD,
});
export const recieveSkills = res => ({
  type: TYPES.SKILLS_SUCCESS,
  payload: res,
});
export const skillsError = () => ({
  type: TYPES.SKILLS_ERROR,
});

// FETCH STUDENT SKILLS
export const requestStudentSkills = () => ({
  type: TYPES.STUDENT_SKILLS_LOAD,
});
export const requestSaveStudentSkills = () => ({
  type: TYPES.STUDENT_SKILLS_SAVE_LOAD,
});
export const requestUpdateStudentSkills = () => ({
  type: TYPES.STUDENT_SKILLS_UPDATE_LOAD,
});
export const requestDeleteStudentSkills = () => ({
  type: TYPES.STUDENT_SKILLS_DELETE_LOAD,
});
export const receiveStudentSkills = res => ({
  type: TYPES.STUDENT_SKILLS_SUCCESS,
  payload: res,
});
export const studentSkillsClear = res => ({
  type: TYPES.STUDENT_SKILLS_CLEAR,
  payload: res,
});
export const receiveSaveStudentSkills = res => ({
  type: TYPES.STUDENT_SKILLS_SAVE_SUCCESS,
  payload: res,
});
export const receiveUpdateStudentSkills = res => ({
  type: TYPES.STUDENT_SKILLS_UPDATE_SUCCESS,
  payload: res,
});
export const receiveDeleteStudentSkills = res => ({
  type: TYPES.STUDENT_SKILLS_DELETE_SUCCESS,
  payload: res,
});
export const studentSkillsError = () => ({
  type: TYPES.STUDENT_SKILLS_ERROR,
});
export const studentSaveSkillsError = () => ({
  type: TYPES.STUDENT_SKILLS_SAVE_ERROR,
});
export const studentUpdateSkillsError = () => ({
  type: TYPES.STUDENT_SKILLS_UPDATE_ERROR,
});
export const studentDeleteSkillsError = () => ({
  type: TYPES.STUDENT_SKILLS_DELETE_ERROR,
});

// OCCUPATION INTEREST
export const requestOccupationInterest = () => ({
  type: TYPES.OCCUPATION_INTEREST_LOAD,
});
export const recieveOccuaptionInterest = res => ({
  type: TYPES.OCCUPATION_INTEREST_SUCCESS,
  payload: res,
});
export const occuaptionInterestError = () => ({
  type: TYPES.OCCUPATION_INTEREST_ERROR,
});
export const requestOccupationDeleteInterest = () => ({
  type: TYPES.OCCUPATION_INTEREST_DELETE_LOAD,
});
export const recieveOccuaptionDeleteInterest = res => ({
  type: TYPES.OCCUPATION_INTEREST_DELETE_SUCCESS,
  payload: res,
});
export const occuaptionInterestDeleteError = () => ({
  type: TYPES.OCCUPATION_INTEREST_DELETE_ERROR,
});

// FETCH ETHNICITY
export const requestEthnicity = () => ({
  type: TYPES.ETHNICITY_LOAD,
});
export const recieveEthnicity = res => ({
  type: TYPES.ETHNICITY_SUCCESS,
  payload: res,
});
export const ethnicityError = () => ({
  type: TYPES.ETHNICITY_ERROR,
});

// FETCH NATIVE_LANGUAGE
export const requestNativeLanguage = () => ({
  type: TYPES.NATIVE_LANGUAGE_LOAD,
});
export const recieveNativeLanguage = res => ({
  type: TYPES.NATIVE_LANGUAGE_SUCCESS,
  payload: res,
});
export const nativeLanguageError = () => ({
  type: TYPES.NATIVE_LANGUAGE_ERROR,
});

// FETCH CITIZENSHIP
export const requestCitizenship = () => ({
  type: TYPES.CITIZENSHIP_LOAD,
});
export const recieveCitizenship = res => ({
  type: TYPES.CITIZENSHIP_SUCCESS,
  payload: res,
});
export const citizenshipError = () => ({
  type: TYPES.CITIZENSHIP_ERROR,
});

// FETCH STUDENT CREDENTIALS
export const requestStudentCredentials = () => ({
  type: TYPES.STUDENT_CREDENTIALS_LOAD,
});
export const requestSaveStudentCredentials = () => ({
  type: TYPES.STUDENT_CREDENTIALS_SAVE_LOAD,
});
export const requestUpdateStudentCredentials = () => ({
  type: TYPES.STUDENT_CREDENTIALS_UPDATE_LOAD,
});
export const requestDeleteStudentCredentials = () => ({
  type: TYPES.STUDENT_CREDENTIALS_DELETE_LOAD,
});
export const receiveStudentCredentials = res => ({
  type: TYPES.STUDENT_CREDENTIALS_SUCCESS,
  payload: res,
});
export const receiveSaveStudentCredentials = res => ({
  type: TYPES.STUDENT_CREDENTIALS_SAVE_SUCCESS,
  payload: res,
});
export const receiveUpdateStudentCredentials = res => ({
  type: TYPES.STUDENT_CREDENTIALS_UPDATE_SUCCESS,
  payload: res,
});
export const receiveDeleteStudentCredentials = res => ({
  type: TYPES.STUDENT_CREDENTIALS_DELETE_SUCCESS,
  payload: res,
});
export const studentCredentialsError = () => ({
  type: TYPES.STUDENT_CREDENTIALS_ERROR,
});
export const studentSaveCredentialsError = () => ({
  type: TYPES.STUDENT_CREDENTIALS_SAVE_ERROR,
});
export const studentUpdateCredentialsError = () => ({
  type: TYPES.STUDENT_CREDENTIALS_UPDATE_ERROR,
});
export const studentDeleteCredentialsError = () => ({
  type: TYPES.STUDENT_CREDENTIALS_DELETE_ERROR,
});
export const studentCredentialsClear = () => ({
  type: TYPES.STUDENT_CREDENTIALS_CLEAR,
});

// FETCH STUDENT_METRICS
export const requestStudentMetrics = () => ({
  type: TYPES.STUDENT_METRICS_REQUEST,
});
export const recieveStudentMetrics = res => ({
  type: TYPES.STUDENT_METRICS_SUCCESS,
  payload: res,
});
export const studentMetricsError = () => ({
  type: TYPES.STUDENT_METRICS_ERROR,
});
export const studentMetricsClear = () => ({
  type: TYPES.STUDENT_METRICS_CLEAR,
});

export const requestPrivacySetting = () => ({
  type: TYPES.REQUEST_PRIVACY_SETTING,
});
export const recievePrivacySetting = json => ({
  type: TYPES.RECIEVE_PRIVACY_SETTING,
  payload: json,
});
export const requestPrivacySettingError = err => ({
  type: TYPES.REQUEST_PRIVACY_SETTING_ERROR,
  err,
});

export const requestSavePrivacySetting = () => ({
  type: TYPES.REQUEST_SAVE_PRIVACY_SETTING,
});
export const recieveSavePrivacySetting = json => ({
  type: TYPES.RECIEVE_SAVE_PRIVACY_SETTING,
  payload: json,
});
export const requestSavePrivacySettingError = err => ({
  type: TYPES.REQUEST_SAVE_PRIVACY_SETTING_ERROR,
  err,
});

// DASHBOARD PROGRAMS
export const requestDashboardPrograms = () => ({
  type: TYPES.DASHBOARD_PROGRAMS_REQUEST,
});
export const recieveDashboardPrograms = res => ({
  type: TYPES.DASHBOARD_PROGRAMS_SUCCESS,
  payload: res,
});
export const dashboardProgramsError = () => ({
  type: TYPES.DASHBOARD_PROGRAMS_ERROR,
});
export const dashboardProgramsClear = () => ({
  type: TYPES.DASHBOARD_PROGRAMS_CLEAR,
});

// CHANGE PASSWORD
export const requestChangePassword = () => ({
  type: TYPES.DASHBOARD_PROGRAMS_REQUEST,
});
export const receiveChangePassword = res => ({
  type: TYPES.DASHBOARD_PROGRAMS_SUCCESS,
  payload: res,
});
export const errorChangePassword = () => ({
  type: TYPES.DASHBOARD_PROGRAMS_ERROR,
});

// CHANGE EMAIL
export const requestChangeEmail = () => ({
  type: TYPES.CHANGE_EMAIL_REQUEST,
});
export const recieveChangeEmail = res => ({
  type: TYPES.CHANGE_EMAIL_SUCCESS,
  payload: res,
});
export const changeEmailError = () => ({
  type: TYPES.CHANGE_EMAIL_ERROR,
});
