import React from 'react';
import {
  Grid,
  Button,
  CareerCard,
  ErrorBoundary,
  RequestErrorLoader,
  SearchResultsCounter,
} from 'mui-core';
import {Box, Stack, useTheme, useMediaQuery} from '@mui/material';
import {exploreCareer} from 'data/exploreCareer.json';

const CareerList = ({
  carrerAreaListLoader,
  reqParams,
  refetchData,
  careerRank = {},
}) => {
  const theme = useTheme();
  const {data = {}, error, request} = careerRank;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    data: careerRankList = [],
    page_details: careerRankPageDetails = {},
  } = data;
  const recordsCount = careerRankList.length || 0;
  const {loadingLabel, loadMoreLabel} = exploreCareer;
  const totalCount = careerRankPageDetails?.total_results || 0;
  const hideLoadMore =
    (careerRankPageDetails &&
      reqParams?.page >= careerRankPageDetails?.no_of_pages &&
      !request &&
      !error) ||
    recordsCount === totalCount ||
    totalCount <= 16;

  const loadMoreCareers = () => {
    const newPage = reqParams.page + 1;
    const Obj = Object.assign({}, reqParams, {
      page: newPage,
    });
    refetchData(Obj, true);
  };

  return (
    <ErrorBoundary nameOfComponent='module-career-list' typeOfUi='subPage'>
      <Stack>
        <RequestErrorLoader
          minHeight={300}
          body={{
            ...careerRank,
            request: carrerAreaListLoader || careerRank.request,
            data: careerRankList,
          }}>
          {careerRankList?.length > 0 && (
            <>
              <SearchResultsCounter
                request={request}
                totalResults={totalCount}
                currentLength={recordsCount}
              />
              <Grid
                container
                columnSpacing={{xs: 2, sm: 2}}
                rowSpacing={{xs: 2, sm: 2, md: 3, lg: 4}}
                justifyContent={isMobile ? 'center' : 'left'}>
                {careerRankList.map(careerData => (
                  <Grid
                    item
                    xs={12}
                    sm='auto'
                    justifyContent='center'
                    sx={{
                      flexBasis: 'fit-content',
                      textAlign: {xs: 'center', sm: 'left'},
                    }}
                    key={careerData.occupation_onnet}>
                    <CareerCard
                      width={300}
                      target='_self'
                      enableNavigation
                      imageHeight={172}
                      data={careerData}
                      config={{
                        type: 'occupation',
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
              {!hideLoadMore && (
                <Box
                  py={3}
                  display='flex'
                  alignContent='center'
                  justifyContent='center'>
                  <Button
                    size='large'
                    color='primary'
                    variant='outlined'
                    sx={{padding: '8px 54px'}}
                    onClick={loadMoreCareers}>
                    {request ? loadingLabel : loadMoreLabel}
                  </Button>
                </Box>
              )}
            </>
          )}
        </RequestErrorLoader>
      </Stack>
    </ErrorBoundary>
  );
};

export default CareerList;
