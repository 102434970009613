import React, {useRef, useEffect, useState} from 'react';
import {styled} from '@mui/system';
import {useSelector, useDispatch} from 'react-redux';
import {Box, Autocomplete, TextField, CircularProgress} from '@mui/material';
import {getLocationSearch, fetchLocationSearch} from 'redux/modules/occupation';

function useDebounce(value, delay, initialValue) {
  const [state, setState] = useState(initialValue);

  useEffect(() => {
    const timer = setTimeout(() => setState(value), delay);
    // clear timeout should the value change while already debouncing
    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return state;
}

const GroupHeader = styled('div')(({theme}) => ({
  top: '-16px',
  margin: '8px',
  fontWeight: 600,
  paddingLeft: '8px',
  position: 'sticky',
  background: 'transparent',
  color:
    theme?.palette?.mode === 'dark'
      ? theme.palette.grayLight.main
      : theme.palette.darkGray.main,
}));

const GroupItems = styled('ul')(({theme}) => ({
  padding: 0,
  background: 'transparent',
  color:
    theme?.palette?.mode === 'dark'
      ? theme.palette.grayLight.main
      : theme.palette.darkGray.main,
}));

let passValue = {};

const LocationSearch = ({
  onBlur,
  onClear,
  filters = {},
  onEnterPress,
  onInputChange,
  onLocationChange,
  LocationOptions = [],
  calledFromForm = false,
  locationLabel = 'Location',
  backgroundColor = 'transparent',
  placeholder = 'Search By City, State or Zip Code',
}) => {
  const dispatch = useDispatch();
  const autocompleteRef = useRef();
  const [keyword, setKeyword] = useState('');
  const [openOptions, setOpen] = useState(false);
  const [locationValue, setLocationValue] = useState('');
  const {LocationSearch: {data: locationData, request} = {}} =
    useSelector(getLocationSearch) || {};
  const {location_details} = locationData || {};

  useEffect(() => {
    if (filters && filters.location) {
      setTimeout(() => {
        setLocationValue(filters.location);
      }, 100);
    } else {
      if (
        filters &&
        filters.location === '' &&
        autocompleteRef &&
        autocompleteRef.current
      ) {
        const clear = autocompleteRef.current.querySelector(
          '.MuiAutocomplete-clearIndicator',
        );
        if (clear) clear.click();
        if (!onBlur) {
          autocompleteRef.current.querySelector('input').blur();
        }
      }
    }
  }, [filters]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (location_details && location_details.length === 0) {
      setLocationValue(null);
    }
  }, [location_details]); // eslint-disable-line react-hooks/exhaustive-deps

  const debouncedValue = useDebounce(keyword, 1000);

  useEffect(() => {
    if (debouncedValue) {
      dispatch(fetchLocationSearch(debouncedValue));
    }
  }, [debouncedValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const searchLocation = async (_, value, r) => {
    console.log('searchLocation', value, r, _);
    if (r === 'clear') {
      setLocationValue(null);
      setKeyword('');
      passValue.location = '';
      passValue.msa_code = '';
      // This property is used to idenify that this component is used inside an ANT form or not?
      if (calledFromForm) {
        onInputChange(passValue);
      }
      if (onClear) onClear();
    }
    if (!value && r !== 'reset') {
      setLocationValue('');
      setKeyword('');
    }
    if (r === 'input') {
      setOpen(true);
      setKeyword(value);
      passValue.location = value;
      passValue.msa_code = '';
      setLocationValue(value);
      if (calledFromForm) {
        onInputChange(passValue);
      }
    }
  };

  const handleChange = (e, v, r) => {
    const value = `${v && v.city ? v.city + ', ' : ''}${
      v && v.state_code ? v.state_code : ''
    }`;

    // If pressed enter
    if (r === 'createOption') {
      passValue.location = v;
      passValue.msa_code = '';
      onLocationChange(passValue);
    }
    //  If selected from an option
    if (value) {
      passValue = {
        location: value,
        city: v.city,
        msa_code: v?.msa_code ? v.msa_code : '',
      };
      onLocationChange(passValue);
    }
    setLocationValue(value);
    setOpen(false);
  };

  const getValue = () => {
    if (locationValue && locationValue?.msa_code) {
      return locationValue.msa_code;
    }
    return '';
  };

  const handleSubmit = e => {
    e.preventDefault();
    onLocationChange(passValue);
  };

  const getOptions = () => {
    if (keyword && keyword?.length < 2) {
      return LocationOptions
        ? location_details?.length
          ? [
              ...LocationOptions,
              ...location_details.map(l => {
                return {...l, nearby_msa: 'Other Locations'};
              }),
            ]
          : LocationOptions
        : location_details || [];
    } else if (keyword === '') {
      return LocationOptions;
    } else {
      return location_details || [];
    }
  };

  const onBlurField = e => {
    console.log('onBlurField_onBlurField', e.target.value);
    if (e.target.value === '') {
      if (onBlur) {
        onBlur();
      }
    }
  };

  return (
    <Autocomplete
      freeSolo
      id='state_code'
      ref={autocompleteRef}
      options={getOptions()}
      onChange={handleChange}
      defaultValue={getValue()}
      placeholder={placeholder}
      onClose={() => setOpen(false)}
      onInputChange={searchLocation}
      groupBy={option => option?.nearby_msa}
      value={locationValue ? locationValue : null}
      open={!request && openOptions ? true : false}
      sx={{width: '100%', background: backgroundColor}}
      filterOptions={options =>
        !request && keyword && keyword.length > 1
          ? options
          : options.filter(o => o.nearby_msa === 'Nearby Locations')
      }
      getOptionLabel={option =>
        option && option.state_code
          ? `${option.city ? option.city + ', ' : ''}${option.state_code}`
          : option
      }
      renderOption={(props, option) => (
        <Box py={1} px={2} {...props} value={option.msa_code}>
          {`${option.city ? option.city + ', ' : ''}${option.state_code}`}
        </Box>
      )}
      renderGroup={option => (
        <li key={option.id}>
          {LocationOptions.length ? (
            <GroupHeader>{option.group}</GroupHeader>
          ) : (
            ''
          )}
          <GroupItems>{option.children}</GroupItems>
        </li>
      )}
      renderInput={params => (
        <TextField
          {...params}
          label={locationLabel}
          placeholder={placeholder}
          onBlur={e => onBlurField(e)}
          onKeyDown={e => {
            if (e.key === 'Enter') onEnterPress(e.target.value);
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: request ? (
              <CircularProgress size={22} />
            ) : (
              params.InputProps.endAdornment
            ),
          }}
        />
      )}
    />
  );
};

export default LocationSearch;
