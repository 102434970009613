import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react';
import {VisibilityOff, Visibility} from '@mui/icons-material';
import {OutlinedInput, InputAdornment, IconButton} from '@mui/material';

const MUIInput = (props, ref) => {
  const {
    label,
    ariaLabel,
    placeholder,
    maxLength,
    // value,
    // maskZipcode,
    // maskPhoneNumber,
    adornment = null,
    typeOfInput = 'text',
    inputRef,
  } = props;
  const muiInputRef = useRef();
  const [showPassword, setShowPassword] = useState(false);
  //   const maskedValue = value => {
  //     if (maskZipcode) {
  //       let maskedValue = '';
  //       const plainValue = value.slice(0, 10).replace('-', '');
  //       console.log('🚀 ~ maskedValue ~ value:', value.length);
  //       if (value.length <= 10) {
  //         if (value.length > 5) {
  //           const splited1 = plainValue.slice(0, 5);
  //           const splited2 = plainValue.slice(5, plainValue.length);
  //           maskedValue =
  //             splited2 !== '-' || splited2 !== ''
  //               ? `${splited1}-${splited2}`
  //               : splited1;
  //         } else {
  //           maskedValue = plainValue;
  //         }
  //       }
  //       return maskedValue;
  //     } else if (maskPhoneNumber) {
  //     } else return value;
  //   };

  useImperativeHandle(
    ref,
    () => {
      return {
        focus() {
          muiInputRef?.current?.focus();
        },
      };
    },
    [],
  );

  const handleTogglePasswordVisibility = () => {
    setShowPassword(prevShowPassword => !prevShowPassword);
  };

  const passwordAdornment = () => (
    <InputAdornment position='end'>
      <IconButton
        edge='end'
        aria-label='toggle password visibility'
        onClick={handleTogglePasswordVisibility}>
        {showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );

  return (
    <OutlinedInput
      autoFocus
      inputRef={muiInputRef}
      endAdornment={
        typeOfInput === 'password' ? passwordAdornment() : adornment
      }
      {...props}
      label={label}
      aria-label={ariaLabel}
      placeholder={placeholder}
      id={`${label}-outlined-input`}
      //   value={maskedValue(value)}
      inputProps={{maxLength: maxLength}}
      type={
        typeOfInput === 'password'
          ? showPassword
            ? 'text'
            : 'password'
          : typeOfInput
      }
    />
  );
};

export default forwardRef(MUIInput);
